import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import ProfileIcon from "./assets/profile.svg";
import moment from "moment";

// Styled Components
const DrawerContainer = styled.div`
  position: fixed;
  top: 60px;
  right: 0;
  width: 350px;
  min-height: calc(100vh - 60px);
  background-color: #f9f9f9;
  box-shadow: -2px 0 5px rgba(0, 0, 0, 0.1);
  transform: ${({ open }) => (open ? "translateX(0)" : "translateX(100%)")};
  transition: transform 0.3s ease;
  z-index: 1001;
  display: flex;
  flex-direction: column;
`;

const Tabs = styled.div`
  display: flex;
  padding: 0;
  margin-bottom: 20px;
  overflow-x: auto;
  background-color: var(--color-container-card-default);
`;

const TabButton = styled.button`
  background-color: transparent;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  color: var(--color-background-card);
  font-weight: var(--font-weight-h4);
  border-bottom: 2px solid transparent;
  text-align: center;
  transition: all 0.3s ease;
  white-space: nowrap;

  &.active {
    background-color: var(--color-background-light);
    border-bottom: 2px solid var(--color-background-card);
  }

  &:hover {
    border-bottom: 2px solid var(--color-background-card);
  }
`;

const NotificationsContainer = styled.div`
  flex-grow: 1;
  padding: 15px;
  overflow-y: scroll;
`;

const NotificationItem = styled.a`
  display: flex;
  align-items: flex-start;
  text-decoration: none;
  color: inherit;
  margin-bottom: 20px;
  padding-bottom: 10px;
  border-bottom: 1px solid #ccc;

  &:hover {
    background-color: #f1f1f1;
  }
`;

const NotificationAvatar = styled.img`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 15px;
`;

const NotificationContentWrapper = styled.div`
  flex-grow: 1;
`;

const NotificationTitle = styled.div`
  font-size: 1rem;
  font-weight: bold;
  margin-bottom: 5px;
`;

const NotificationContent = styled.div`
  font-size: 0.9rem;
  color: #333;
  margin-bottom: 5px;
`;

const NotificationDate = styled.div`
  font-size: 0.85rem;
  color: #777;
`;

const DrawerToggleIcon = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  background-color: ${({ open }) => (open ? "#f0f0f0" : "transparent")};
  padding: 8px;
  border-radius: 50%;
  transition: background-color 0.3s ease;
`;

const NotificationDrawer = ({
  toggleIcon,
  readNotifications,
  unreadNotifications,
}) => {
  const [open, setOpen] = useState(false);
  const [activeTab, setActiveTab] = useState("unread");
  const drawerRef = useRef(null); // Reference for the drawer container
  const toggleButtonRef = useRef(null); // Reference for the toggle button

  // Function to handle closing drawer when clicking outside
  const handleClickOutside = (event) => {
    // Check if the click is outside the drawer and not on the toggle button
    if (
      drawerRef.current &&
      !drawerRef.current.contains(event.target) &&
      toggleButtonRef.current &&
      !toggleButtonRef.current.contains(event.target)
    ) {
      setOpen(false); // Close the drawer if clicked outside
    }
  };

  useEffect(() => {
    // Add event listener for clicks
    if (open) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }
    
    // Cleanup the event listener on component unmount
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [open]);

  // Select notifications based on the active tab
  const notificationsToDisplay =
    activeTab === "unread" ? unreadNotifications : readNotifications;

  return (
    <>
      {/* Pass the 'open' state to dynamically change background color */}
      <DrawerToggleIcon
        ref={toggleButtonRef} // Attach the ref to the toggle button
        open={open}
        onClick={() => setOpen(!open)} // Toggle the drawer open/close
      >
        {toggleIcon}
      </DrawerToggleIcon>

      <DrawerContainer ref={drawerRef} open={open}>
        <Tabs>
          <TabButton
            className={activeTab === "unread" ? "active" : ""}
            onClick={() => setActiveTab("unread")}
          >
            unread
          </TabButton>
          <TabButton
            className={activeTab === "read" ? "active" : ""}
            onClick={() => setActiveTab("read")}
          >
            read
          </TabButton>
        </Tabs>
        <NotificationsContainer>
          {notificationsToDisplay.length > 0 ? (
            notificationsToDisplay.map((notification, index) => (
              <NotificationItem key={notification.id} href={notification.url || `/dashboard/#/messages?user=${notification.id}`}>
                <NotificationAvatar
                  src={
                    notification?.recipient?.avatar_url ||
                    notification.actor?.avatar_url || ProfileIcon
                  }
                  alt="User Avatar"
                />
                <NotificationContentWrapper>
                  {notification.recipient && (
                    <NotificationTitle>
                      {notification.recipient.first_name}{" "}
                      {notification.recipient.last_name}
                    </NotificationTitle>
                  )}
                  <NotificationContent>
                    {notification.description ||
                      notification?.last_message?.content}
                  </NotificationContent>
                  <NotificationDate>
                    {moment(
                      notification.created_at ||
                        notification?.last_message?.created_at
                    ).fromNow()}
                  </NotificationDate>
                </NotificationContentWrapper>
              </NotificationItem>
            ))
          ) : (
            <p>No notifications to display.</p>
          )}
        </NotificationsContainer>
      </DrawerContainer>
    </>
  );
};

export default NotificationDrawer;
import React from "react";
import { Routes, Route } from "react-router-dom";
import Layout from "../Lib/Layout";
import  UserProvider from "../Lib/UserContext";
import QueryProvider from "../Lib/QueryClientProvider";
import UserProfileContainer from "../Profile/UserProfile";

export default function UserProfilePage() {
  return (
    <QueryProvider>
    <Layout>
      <UserProvider>
        <Routes>
          <Route path="/profile/user/:id" element={<UserProfileContainer />} />
        </Routes>
      </UserProvider>
    </Layout>
    </QueryProvider>
  );
}

import React from "react";
import Search from "./assets/search.svg";
import Help from "./assets/help.svg";
import styled from "styled-components";
import UserButton from "./UserButton";

// Styled Components for HeaderRightTools
const HeaderToolsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 24px;
`;

const Icon = styled.img`
  cursor: pointer;
  width: 18px;
`;

const HeaderRightTools = ({
  user,
  chatNotifications,
  activityNotification,
}) => {
  return (
    <HeaderToolsContainer>
      <Icon src={Search} alt="Search" />
      <Icon src={Help} alt="Help" />
      <div style={{ display: "flex", gap: "8px", alignItems: "center" }}>
        {chatNotifications}

        {activityNotification}
      </div>
      <UserButton
        userName={user.full_name}
        userEmail={user.job_title}
        userImage={user?.avatar?.url}
      />
    </HeaderToolsContainer>
  );
};

export default HeaderRightTools;

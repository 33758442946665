import React from "react";
import MDEditor from "@uiw/react-md-editor";
import rehypeSanitize from "rehype-sanitize";

const Code = ({ inline, children = [], className, ...props }) => {
  return <code className={className}>{children}</code>;
};

export default function Markdown({
  value,
  setValue,
  visibleDragbar,
  hideToolbar,
  preview,
  height,
  textareaProps,
  placeholder,
}) {
  //   const [value, setValue] = React.useState(mdMermaid);
  return (
    <div className="h-full mt-3">
      {!hideToolbar || setValue ? (
        <MDEditor
          value={value}
          height={height}
          onChange={setValue}
          defaultTabEnable={false}
          autoFocus={true}
          previewOptions={{
            rehypePlugins: [[rehypeSanitize]],
            components: {
              code: Code,
            },
          }}
          textareaProps={{
            placeholder,
            ...textareaProps,
          }}
          visibleDragbar={visibleDragbar}
          preview={preview}
          hideToolbar={hideToolbar}
        />
      ) : (
        <MDEditor.Markdown source={value} />
      )}
    </div>
  );
}

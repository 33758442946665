import React from 'react';
import styled from 'styled-components';

// Styled Components for Header
const HeaderContainer = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 30px;
  background-color: var(--color-container-card-default);
  color: #000;
  width: 100vw;
  box-sizing: border-box;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
`;

const LeftRightContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-grow: 1;
`;

const LeftComponent = styled.div`
  display: flex;
  align-items: center;
`;

const RightComponent = styled.div`
  display: flex;
  align-items: center;
  margin-left: auto;
`;

const MenuButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  margin-left: 20px;
  display: none;

  @media (max-width: 834px) {
    display: block;
  }
`;

const MenuIcon = styled.svg`
  width: 24px;
  height: 24px;
`;

const Header = ({ onMenuClick, leftC, rightC }) => (
  <HeaderContainer>
    <LeftRightContainer>
      {/* Left component */}
      <LeftComponent>{leftC}</LeftComponent>

      {/* Right component */}
      <RightComponent>{rightC}</RightComponent>
    </LeftRightContainer>

    {/* Menu button for small screens */}
    <MenuButton onClick={onMenuClick}>
      <MenuIcon
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
      >
        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16m-7 6h7" />
      </MenuIcon>
    </MenuButton>
  </HeaderContainer>
);

export default Header;

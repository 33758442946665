import React from "react";
import styled from "styled-components";
import StartupButton from "./StartupButton";
import NavLinks from "./NavLinks";
import PitchComponent from "./PitchComponent";

const SidebarContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 20px;
  position: sticky !important;
  top: 70px;
`;

const Sidebar = ({ startupDetails, navLinks }) => {
  return (
    <SidebarContainer>
      <StartupButton
        startupName={startupDetails.startupName}
        subText={startupDetails.subText}
        startupImage={startupDetails.startupImage}
      />
      <div>
        <NavLinks links={navLinks} />
        <PitchComponent />
      </div>
    </SidebarContainer>
  );
};

export default Sidebar;

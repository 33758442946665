import React from 'react';
import styled from 'styled-components';

const StyledFeatureButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 15px;
  border: 1px solid var(--color-button-accent);
  border-radius: 4px;
  color: var(--color-button-accent);
  font-weight: var(--font-weight-body-1);
  cursor: pointer;
  text-align: center;
  width: ${({ fullwidth }) => (fullwidth ? "100%" : "unset")};

  &:hover {
    background-color: var(--color-button-accent);
    color: var(--color-container-card-default);
  }
`;

const PlusSpan = styled.span`
  margin-left: 8px;
`;

const FeatureButton = ({ buttonText, action, hidePlus, fullwidth }) => {
  return (
    <StyledFeatureButton onClick={action} fullwidth={fullwidth}>
      {buttonText} {!hidePlus && <PlusSpan>+</PlusSpan>}
    </StyledFeatureButton>
  );
};

export default FeatureButton;

import React, { useEffect, useState } from "react";
import styled from "styled-components";
import ContainerButton from "./ContainerButton";
import moment from "moment";
import emptyImage from "./assets/empty-state.svg";
import TextCreationArea from "./TextCreationArea";
import { AssignMembers } from "../components/Startups/TextEditor";
import ChatDocumentUpload from "./ChatDocumentUpload";
import useStartupStore from "../stores/startupStore";
import EditorContentDisplay from "./EditorContentDisplay";
import ProgramChangesApproval from "../components/Startups/ProgramUpdateApproval";

const RoadmapDetailsContainer = styled.div`
  width: 100%;
  background-color: var(--color-container-card-default);
  border: 1px solid #dee8e7;
  padding: 20px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  gap: 10px;
  min-height: 100%;
  overflow-x: auto;
`;

const RoadmapHeader = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  gap: 16px;
  padding-bottom: 15px;

  h3 {
    font-weight: var(--font-weight-h4);
    color: var(--color-background-card);
  }

  span {
    color: var(--color-background-main);
    font-size: var(--font-size-body-1);
  }
`;

const RoadmapTabs = styled.div`
  display: inline-flex;
  margin-bottom: 10px;
  overflow-x: scroll;
`;

const TabButton = styled.button`
  background-color: transparent;
  border: none;
  padding: 8px 44px;
  cursor: pointer;
  color: var(--color-background-card);
  font-weight: var(--font-weight-h4);
  border-bottom: 2px solid var(--color-navigation-icon-dark);
  text-align: center;
  transition: all 0.3s ease;
  white-space: nowrap;

  &.active {
    background-color: var(--color-navigation-icon-light);
    color: #fff;
    border-bottom: 2px solid var(--color-background-card);
  }
`;

const RoadmapContent = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const EmptyState = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;

  img {
    margin-bottom: 20px;
  }
`;

const FlexColumnContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
`;

const RoadmapDetails = ({}) => {
  const [showChanges, setShowChanges] = useState(false);
  const {
    isOwner,
    isMember,
    role,
    selectedProgram,
    setSelectedProgram,
    editMode,
    setEditMode,
    members,
    program,
    setSubcategory,
  } = useStartupStore((state) => ({
    program: state.program,
    setProgram: state.setProgram,
    isOwner: state.isOwner,
    isMember: state.isMember,
    setSubcategory: state.setSubcategory,
    selectedProgram: state.selectedProgram,
    setSelectedProgram: state.setSelectedProgram,
    editMode: state.editMode,
    setEditMode: state.setEditMode,
    members: state.members,
  }));
  const handleTabClick = (index) => {
    setSelectedProgram({ sid: index });
  };

  console.log(program);

  return (
    <RoadmapDetailsContainer>
      {program.categories.length ? (
        <>
          <RoadmapHeader>
            <h3>{program.categories[selectedProgram.cid].name}</h3>
            {program.categories[selectedProgram.cid].end_date && (
              <span>
                {moment(
                  program.categories[selectedProgram.cid].end_date
                ).format("MMMM Do, YYYY")}
              </span>
            )}
            {editMode && (
              <AssignMembers
                canAssign={isOwner || role < 2}
                categoryId={program.categories[selectedProgram.cid].id}
                members={members}
                assign_members={
                  program.categories[selectedProgram.cid].assigned_users
                }
              />
            )}
          </RoadmapHeader>

          <RoadmapTabs>
            {program.categories[selectedProgram.cid].sub_categories.map(
              (tab, index) => (
                <TabButton
                  key={tab.id}
                  className={index === selectedProgram.sid ? "active" : ""}
                  onClick={() => handleTabClick(index)}
                >
                  {tab.name}
                </TabButton>
              )
            )}
          </RoadmapTabs>

          <RoadmapContent>
            {!editMode ? (
              <>
                {program.categories[selectedProgram.cid].sub_categories[
                  selectedProgram.sid
                ].response ||
                program.categories[selectedProgram.cid].sub_categories[
                  selectedProgram.sid
                ].diff_proposed_response ? (
                  <>
                    <EditorContentDisplay
                      content={
                        showChanges && isMember
                          ? program.categories[selectedProgram.cid]
                              .sub_categories[selectedProgram.sid]
                              .diff_proposed_response
                          : program.categories[selectedProgram.cid]
                              .sub_categories[selectedProgram.sid].response
                      }
                    />
                    {isMember && (
                      <EmptyState
                        key={
                          program.categories[selectedProgram.cid]
                            .sub_categories[selectedProgram.sid]
                            .waiting_for_approval
                        }
                        style={{ marginTop: "44px" }}
                      >
                        <div style={{ display: "flex", gap: "4px" }}>
                          {!program.categories[selectedProgram.cid]
                            .sub_categories[selectedProgram.sid]
                            .waiting_for_approval ? (
                            <ContainerButton
                              action={() => setEditMode(true)}
                              buttonText={`Edit ${
                                program.categories[selectedProgram.cid]
                                  .sub_categories[selectedProgram.sid].name
                              }`}
                            />
                          ) : (
                            <>
                              <ContainerButton
                                action={() => setShowChanges(!showChanges)}
                                buttonText={
                                  showChanges ? "Back" : "Pending Changes"
                                }
                              />

                              {showChanges && isOwner && (
                                <ProgramChangesApproval
                                  id={
                                    program.categories[selectedProgram.cid]
                                      .sub_categories[selectedProgram.sid].id
                                  }
                                  toggle={() => setShowChanges(false)}
                                  setSubcategory={setSubcategory}
                                />
                              )}
                            </>
                          )}
                        </div>
                      </EmptyState>
                    )}
                  </>
                ) : (
                  <EmptyState>
                    <img src={emptyImage} alt="Empty Content" />
                    {isMember && (
                      <ContainerButton
                        action={() => setEditMode(true)}
                        buttonText={`Create ${
                          program.categories[selectedProgram.cid]
                            .sub_categories[selectedProgram.sid].name
                        }`}
                      />
                    )}
                  </EmptyState>
                )}
              </>
            ) : (
                <TextCreationArea
                  key={selectedProgram.cid}
                  index={selectedProgram.sid}
                />
            )}
          </RoadmapContent>
        </>
      ) : (
        <EmptyState>
          <img src={emptyImage} alt="Empty Content" />
        </EmptyState>
      )}
    </RoadmapDetailsContainer>
  );
};

export default RoadmapDetails;

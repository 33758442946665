import React from "react";
import styled from "styled-components";

// Extension to color mapping
const extensionColors = {
  PDF: "#D32F2F",
  DOC: "#1976D2",
  DOCX: "#1976D2",
  XLS: "#388E3C",
  XLSX: "#388E3C",
  PPT: "#E64A19",
  PPTX: "#E64A19",
  JPG: "#FBC02D",
  JPEG: "#FBC02D",
  PNG: "#0288D1",
  GIF: "#7B1FA2",
  TXT: "#455A64",
  ZIP: "#F57C00",
  MP3: "#009688",
  MP4: "#FF5722",
};

const defaultColor = "#1976D2";

const ScrollableContainer = styled.div`
  max-height: 150px; /* Set the maximum height of the scrollable area */
  overflow-y: auto;  /* Enable vertical scrolling */
`;

// FileList Component
const FileList = ({ files, fileRecords, }) => {
  const renderFile = (index, file, isRecord = false) => {
    const fileExtension = isRecord
      ? file.name.split(".").pop().toUpperCase()
      : file.url.split(".").pop().toUpperCase();

    const fileSizeKiB = ((file.size_bytes || file.size) / 1024.0).toFixed(1);

    const fileIcon = isRecord ? file.icon : 'https://www.dropbox.com/static/images/icons64/page_white.png';

    return (
      <div key={`${file.id || file.url || file.name}-${index}`}>
        <div className="cdx-attaches cdx-attaches--with-file">
          <div className="cdx-attaches__file-icon">
            {isRecord && file.icon ? (
              <img
                src={fileIcon}
                alt={`${fileExtension} icon`}
                className="cdx-attaches__file-icon-background"
              />
            ) : (
              <>
                <div
                  className="cdx-attaches__file-icon-background"
                  style={{
                    backgroundColor:
                      extensionColors[fileExtension] || defaultColor,
                  }}
                ></div>
                <div
                  className="cdx-attaches__file-icon-label"
                  title={fileExtension}
                  style={{
                    backgroundColor:
                      extensionColors[fileExtension] || defaultColor,
                  }}
                >
                  {fileExtension}
                </div>
              </>
            )}
          </div>
          <div className="cdx-attaches__file-info">
            <div
              className="cdx-attaches__title"
              data-placeholder="File title"
              data-empty="false"
            >
              {isRecord ? file.name : file.url.split("/").pop()}
            </div>
            <div className="cdx-attaches__size">{fileSizeKiB} KiB</div>
          </div>
          <a
            className="cdx-attaches__download-button"
            href={file.url}
            target="_blank"
            rel="nofollow noindex noreferrer"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              fill="none"
              viewBox="0 0 24 24"
            >
              <path
                stroke="currentColor"
                strokeLinecap="round"
                strokeWidth="2"
                d="M7 10L11.8586 14.8586C11.9367 14.9367 12.0633 14.9367 12.1414 14.8586L17 10"
              ></path>
            </svg>
          </a>
        </div>
      </div>
    );
  };

  return (
    <ScrollableContainer>
      {files?.map((file, i) => renderFile(i, file))}
      {fileRecords?.map((fileRecord, i) => renderFile(i, fileRecord, true))}
    </ScrollableContainer>
  );
};

export default FileList;

import React from "react";
import HashLayout from "../Lib/HashLayout";
import UserProvider from "../Lib/UserContext";
import ProfilePage from "../Profile/Profile";
import QueryProvider from "../Lib/QueryClientProvider";

export default function DashbaordPage() {
  return (
    <QueryProvider>
      <HashLayout>
        <UserProvider>
          <ProfilePage />
        </UserProvider>
      </HashLayout>
    </QueryProvider>
  );
}

import React, { useState } from "react";
import fetchApi from "../Lib/api";
import TagsInput from "../Lib/TagsInput";

const ProfessorSignUpForm = () => {
  const [formData, setFormData] = useState({
    full_name: "",
    email: "",
    phone: "",
    position: "",
    linkedin: "",
    phd: "No",
    institution: "",
    expertise: "",
    bio: "",
    terms_and_policy: false,
  });

  const [notification, setNotification] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [selectedAreas, setSelectedAreas] = useState([]);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  const handleMultiSelectChange = (selected) => {
    setSelectedAreas(selected);
    console.log(selected)
    setFormData((prevData) => ({
      ...prevData,
      expertise: selected.map((option) => option),
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await fetchApi("/expert_signups", "POST", {
        expert_signup: {
          ...formData,
          expertise: formData.expertise.join(", "),
        },
      });

      const data = await response.json();

      console.log(data)

      if (response.ok) {
        setNotification(
          <div role="alert">
            <p className="section-text text-green-700">{data.message}</p>
            <p className="section-text" style={{ marginTop: "3rem" }}>
              Thank you for joining NowCollab! We appreciate your interest and
              will review your information shortly. Our team will reach out to
              you within 3 business days with the next steps.
            </p>
            <p className="section-text">
              While you wait, feel free to connect with us on social media or
              check out some useful links.
            </p>
            <div className="flex justify-between mt-8">
              <ul className="social-list flex space-x-4">
                <li>
                  <a href="#" className="social-link">
                    <ion-icon name="logo-facebook"></ion-icon>
                  </a>
                </li>
                <li>
                  <a href="#" className="social-link">
                    <ion-icon name="logo-twitter"></ion-icon>
                  </a>
                </li>
                <li>
                  <a href="#" className="social-link">
                    <ion-icon name="logo-linkedin"></ion-icon>
                  </a>
                </li>
                <li>
                  <a href="#" className="social-link">
                    <ion-icon name="logo-instagram"></ion-icon>
                  </a>
                </li>
              </ul>
              <ul className="quicklink-list space-y-2 mt-4">
                <li>
                  <a href="#" className="quicklink">
                    Privacy Policy
                  </a>
                </li>
                <li>
                  <a href="#" className="quicklink">
                    Terms of Service
                  </a>
                </li>
                <li>
                  <a href="#" className="quicklink">
                    About Us
                  </a>
                </li>
                <li>
                  <a href="#" className="quicklink">
                    Contact Us
                  </a>
                </li>
                <li>
                  <a href="#" className="quicklink">
                    Careers
                  </a>
                </li>
                <li>
                  <a href="#" className="quicklink">
                    FAQ
                  </a>
                </li>
              </ul>
            </div>
          </div>
        );
        setFormData({
          full_name: "",
          email: "",
          phone: "",
          position: "",
          linkedin: "",
          phd: "No",
          institution: "",
          expertise: "",
          bio: "",
          terms_and_policy: false,
        });
        setIsSubmitted(true);
      } else {
        setNotification(
          <div role="alert" className="text-red-700">
            <p className="section-text">
              {data.message || "There was an error. Please try again."}
            </p>
          </div>
        );
      }
    } catch (error) {
      console.log(error)
      setNotification(
        <div role="alert" className="text-red-700">
          <p className="section-text">
            An error occurred. Please try again later.
          </p>
        </div>
      );
    }
  };

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      <button onClick={openModal} className="btn btn-primary">
        Join as an EXPERT
      </button>

      {isModalOpen && (
        <div
          id="formModal"
          className="modal shadow-sm fixed inset-0 bg-black bg-opacity-50 flex items-start justify-center z-10"
          onClick={(e) => {
            if (e.target.id === "formModal") closeModal();
          }}
        >
          <div className="modal-content bg-white max-w-[370px] lg:max-w-[700px] rounded-lg relative mt-8 lg:mt-[10vh] ">
            <div className="modal-body">
              <form
                onSubmit={handleSubmit}
                className="space-y-6 p-4  lg:p-12 shadow-md rounded-lg"
              >
                {isSubmitted ? (
                  <div id="notification_area">{notification}</div>
                ) : (
                  <>
                    {notification && !isSubmitted && (
                      <div id="notification_area">{notification}</div>
                    )}
                    <div className="text-center mb-8">
                      <h1 className="block text-2xl font-bold text-gray-800 dark:text-white">
                        Expert Sign-Up Form
                      </h1>
                    </div>
                    <div className="grid grid-cols-1 lg:grid-cols-3 gap-6">
                      {/* Full Name */}
                      <div>
                        <div>
                          <label className="inline font-light text-md mb-1 leading-[1.4]">
                            Full Name
                            <span className="text-red-500 inline">*</span>
                          </label>
                        </div>
                        <input
                          type="text"
                          name="full_name"
                          value={formData.full_name}
                          onChange={handleChange}
                          required
                          className="bg-white border border-gray-400 rounded-lg text-gray-900 font-normal h-16 leading-[1.5] px-3 py-2 focus:outline-none w-full"
                        />
                      </div>

                      {/* Email Address */}
                      <div>
                        <div>
                          <label className="inline text-md mb-1 leading-[1.4]">
                            Email Address
                            <span className="text-red-500 inline">*</span>
                          </label>
                        </div>
                        <input
                          type="email"
                          name="email"
                          value={formData.email}
                          onChange={handleChange}
                          required
                          className="bg-white border border-gray-400 rounded-lg text-gray-900 text-[14px] font-normal h-16 leading-[1.5] px-3 py-2 focus:outline-none w-full"
                        />
                      </div>

                      {/* Phone Number */}
                      <div>
                        <label className="inline text-md mb-1 leading-[1.4]">
                          Phone Number
                        </label>
                        <input
                          type="tel"
                          name="phone"
                          value={formData.phone}
                          onChange={handleChange}
                          className="bg-white border border-gray-400 rounded-lg text-gray-900 text-[14px] font-normal h-16 leading-[1.5] px-3 py-2 focus:outline-none w-full"
                        />
                      </div>
                    </div>
                    <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
                      {/* Current Position */}
                      <div>
                        <div>
                          <label className="inline text-md mb-1 leading-[1.4]">
                            Current Position/Title
                            <span className="text-red-500 inline">*</span>
                          </label>
                        </div>
                        <input
                          type="text"
                          name="position"
                          value={formData.position}
                          onChange={handleChange}
                          required
                          className="bg-white border border-gray-400 rounded-lg text-gray-900 text-[14px] font-normal h-16 leading-[1.5] px-3 py-2 focus:outline-none w-full"
                        />
                        <p className="mt-1 text-[11px] text-gray-500">
                          State your current role or title to highlight your
                          professional standing.
                        </p>
                      </div>

                      {/* LinkedIn Profile */}
                      <div>
                        <div>
                          <label className="inline text-md mb-1 leading-[1.4]">
                            LinkedIn Profile URL
                            <span className="text-red-500 inline">*</span>
                          </label>
                        </div>
                        <input
                          type="url"
                          name="linkedin"
                          value={formData.linkedin}
                          onChange={handleChange}
                          required
                          className="bg-white border border-gray-400 rounded-lg text-gray-900 text-[14px] font-normal h-16 leading-[1.5] px-3 py-2 focus:outline-none w-full"
                        />
                      </div>
                    </div>
                    {/* PhD */}
                    <div>
                      <div>
                        <label className="inline text-md mb-1 leading-[1.4]">
                          PhD
                          <span className="text-red-500 inline">*</span>
                        </label>
                      </div>
                      <select
                        name="phd"
                        value={formData.phd}
                        onChange={handleChange}
                        required
                        className="bg-white border border-gray-400 rounded-lg text-gray-900 text-[14px] font-normal h-16 leading-[1.5] px-3 py-2 focus:outline-none w-full"
                      >
                        <option value="">Select...</option>
                        <option value="Yes">Yes</option>
                        <option value="No">No</option>
                      </select>
                      <p className="mt-1 text-[11px] text-gray-500">
                        Are you a PhD holder or currently pursuing a PhD?
                      </p>
                    </div>
                    <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
                      {/* Affiliated Institution */}
                      <div>
                        <div>
                          <label className="inline text-md mb-1 leading-[1.4]">
                            Affiliated Institution
                            <span className="text-red-500 inline">*</span>
                          </label>
                        </div>
                        <input
                          type="text"
                          name="institution"
                          value={formData.institution}
                          onChange={handleChange}
                          required
                          className="bg-white border border-gray-400 rounded-lg text-gray-900 text-[14px] font-normal h-16 leading-[1.5] px-3 py-2 focus:outline-none w-full"
                        />
                        <p className="mt-1 text-[11px] text-gray-500">
                          Enter the name of the institution or organization
                          you're associated with.
                        </p>
                      </div>

                      {/* Areas of Expertise */}
                      <div>
                        <div>
                          <label className="inline text-md mb-1 leading-[1.4]">
                            Areas of Expertise
                            <span className="text-red-500 inline">*</span>
                          </label>
                        </div>
                        <TagsInput
                          data={[]}
                          value={selectedAreas}
                          onChange={handleMultiSelectChange}
                        />
                        <p className="mt-1 text-[11px] text-gray-500">
                          Type, press Enter to save and to add more areas of expertise
                        </p>
                      </div>
                    </div>
                    {/* Comments */}
                    <div className="col-span-full">
                      <label className="inline text-md mb-1 leading-[1.4]">
                      Short Description
                      </label>
                      <textarea
                        name="comments"
                        value={formData.comments}
                        onChange={handleChange}
                        rows={6}
                        className="bg-white border border-gray-400 rounded-lg text-gray-900 text-[14px] font-normal h-32 leading-[1.5] px-3 py-2 focus:outline-none w-full"
                      />
                    </div>
                    {/* Terms */}
                    <div className="flex items-start">
                      <input
                        type="checkbox"
                        name="terms_and_policy"
                        checked={formData.terms_and_policy}
                        onChange={handleChange}
                        required
                        className="h-8 w-8 text-blue-600 border-gray-300 rounded focus:ring-2 focus:ring-blue-500"
                      />
                      <label className="ml-2 block text-gray-900">
                        I agree to the{" "}
                        <a
                          href="/terms_and_policy#terms"
                          className="text-blue-600 hover:text-blue-500 inline ml-1"
                        >
                          Terms of Service{" "}
                        </a>{" "}
                        and{" "}
                        <a
                          href="/terms_and_policy#privacy_policy"
                          className="text-blue-600 hover:text-blue-500 inline ml-1"
                        >
                          Privacy Policy
                        </a>
                        .
                      </label>
                    </div>
                    {/* Submit Button */}
                    <div>
                      <button
                        type="submit"
                        className="w-full py-3 px-4 inline-flex justify-center items-center gap-x-2 text-sm font-medium rounded-lg border border-transparent bg-blue-600 text-white hover:bg-blue-700 focus:outline-none focus:bg-blue-700 disabled:opacity-50"
                      >
                        Get started
                      </button>
                    </div>
                  </>
                )}
              </form>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ProfessorSignUpForm;

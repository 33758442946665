import React from "react";
import styled from "styled-components";
import RoadmapList from "./RoadmapList";
import RoadmapDetails from "./RoadmapDetails";
import RoadmapTimeline from "./RoadmapTimeline";
import PackageSelector from "./PackageSelector";
import { create } from "zustand";
import useStartupStore from "../stores/startupStore";
import StartupMembers from "./StartupMembers";
import OpeningRoles from "../components/Startups/OpeningRoles";
import Modal from "./Modal";
import fetchApi from "../components/Lib/api";

export const useRoadmapStore = create((set, get) => ({
  date: null,
  setDate: (newDate) => set({ date: newDate }),
  endDate: null,
  setEndDate: (newEndDate) => set({ endDate: newEndDate }),
  duration: "0",
  setDuration: (newDuration) => set({ duration: newDuration }),
  opened: false,
  setOpened: (isOpened) => set({ opened: isOpened }),
  loading: false,
  setLoading: (isLoading) => set({loading: isLoading}),

  handleUpdate: async (slug, setProgram) => {
    const { date, duration, setOpened, setLoading, endDate } = get();
    setOpened(true);
    setLoading(true);
    console.log(setProgram);
    try {
      const response = await fetchApi(
        `/startup_entities/${slug}/update_roadmap_duration`,
        "PATCH",
        { roadmap_duration: duration, start_date: date, end_date: endDate }
      );

      if (response.ok) {
        const res = await response.json();
        console.log(res);
        setProgram({ ...res.program });
        console.log(res.program);
      } else {
        const { error } = await response.json();
        console.error(error);
      }
    } catch (error) {
      console.error("Error during role update:", error);
    } finally {
      setOpened(false);
      setLoading(false)
    }
  },
}));

const StartupBodyContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  gap: 20px;
  min-height: 150vh;
`;

const LeftColumn = styled.div`
  border-radius: 8px;
  grid-column: 1 / span 8;
  display: flex;
  flex-direction: column;
  gap: 60px;

  @media (max-width: 1024px) {
    grid-column: 1 / span 12;
  }
`;

const RightColumn = styled.div`
  border-radius: 8px;
  grid-column: 9 / span 4;
  display: flex;
  flex-direction: column;
  gap: 20px;

  @media (max-width: 1024px) {
    grid-column: 1 / span 12;
  }
`;

const StartupBody = () => {
  const { slug, program, editMode, setEditMode } = useStartupStore((state) => ({
    slug: state.slug,
    program: state.program,
    editMode: state.editMode,
    setEditMode: state.setEditMode,
  }));

  return (
    <StartupBodyContainer>
      <LeftColumn>
        {program ? (
          <>
            <div>
              <div style={{ display: "flex" }}>
                <RoadmapList />
                <RoadmapDetails />
              </div>
              <Modal
                showmodal={editMode}
                setshowmodal={setEditMode}
                height="95vh"
                width="70vw"
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    padding: "16px 24px",
                  }}
                >
                  <RoadmapList />
                  <RoadmapDetails />
                </div>
              </Modal>
            </div>
          </>
        ) : (
          <>
            <RoadmapCompletionDuration slug={slug} />
          </>
        )}
      </LeftColumn>
      <RightColumn>
        <StartupMembers />
        <OpeningRoles />
      </RightColumn>
    </StartupBodyContainer>
  );
};

const RoadmapCompletionDuration = ({ slug }) => {
  const { opened, setOpened } = useRoadmapStore();

  console.log(opened)

  // const dateDiff = program?.last_target_update
  //   ? moment().diff(moment(program?.last_target_update), "days")
  //   : 7;

  return (
    <>
      <RoadmapTimeline action={() => setOpened(true)} />
      <Modal showmodal={opened} setshowmodal={setOpened}>
        <PackageSelector useRoadmapStore={useRoadmapStore} slug={slug} />
      </Modal>
    </>
  );
};

export default StartupBody;

import React, { memo } from "react";
import styled from "styled-components";
import arrowUpIcon from "./assets/arrow-up.svg";
import arrowDownIcon from "./assets/arrow-down.svg";
import Step1 from "./assets/step-1.png";
import Step2 from "./assets/step-2.png";
import Step3 from "./assets/step-3.png";
import HelpIcon from "./assets/help.svg";
import ContainerButton from "./ContainerButton";
import useStartupStore from "../stores/startupStore";
import ContactMessage from "../components/Startups/ContactMessage";

const MemoContactMessage = memo(ContactMessage);

const RoadmapTimelineContainer = styled.div`
  padding: 20px;
  border: 1px solid #dee8e7;
  border-radius: 4px;
  background-color: var(--color-container-card-default);
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 34px;
  text-align: left;
`;

const RoadmapHeader = styled.div`
  align-self: flex-start;
`;

const HeaderTitle = styled.h3`
  font-weight: var(--font-weight-h4);
  color: var(--color-background-card);
`;

const HeaderText = styled.p`
  color: #68706b;
  margin-top: 8px;
`;

const RoadmapSteps = styled.div`
  display: grid;
  grid-template-columns: 1fr 0.1fr 1fr 0.1fr 1fr;
  gap: 4px;
  margin: 20px 40px;
`;

const Step = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  & > div {
    align-self: flex-start;
    margin-bottom: 10px;
  }
`;

const StepIcon = styled.img``;

const StepTitle = styled.h3`
  margin-top: 10px;
  font-size: var(--font-size-body-1);
  font-weight: var(--font-weight-h4);
  color: var(--color-background-card);
`;

const StepDescription = styled.p`
  color: var(--color-background-main);
`;

const StepArrow = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 60px;
`;

const RoadmapFooter = styled.div`
  text-align: center;
  margin: 4px auto;
  width: 40%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;
`;

const HelpSection = styled.div`
  margin-top: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
`;

const HelpLink = styled.a`
  font-size: var(--font-size-body-1);
  color: var(--color-background-main);
  cursor: pointer;
`;

const RoadmapTimeline = ({ action }) => {
  const { isOwner, isMember, role, slug } = useStartupStore((state) => ({
    isMember: state.isMember,
    isOwner: state.isOwner,
    slug: state.slug,
    role: state.role,
  }));

  return (
    <RoadmapTimelineContainer>
      <RoadmapHeader>
        <HeaderTitle>Roadmap Overview</HeaderTitle>
        <HeaderText>
          Track the progress of your project from setting up the timeline to
          achieving growth milestones.
        </HeaderText>
      </RoadmapHeader>

      {isOwner ? (
        <>
          <RoadmapSteps>
            <Step>
              <div>
                <StepTitle>Step 1</StepTitle>
                <StepDescription>Set roadmap timeline</StepDescription>
              </div>
              <StepIcon src={Step1} alt="Step 1" />
            </Step>

            <StepArrow>
              <img src={arrowDownIcon} alt="Arrow to next step" />
            </StepArrow>

            <Step>
              <div>
                <StepTitle>Step 2</StepTitle>
                <StepDescription>Complete with members</StepDescription>
              </div>
              <StepIcon src={Step2} alt="Step 2" />
            </Step>

            <StepArrow>
              <img src={arrowUpIcon} alt="Arrow to next step" />
            </StepArrow>

            <Step>
              <div>
                <StepTitle>Step 3</StepTitle>
                <StepDescription>Start dream growth</StepDescription>
              </div>
              <StepIcon src={Step3} alt="Step 3" />
            </Step>
          </RoadmapSteps>

          <RoadmapFooter>
            <HeaderTitle>Set Roadmap Completion Timeline</HeaderTitle>
            <HeaderText>
              Roadmap tasks required to be covered within the mentioned
              timeline, viewers and start-up visitors can see the timeline and
              act accordingly.
            </HeaderText>
            <ContainerButton buttonText="Set Timeline" action={action} />
            <HelpSection>
              <img src={HelpIcon} alt="Help" />
              <HelpLink href="#">Need Help</HelpLink>
            </HelpSection>
          </RoadmapFooter>
        </>
      ) : (
        <RoadmapFooter>
          <HeaderTitle>Roadmap Unavailable</HeaderTitle>
          <HeaderText>
            The project roadmap is not yet available. Please check back later or
            contact the project owners for more information.
          </HeaderText>
          <MemoContactMessage
            slug={slug}
            isMember={isOwner || isMember}
            role={role}
          />
          <HelpSection>
            <img src={HelpIcon} alt="Help" />
            <HelpLink href="#">Need Help</HelpLink>
          </HelpSection>
        </RoadmapFooter>
      )}
    </RoadmapTimelineContainer>
  );
};

export default RoadmapTimeline;

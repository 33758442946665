import React, { useEffect, useState } from "react";
import NavbarSimple from "./SideBar";
import fetchApi from "./api";
import App from "../../channels/consumer";
import LanguageDropdown from "./Language";
import Layout from "../../stories/Layout";
import Header from "../../stories/Header";
import Logo from "../../stories/Logo";
import HeaderRightTools from "../../stories/HeaderRightTools";
import useUserStore from "../../stores/userStore";
import NotificationDrawer from "../../stories/NotificationDrawer";
import { styled } from "styled-components";
import Bell from "../../stories/assets/bell.svg";
import Mail from "../../stories/assets/mail.svg";
import { useTranslation } from "react-i18next";
import { ActionIcon, Button, Dialog, rem, Textarea } from "@mantine/core";
import Notification from "./Notification";
import { useDisclosure } from "@mantine/hooks";
import {  IconNote } from "@tabler/icons-react";

const Icon = styled.img`
  cursor: pointer;
  width: 18px;
`;

const IconContainer = styled.div`
  position: relative;
`;

const NotificationIndicator = styled.span`
  position: absolute;
  top: 8px;
  right: 8px;
  width: 10px;
  height: 10px;
  border: 1px solid var(--color-container-card-default);
  background: #0cb14e;
  border-radius: 50%;
`;

const NotificationIndicatorMessage = styled.span`
  position: absolute;
  top: 5px;
  right: 5px;
  width: 10px;
  height: 10px;
  border: 1px solid var(--color-container-card-default);
  background: #0cb14e;
  border-radius: 50%;
`;

export default function HeaderAndSidebar({ navlinks, children }) {
  const { user, recent_activities_count, chats_count, gamification } =
    useUserStore((state) => state.user);

  return (
    <>
      <Layout
        header={
          <Header
            leftC={<Logo />}
            rightC={
              <HeaderRightTools
                hasNotification={!!recent_activities_count}
                user={user}
                activityNotification={
                  <ActivitiesNotification
                    user_id={user.id}
                    recent_activities_count={recent_activities_count}
                  />
                }
                chatNotifications={
                  <MessagesNotification
                    user_id={user.id}
                    chats_count={chats_count}
                  />
                }
              />
            }
          />
        }
        sidebarContent={<NavbarSimple navlinks={navlinks} />}
        mainContent={children}
      />{" "}
      <Feedback />
    </>
  );
}

const play = () => {
  const notificationSound = new Audio("/notification.mp3");
  notificationSound.play();
};

const GamificationBar = ({ gamification, user_id }) => {
  const [userGamification, setUserGamification] = useState(gamification);
  useEffect(() => {
    const subscription = App.subscriptions.create(
      { channel: "GamificationChannel", user_id },
      {
        connected() {
          console.log("Successfully connected to Notifications Channel");
        },
        disconnected() {
          console.log("Disconnected from Notifications Channel");
        },
        received(data) {
          console.log("New notification received:", data);
          setUserGamification(data);
          play();
        },
      }
    );

    return () => subscription.unsubscribe();
  }, []);

  return (
    <div
      className="flex w-[200px] my-auto h-4 bg-gray-300 rounded-full overflow-hidden"
      role="progressbar"
      aria-valuenow="75"
      aria-valuemin="0"
      aria-valuemax="100"
    >
      <div
        className="flex flex-col p-2 justify-center rounded-full overflow-hidden bg-gradient-to-r from-green-400 to-blue-500 text-xs text-white text-center whitespace-nowrap transition duration-500"
        style={{
          width: `${
            (userGamification.current_points /
              userGamification.next_level.points_threshold) *
            100
          }%`,
        }}
      >
        {userGamification.current_points}PX
      </div>
    </div>
  );
};

const Feedback = () => {
  const { t } = useTranslation();
  const [opened, { toggle, close }] = useDisclosure(true);

  const [desc, setDesc] = useState("");
  const [rating, setRating] = useState(1);
  const [notification, setNotification] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const { user } = useUserStore((state) => state.user);

  const submitFeedback = async () => {
    if (!desc.trim()) {
      setError(t("sidebar.short_feedback_error"));
      return;
    }
    setLoading(true);
    try {
      const response = await fetchApi("/feedbacks", "POST", {
        feedback: {
          name: `${user.first_name} ${user.last_name}`,
          email: user.email,
          message: desc || "All Good. No Comment.",
          rating,
        },
      });

      if (response.ok) {
        const res = await response.json();
        setNotification({ type: "success", content: res.message });
      } else {
        const msg = (await response.json()).error;
        setNotification({ type: "failure", content: res.error });
        console.error(msg);
      }
    } catch (error) {
      console.error("Error during feedback sending", error);
    } finally {
      setDesc("");
    }

    setLoading(false);
  };
  return (
    <Dialog
      opened={true}
      withCloseButton={opened}
      onClose={close}
      position={{ bottom: 10, right: 20 }}
      size="xl"
      radius="md"
      style={{width: "fit-content", maxWidth: "400px"}}
      p={0}
    >
      {!opened ? (
        <ActionIcon
          size={42}
          onClick={toggle}
          variant="default"
          aria-label="ActionIcon with size as a number"
        >
          <IconNote style={{ width: rem(24), height: rem(24) }} />
        </ActionIcon>
      ) : (
        <div className="flex w-full flex-col border rounded-lg bg-white p-4">
          {notification && <Notification {...notification} />}
          <p className="m-2 text-sm text-center leading-relaxed text-gray-600">
            {t("sidebar.feedback_info")}
          </p>
          <div className="mb-2">
            <Textarea
              onChange={(event) => setDesc(event.currentTarget.value)}
              value={desc}
              id="message"
              name="message"
              placeholder={t("sidebar.feedback_message")}
              withAsterisk
              error={error}
            />
          </div>
          <Button
            onClick={submitFeedback}
            loading={loading}
            className="rounded border-0 bg-indigo-500 py-2 px-6 text-lg text-white hover:bg-indigo-600 focus:outline-none"
          >
            {t("sidebar.feedback_submit")}
          </Button>
        </div>
      )}
    </Dialog>
  );
};

const MessagesNotification = ({ chats_count, user_id }) => {
  const [unread, setUnread] = useState([]);
  const [read, setRead] = useState([]);
  const [newCount, setNewCount] = useState(0);

  useEffect(() => {
    const subscription = App.subscriptions.create(
      { channel: "MessagesChannel", user_id },
      {
        connected() {
          console.log("Successfully connected to Notifications Channel");
        },
        disconnected() {
          console.log("Disconnected from Notifications Channel");
        },
        received(data) {
          console.log("New notification received:", data);
          setUnread((p) => [data, ...p]);
          setNewCount((p) => ++p);
          play();
        },
      }
    );

    return () => subscription.unsubscribe();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetchApi(`/api/users/fetch_chats`, "GET");

        if (response.ok) {
          const res = await response.json();
          setUnread(res.unread_chats);
          setRead(res.next_five_chats);
        } else {
          const msg = (await response.json()).error;
          console.error(msg);
        }
      } catch (error) {
        console.error("Error during fetching activities:", error);
      }
    };

    fetchData();
  }, []);
  return (
    <IconContainer>
      {chats_count + newCount ? <NotificationIndicatorMessage /> : <></>}
      <NotificationDrawer
        toggleIcon={<Icon src={Mail} alt="Messages" />}
        readNotifications={read}
        unreadNotifications={unread}
      />
    </IconContainer>
  );
};

const ActivitiesNotification = ({ recent_activities_count, user_id }) => {
  const [unread, setUnread] = useState([]);
  const [read, setRead] = useState([]);
  const [newCount, setNewCount] = useState(0);

  useEffect(() => {
    const subscription = App.subscriptions.create(
      { channel: "UserActivitiesChannel", user_id },
      {
        connected() {
          console.log("Connected to Activities Channel");
        },
        disconnected() {
          console.log("Disconnected from Activities Channel");
        },
        received(data) {
          setUnread((p) => [data, ...p]);
          setNewCount((p) => ++p);
          play();
        },
      }
    );

    return () => subscription.unsubscribe();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetchApi(`/api/users/fetch_activities`, "GET");

        if (response.ok) {
          const res = await response.json();
          setUnread(res.unread_activities);
          setRead(res.next_five_activities);
        } else {
          const msg = (await response.json()).error;
          console.error(msg);
        }
      } catch (error) {
        console.error("Error during fetching activities:", error);
      }
    };

    fetchData();
  }, []);
  return (
    <IconContainer>
      <NotificationDrawer
        toggleIcon={<Icon src={Bell} alt="Notifications" />}
        readNotifications={read}
        unreadNotifications={unread}
      />
      {recent_activities_count + newCount ? <NotificationIndicator /> : <></>}
    </IconContainer>
  );
};

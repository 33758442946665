import {
  ActionIcon,
  Anchor,
  Button,
  Chip,
  Grid,
  Group,
  Modal,
  Paper,
  Select,
  SimpleGrid,
  Stack,
  Switch,
  Text,
  TextInput,
  Textarea,
  Title,
  rem,
} from "@mantine/core";
import { MultiSelect } from "react-multi-select-component";
import React, { useContext, useState } from "react";
import { countriesData, industries, skills } from "../../Onboarding/data";
import { useDisclosure } from "@mantine/hooks";
import { onSubmit } from "../../Onboarding/Onboarding";
import {
  IconCheck,
  IconExternalLink,
  IconPencil,
  IconTrash,
  IconX,
} from "@tabler/icons-react";
import useUserStore from "../../../stores/userStore";

export default function Profile({ isEditMode, setIsEditMode, form }) {
  const {
    user: { user, gamification },
    setUser,
  } = useUserStore((state) => state);

  console.log(user)

  return (
    <main className="flex justify-center ">
      <div className="max-w-5xl w-full lg:p-6">
        <Stack spacing={10}>
          <AboutMe
            setUser={setUser}
            user={user}
            isEditMode={isEditMode}
            form={form}
            gamification={gamification}
          />

          <Skills
            setUser={setUser}
            user={user}
            isEditMode={isEditMode}
            form={form}
          />

          <Personal
            setUser={setUser}
            user={user}
            isEditMode={isEditMode}
            form={form}
          />

          <Education
            setUser={setUser}
            user={user}
            isEditMode={isEditMode}
            form={form}
          />
          <Job
            setUser={setUser}
            user={user}
            isEditMode={isEditMode}
            form={form}
          />

          <Socials
            setUser={setUser}
            user={user}
            isEditMode={isEditMode}
            form={form}
          />
        </Stack>
      </div>
    </main>
  );
}

const AboutMe = ({ setUser, isEditMode, form, user, gamification }) => {
  const [editAboutMe, setEditAboutMe] = useState(false);
  return (
    <Grid>
      <Grid.Col lg={4} order={1} orderSm={1} orderLg={1}>
        <Paper
          radius="md"
          withBorder
          p="xl"
          mih={300}
          style={{
            background: "linear-gradient(to right, #0f0c29, #302b63, #24243e)",
          }}
        >
          <Group align="end" mb={4} noWrap>
            <Title
              order={4}
              size="h4"
              fw={700}
              className="whitespace-nowrap text-white"
            >
              Points & Level
            </Title>
          </Group>

          <Stack mt={10}>
            <div className="w-full">
              <div className="mb-2 flex justify-between items-center">
                <h3 className="text-sm font-semibold text-gray-100">
                  {gamification.current_level.name}
                </h3>
                <span className="text-sm text-gray-100">
                  {gamification.next_level.points_threshold}PX
                </span>
              </div>
              <div
                className="flex w-full h-4 bg-gray-300 rounded-full overflow-hidden"
                role="progressbar"
                aria-valuenow="75"
                aria-valuemin="0"
                aria-valuemax="100"
              >
                <div
                  className="flex flex-col p-2 justify-center rounded-full overflow-hidden bg-gradient-to-r from-green-400 to-blue-500 text-xs text-white text-center whitespace-nowrap transition duration-500"
                  style={{
                    width: `${
                      (gamification.current_points /
                        gamification.next_level.points_threshold) *
                      100
                    }%`,
                  }}
                >
                  {gamification.current_points}PX
                </div>
              </div>
            </div>

            <h3 className="text-sm font-semibold text-gray-100">
              Achievements
            </h3>

            {/* <ul className="flex flex-col text-center">
              <li className="list-none">
                <p className="border-s-2 border-transparent text-sm text-gray-300">
                  Current Level
                </p>

                <p className="mb-3 text-sm font-semibold text-white break-all">
                  {gamification.current_level.name} -{" "}
                  {gamification.current_points}PX
                </p>
              </li>
              <li className="list-none">
                <p className="border-s-2 border-transparent text-sm text-gray-300">
                  Previous Level
                </p>

                <p className="mb-3 text-sm font-semibold text-white break-all">
                  {gamification.previous_level.name} -{" "}
                  {gamification.previous_level.points_threshold}PX
                </p>
              </li>
              <li className="list-none">
                <p className="border-s-2 border-transparent text-sm text-gray-300">
                  Next Level
                </p>

                <p className="mb-3 text-sm font-semibold text-white break-all">
                  {gamification.next_level.name} -{" "}
                  {gamification.next_level.points_threshold}PX
                </p>
              </li>
            </ul> */}
          </Stack>
        </Paper>
      </Grid.Col>
      <Grid.Col lg={8} order={1} orderSm={2} orderLg={2}>
        <Paper radius="md" withBorder p="xl" mih={300}>
          <Group align="end" mb={4} noWrap>
            <Title order={4} size="h4" fw={700} className="whitespace-nowrap">
              About Me
            </Title>
            {!isEditMode && (
              <>
                <ActionIcon
                  size={30}
                  variant="default"
                  aria-label="Edit profile"
                  onClick={() => setEditAboutMe(!editAboutMe)}
                >
                  {editAboutMe ? (
                    <IconX style={{ width: rem(24), height: rem(24) }} />
                  ) : (
                    <IconPencil style={{ width: rem(24), height: rem(24) }} />
                  )}
                </ActionIcon>
                {editAboutMe && (
                  <ActionIcon
                    size={30}
                    variant="default"
                    aria-label="Edit profile"
                    onClick={() => {
                      if (onSubmit(form.values, setUser)) setEditAboutMe(false);
                    }}
                  >
                    <IconCheck style={{ width: rem(24), height: rem(24) }} />
                  </ActionIcon>
                )}
              </>
            )}
          </Group>
          {isEditMode || editAboutMe ? (
            <Textarea
              radius="md"
              size="md"
              defaultValue={form.values.about_me}
              onChange={(event) => {
                const updatedValue = event.currentTarget.value;
                form.setFieldValue("about_me", updatedValue.slice(0, 500));
              }}
              error={form.errors.about_me}
              minRows={8}
              maxLength={500}
            />
          ) : (
            <Text className="mt-2 px-2 text-base text-gray-600">
              {user.about_me}
            </Text>
          )}
        </Paper>
      </Grid.Col>
    </Grid>
  );
};

const Skills = ({ setUser, isEditMode, form, user }) => {
  const [editAboutMe, setEditAboutMe] = useState(false);
  const [selectedExperts, setSelectedExperts] = useState(
    form.values.expert_in.map((m) => ({ label: m, value: m }))
  );
  const [selectedSkills, setSelectedSkills] = useState(
    form.values.skills.map((m) => ({ label: m, value: m }))
  );
  const [selectedIndustries, setSelectedIndustries] = useState(
    form.values.industries.map((m) => ({ label: m, value: m }))
  );
  return (
    <Paper radius="md" withBorder p="xl">
      <Stack>
        <div>
          <Group align="end" mb={4}>
            <Title order={4} size="h4" fw={700}>
              Expert In
            </Title>
            {!isEditMode && (
              <>
                <ActionIcon
                  size={30}
                  variant="default"
                  aria-label="Edit profile"
                  onClick={() => setEditAboutMe(!editAboutMe)}
                >
                  {editAboutMe ? (
                    <IconX style={{ width: rem(24), height: rem(24) }} />
                  ) : (
                    <IconPencil style={{ width: rem(24), height: rem(24) }} />
                  )}
                </ActionIcon>
                {editAboutMe && (
                  <ActionIcon
                    size={30}
                    variant="default"
                    aria-label="Edit profile"
                    onClick={() => {
                      if (onSubmit(form.values, setUser)) setEditAboutMe(false);
                    }}
                  >
                    <IconCheck style={{ width: rem(24), height: rem(24) }} />
                  </ActionIcon>
                )}
              </>
            )}
          </Group>
          <Group position="center" my="md">
            {isEditMode || editAboutMe ? (
              <MultiSelect
                isCreatable={true}
                placeholder="Pick value"
                options={skills}
                hasSelectAll={false}
                value={selectedExperts}
                onChange={(e) => {
                  if (e.length > 5) return;
                  setSelectedExperts(e);
                  form.setFieldValue(
                    "expert_in",
                    e.map((m) => m.label)
                  );
                }}
                className="max-w-full lg:w-96"
              />
            ) : (
              <>
                {user.expert_in.map((skill) => (
                  <Chip
                    key={skill}
                    variant="outline"
                    size="sm"
                    radius="md"
                    checked={false}
                  >
                    <Anchor href={`/dashboard/#/members?expert_in=${skill}`}>
                      {skill}
                    </Anchor>
                  </Chip>
                ))}
              </>
            )}
          </Group>
        </div>
        <hr></hr>
        <div>
          <Title order={4} size="h4" fw={700}>
            Industries
          </Title>

          <Group position="center" my="md">
            {isEditMode || editAboutMe ? (
              <MultiSelect
                isCreatable={true}
                placeholder="Pick value"
                options={industries}
                hasSelectAll={false}
                value={selectedIndustries}
                onChange={(e) => {
                  if (e.length > 5) return;
                  setSelectedIndustries(e);
                  form.setFieldValue(
                    "industries",
                    e.map((m) => m.label)
                  );
                }}
                className="max-w-full lg:w-96"
              />
            ) : (
              <>
                {user.industries.map((skill) => (
                  <Chip
                    key={skill}
                    variant="outline"
                    size="sm"
                    radius="md"
                    checked={false}
                  >
                    <Anchor href={`/dashboard/#/members?industries=${skill}`}>
                      {skill}
                    </Anchor>
                  </Chip>
                ))}
              </>
            )}
          </Group>
        </div>
        <hr></hr>
        <div>
          <Title order={4} size="h4" fw={700}>
            Skills
          </Title>
          <Group position="center" my="md">
            {isEditMode || editAboutMe ? (
              <MultiSelect
                isCreatable={true}
                placeholder="Pick value"
                options={skills}
                hasSelectAll={false}
                value={selectedSkills}
                onChange={(e) => {
                  if (e.length > 15) return;
                  setSelectedSkills(e);
                  form.setFieldValue(
                    "skills",
                    e.map((m) => m.label)
                  );
                }}
                className="max-w-full lg:w-96"
              />
            ) : (
              <>
                {user.skills.map((skill) => (
                  <Chip
                    key={skill}
                    variant="outline"
                    size="sm"
                    color="gray"
                    radius="md"
                    checked={false}
                  >
                    <Anchor href={`/dashboard/#/members?skills=${skill}`}>
                      {skill}
                    </Anchor>
                  </Chip>
                ))}
              </>
            )}
          </Group>
        </div>
      </Stack>
    </Paper>
  );
};

const Personal = ({ setUser, isEditMode, form, user }) => {
  const [editAboutMe, setEditAboutMe] = useState(false);
  return (
    <Paper radius="md" withBorder p="xl">
      <Group align="end" mb={4}>
        <Title order={4} size="h4" fw={700}>
          Personal Information
        </Title>
        {!isEditMode && (
          <>
            <ActionIcon
              size={30}
              variant="default"
              aria-label="Edit profile"
              onClick={() => setEditAboutMe(!editAboutMe)}
            >
              {editAboutMe ? (
                <IconX style={{ width: rem(24), height: rem(24) }} />
              ) : (
                <IconPencil style={{ width: rem(24), height: rem(24) }} />
              )}
            </ActionIcon>
            {editAboutMe && (
              <ActionIcon
                size={30}
                variant="default"
                aria-label="Edit profile"
                onClick={() => {
                  if (onSubmit(form.values, setUser)) setEditAboutMe(false);
                }}
              >
                <IconCheck style={{ width: rem(24), height: rem(24) }} />
              </ActionIcon>
            )}
          </>
        )}
      </Group>
      <Stack mt={10}>
        <SimpleGrid
          spacing="lg"
          verticalSpacing="lg"
          breakpoints={[
            { minWidth: "0rem", cols: 1 },
            { minWidth: "50rem", cols: 2 },
          ]}
        >
          <div className="flex flex-col justify-center rounded-2xl bg-white bg-clip-border px-3 py-4 shadow-3xl shadow-shadow-500  ">
            <p className="text-sm text-gray-600">First Name</p>
            {isEditMode || editAboutMe ? (
              <TextInput
                radius="md"
                size="md"
                name="first_name"
                defaultValue={form.values.first_name}
                onChange={(event) =>
                  form.setFieldValue("first_name", event.currentTarget.value)
                }
                error={form.errors.first_name}
                withAsterisk
              />
            ) : (
              <p className="text-base font-medium text-navy-700 ">
                {user.first_name}
              </p>
            )}
          </div>
          <div className="flex flex-col justify-center rounded-2xl bg-white bg-clip-border px-3 py-4 shadow-3xl shadow-shadow-500  ">
            <p className="text-sm text-gray-600">Last Name</p>
            {isEditMode || editAboutMe ? (
              <TextInput
                radius="md"
                size="md"
                name="last_name"
                defaultValue={form.values.last_name}
                onChange={(event) =>
                  form.setFieldValue("last_name", event.currentTarget.value)
                }
                error={form.errors.last_name}
                withAsterisk
              />
            ) : (
              <p className="text-base font-medium text-navy-700 ">
                {user.last_name}
              </p>
            )}
          </div>
        </SimpleGrid>

        <SimpleGrid
          spacing="lg"
          verticalSpacing="lg"
          breakpoints={[
            { minWidth: "0rem", cols: 1 },
            { minWidth: "50rem", cols: 2 },
          ]}
        >
          <div className="flex flex-col justify-center rounded-2xl bg-white bg-clip-border px-3 py-4 shadow-3xl shadow-shadow-500  ">
            <Group position="apart" align="center">
              <p className="text-sm text-gray-600">Public Email</p>
              {isEditMode ||
                (editAboutMe && (
                  <Switch
                    labelPosition="left"
                    label="Show Email Publicly?"
                    checked={form.values.display_email}
                    onChange={(event) =>
                      form.setFieldValue(
                        "display_email",
                        event.currentTarget.checked
                      )
                    }
                  />
                ))}
            </Group>
            {isEditMode || editAboutMe ? (
              <TextInput
                radius="md"
                size="md"
                name="public_email"
                defaultValue={form.values.public_email}
                onChange={(event) =>
                  form.setFieldValue("public_email", event.currentTarget.value)
                }
                error={form.errors.public_email}
                withAsterisk
              />
            ) : (
              <p className="text-base font-medium text-navy-700 ">
                {form.values.public_email}
              </p>
            )}
          </div>
          <div className="flex flex-col justify-center rounded-2xl bg-white bg-clip-border px-3 py-4 shadow-3xl shadow-shadow-500  ">
            <Group position="apart" align="center">
              <p className="text-sm text-gray-600">Phone Number</p>
              {isEditMode ||
                (editAboutMe && (
                  <Switch
                    labelPosition="left"
                    label="Show Number Publicly?"
                    checked={form.values.display_phone}
                    onChange={(event) =>
                      form.setFieldValue(
                        "display_phone",
                        event.currentTarget.checked
                      )
                    }
                  />
                ))}
            </Group>
            {isEditMode || editAboutMe ? (
              <TextInput
                radius="md"
                size="md"
                name="public_phone"
                defaultValue={form.values.public_phone}
                onChange={(event) =>
                  form.setFieldValue("public_phone", event.currentTarget.value)
                }
                error={form.errors.public_phone}
                withAsterisk
              />
            ) : (
              <p className="text-base font-medium text-navy-700 ">
                {form.values.public_phone}
              </p>
            )}
          </div>
        </SimpleGrid>

        <SimpleGrid
          spacing="lg"
          verticalSpacing="lg"
          breakpoints={[
            { minWidth: "0rem", cols: 1 },
            { minWidth: "50rem", cols: 2 },
          ]}
        >
          <div className="flex flex-col justify-center rounded-2xl bg-white bg-clip-border px-3 py-4 shadow-3xl shadow-shadow-500  ">
            <p className="text-sm text-gray-600">Country</p>
            {isEditMode || editAboutMe ? (
              <Select
                withAsterisk
                data={countriesData}
                label="Country"
                value={form.values.country}
                onChange={(event) => form.setFieldValue("country", event)}
                error={form.errors.country}
                radius="md"
                size="md"
                searchable
                nothingFound="No options"
              />
            ) : (
              <p className="text-base font-medium text-navy-700 ">
                {user.address?.country}
              </p>
            )}
          </div>
          <div className="flex flex-col justify-center rounded-2xl bg-white bg-clip-border px-3 py-4 shadow-3xl shadow-shadow-500  ">
            <p className="text-sm text-gray-600">City</p>
            {isEditMode || editAboutMe ? (
              <TextInput
                radius="md"
                size="md"
                name="city"
                defaultValue={form.values.city}
                onChange={(event) =>
                  form.setFieldValue("city", event.currentTarget.value)
                }
                error={form.errors.city}
                withAsterisk
              />
            ) : (
              <p className="text-base font-medium text-navy-700">
                {user.address?.city}
              </p>
            )}
          </div>
        </SimpleGrid>
      </Stack>
    </Paper>
  );
};

const Education = ({ setUser, isEditMode, form, user }) => {
  const [editAboutMe, setEditAboutMe] = useState(false);
  return (
    <Paper radius="md" withBorder p="xl">
      <Group align="end" mb={4}>
        <Title order={4} size="h4" fw={700}>
          Education
        </Title>
        {!isEditMode && (
          <>
            <ActionIcon
              size={30}
              variant="default"
              aria-label="Edit profile"
              onClick={() => setEditAboutMe(!editAboutMe)}
            >
              {editAboutMe ? (
                <IconX style={{ width: rem(24), height: rem(24) }} />
              ) : (
                <IconPencil style={{ width: rem(24), height: rem(24) }} />
              )}
            </ActionIcon>
            {editAboutMe && (
              <ActionIcon
                size={30}
                variant="default"
                aria-label="Edit profile"
                onClick={() => {
                  if (onSubmit(form.values, setUser)) setEditAboutMe(false);
                }}
              >
                <IconCheck style={{ width: rem(24), height: rem(24) }} />
              </ActionIcon>
            )}
          </>
        )}
      </Group>
      <Stack mt={10}>
        <SimpleGrid
          spacing="lg"
          verticalSpacing="lg"
          breakpoints={[
            { minWidth: "0rem", cols: 1 },
            { minWidth: "50rem", cols: 2 },
          ]}
        >
          <div className="flex flex-col justify-center rounded-2xl bg-white bg-clip-border px-3 py-4 shadow-3xl shadow-shadow-500  ">
            <p className="text-sm text-gray-600">University</p>
            {isEditMode || editAboutMe ? (
              <TextInput
                radius="md"
                size="md"
                name="university"
                defaultValue={form.values.university}
                onChange={(event) =>
                  form.setFieldValue("university", event.currentTarget.value)
                }
                error={form.errors.university}
                withAsterisk
              />
            ) : (
              <p className="text-base font-medium text-navy-700 ">
                {user.university}
              </p>
            )}
          </div>
          <div className="flex flex-col justify-center rounded-2xl bg-white bg-clip-border px-3 py-4 shadow-3xl shadow-shadow-500  ">
            <p className="text-sm text-gray-600">Degree</p>
            {isEditMode || editAboutMe ? (
              <TextInput
                radius="md"
                size="md"
                name="degree"
                defaultValue={form.values.degree}
                onChange={(event) =>
                  form.setFieldValue("degree", event.currentTarget.value)
                }
                error={form.errors.degree}
                withAsterisk
              />
            ) : (
              <p className="text-base font-medium text-navy-700 ">
                {user.degree}
              </p>
            )}
          </div>
        </SimpleGrid>
      </Stack>
    </Paper>
  );
};

const Socials = ({ setUser, isEditMode, form, user }) => {
  const [editAboutMe, setEditAboutMe] = useState(false);
  return (
    <Paper radius="md" withBorder p="xl">
      <Group align="end" mb={4}>
        <Title order={4} size="h4" fw={700}>
          Social Links
        </Title>
        {!isEditMode && (
          <>
            <ActionIcon
              size={30}
              variant="default"
              aria-label="Edit profile"
              onClick={() => setEditAboutMe(!editAboutMe)}
            >
              {editAboutMe ? (
                <IconX style={{ width: rem(24), height: rem(24) }} />
              ) : (
                <IconPencil style={{ width: rem(24), height: rem(24) }} />
              )}
            </ActionIcon>
            {editAboutMe && (
              <ActionIcon
                size={30}
                variant="default"
                aria-label="Edit profile"
                onClick={() => {
                  if (onSubmit(form.values, setUser)) setEditAboutMe(false);
                }}
              >
                <IconCheck style={{ width: rem(24), height: rem(24) }} />
              </ActionIcon>
            )}
          </>
        )}
      </Group>
      <Stack mt={10}>
        <SimpleGrid
          spacing="lg"
          verticalSpacing="lg"
          breakpoints={[
            { minWidth: "0rem", cols: 1 },
            { minWidth: "50rem", cols: 2 },
            { minWidth: "90rem", cols: 3 },
          ]}
        >
          <div className="flex flex-col justify-center rounded-2xl bg-white bg-clip-border px-3 py-4 shadow-3xl shadow-shadow-500  ">
            {isEditMode || editAboutMe ? (
              <TextInput
                radius="md"
                size="md"
                name="linkedin"
                description="linkedin"
                defaultValue={form.values.linkedin}
                onChange={(event) =>
                  form.setFieldValue("linkedin", event.currentTarget.value)
                }
                error={form.errors.linkedin}
                withAsterisk
              />
            ) : (
              <Button
                component="a"
                href={`https://${user.linkedin}`}
                variant="subtle"
                leftIcon={<IconExternalLink size="0.9rem" />}
              >
                LinkedIn
              </Button>
            )}
          </div>
          <div className="flex flex-col justify-center rounded-2xl bg-white bg-clip-border px-3 py-4 shadow-3xl shadow-shadow-500  ">
            {isEditMode || editAboutMe ? (
              <TextInput
                radius="md"
                size="md"
                name="github"
                description="github"
                defaultValue={form.values.github}
                onChange={(event) =>
                  form.setFieldValue("github", event.currentTarget.value)
                }
                error={form.errors.github}
                withAsterisk
              />
            ) : (
              <Button
                component="a"
                href={`https://${user.github}`}
                variant="subtle"
                leftIcon={<IconExternalLink size="0.9rem" />}
              >
                Github
              </Button>
            )}
          </div>
          <div className="flex flex-col justify-center rounded-2xl bg-white bg-clip-border px-3 py-4 shadow-3xl shadow-shadow-500  ">
            {isEditMode || editAboutMe ? (
              <TextInput
                radius="md"
                size="md"
                name="twitter"
                description="twitter"
                defaultValue={form.values.twitter}
                onChange={(event) =>
                  form.setFieldValue("twitter", event.currentTarget.value)
                }
                error={form.errors.twitter}
                withAsterisk
              />
            ) : (
              <Button
                component="a"
                href={`https://${user.twitter}`}
                variant="subtle"
                leftIcon={<IconExternalLink size="0.9rem" />}
              >
                Twitter
              </Button>
            )}
          </div>
        </SimpleGrid>
      </Stack>
    </Paper>
  );
};

const Job = ({ setUser, isEditMode, form, user }) => {
  const [opened, { close, open }] = useDisclosure(false);
  const [editAboutMe, setEditAboutMe] = useState(false);
  return (
    <>
      {[1, 0].includes(user.user_type) ? (
        <Paper radius="md" withBorder p="xl">
          <Group position="apart">
            <Group align="end" mb={4}>
              <Title order={4} size="h4" fw={700}>
                Job Details
              </Title>
              {!isEditMode && (
                <>
                  <ActionIcon
                    size={30}
                    variant="default"
                    aria-label="Edit profile"
                    onClick={() => setEditAboutMe(!editAboutMe)}
                  >
                    {editAboutMe ? (
                      <IconX style={{ width: rem(24), height: rem(24) }} />
                    ) : (
                      <IconPencil style={{ width: rem(24), height: rem(24) }} />
                    )}
                  </ActionIcon>
                  {editAboutMe && (
                    <ActionIcon
                      size={30}
                      variant="default"
                      aria-label="Edit profile"
                      onClick={() => {
                        if (onSubmit(form.values, setUser))
                          setEditAboutMe(false);
                      }}
                    >
                      <IconCheck style={{ width: rem(24), height: rem(24) }} />
                    </ActionIcon>
                  )}
                </>
              )}
            </Group>
            <ActionIcon
              size={42}
              variant="default"
              aria-label="ActionIcon with size as a number"
            >
              <IconTrash style={{ width: rem(24), height: rem(24) }} />
            </ActionIcon>
          </Group>

          <Stack mt={10}>
            <SimpleGrid
              spacing="lg"
              verticalSpacing="lg"
              breakpoints={[
                { minWidth: "0rem", cols: 1 },
                { minWidth: "50rem", cols: 2 },
              ]}
            >
              <div className="flex flex-col justify-center rounded-2xl bg-white bg-clip-border px-3 py-4 shadow-3xl shadow-shadow-500  ">
                <p className="text-sm text-gray-600">Company</p>
                {isEditMode || editAboutMe ? (
                  <TextInput
                    radius="md"
                    size="md"
                    name="company"
                    defaultValue={form.values.company}
                    onChange={(event) =>
                      form.setFieldValue("company", event.currentTarget.value)
                    }
                    error={form.errors.company}
                    withAsterisk
                  />
                ) : (
                  <p className="text-base font-medium text-navy-700 ">
                    {user.company}
                  </p>
                )}
              </div>
              <div className="flex flex-col justify-center rounded-2xl bg-white bg-clip-border px-3 py-4 shadow-3xl shadow-shadow-500  ">
                <p className="text-sm text-gray-600">Job Title</p>
                {isEditMode || editAboutMe ? (
                  <TextInput
                    radius="md"
                    size="md"
                    name="job_title"
                    defaultValue={form.values.job_title}
                    onChange={(event) =>
                      form.setFieldValue("job_title", event.currentTarget.value)
                    }
                    error={form.errors.job_title}
                    withAsterisk
                  />
                ) : (
                  <p className="text-base font-medium text-navy-700 ">
                    {user.job_title}
                  </p>
                )}
              </div>
            </SimpleGrid>
            <SimpleGrid
              spacing="lg"
              verticalSpacing="lg"
              breakpoints={[
                { minWidth: "0rem", cols: 1 },
                { minWidth: "50rem", cols: 2 },
              ]}
            >
              <div className="flex flex-col justify-center rounded-2xl bg-white bg-clip-border px-3 py-4 shadow-3xl shadow-shadow-500  ">
                <p className="text-sm text-gray-600">Type (Remote/Onsite)</p>
                {isEditMode || editAboutMe ? (
                  <TextInput
                    radius="md"
                    size="md"
                    name="work_type"
                    defaultValue={form.values.work_type}
                    onChange={(event) =>
                      form.setFieldValue("work_type", event.currentTarget.value)
                    }
                    error={form.errors.work_type}
                    withAsterisk
                  />
                ) : (
                  <p className="text-base font-medium text-navy-700 ">
                    {user.work_type}
                  </p>
                )}
              </div>
              <div className="flex flex-col justify-center rounded-2xl bg-white bg-clip-border px-3 py-4 shadow-3xl shadow-shadow-500  ">
                <p className="text-sm text-gray-600">Years Of Experience</p>
                {isEditMode || editAboutMe ? (
                  <TextInput
                    radius="md"
                    size="md"
                    name="year_of_experience"
                    defaultValue={form.values.year_of_experience}
                    onChange={(event) =>
                      form.setFieldValue(
                        "year_of_experience",
                        event.currentTarget.value
                      )
                    }
                    error={form.errors.year_of_experience}
                    withAsterisk
                  />
                ) : (
                  <p className="text-base font-medium text-navy-700">
                    {user.year_of_experience}
                  </p>
                )}
              </div>
            </SimpleGrid>
          </Stack>
        </Paper>
      ) : (
        <>
          <Button
            variant="default"
            size="lg"
            fw={500}
            onClick={() => {
              form.setFieldValue("user_type", 0);
              open();
            }}
          >
            Do you also have a job?
          </Button>
          <Modal opened={opened} onClose={close} size="xl" title="Job Details">
            <Group spacing={"md"} grow className="m-3" align="start">
              <TextInput
                label="Company"
                radius="md"
                size="md"
                name="first_name"
                defaultValue={form.values.company}
                onChange={(event) =>
                  form.setFieldValue("company", event.currentTarget.value)
                }
                error={form.errors.company}
                withAsterisk
              />
              <TextInput
                label="Job Title"
                radius="md"
                size="md"
                name="last_name"
                defaultValue={form.values.job_title}
                onChange={(event) =>
                  form.setFieldValue("job_title", event.currentTarget.value)
                }
                error={form.errors.job_title}
                withAsterisk
              />
            </Group>
            <Group spacing={"md"} grow className="m-3" align="start">
              <TextInput
                label="Years Of Experience"
                radius="md"
                size="md"
                name="year_of_experience"
                defaultValue={form.values.year_of_experience}
                onChange={(event) =>
                  form.setFieldValue(
                    "year_of_experience",
                    event.currentTarget.value
                  )
                }
                error={form.errors.year_of_experience}
                withAsterisk
              />
              <Select
                label="Work Type"
                radius="md"
                size="md"
                name="work_type"
                data={["Remote", "Onsite"]}
                defaultValue={form.values.work_type}
                onChange={(event) => form.setFieldValue("work_type", event)}
                error={form.errors.work_type}
                withAsterisk
              />
            </Group>
            <Button
              variant="default"
              fullWidth
              size="md"
              mb={10}
              fw={500}
              onClick={() => {
                form.setFieldValue("user_type", 0);
                if (onSubmit(form.values, setUser)) close();
              }}
            >
              Add
            </Button>
          </Modal>
        </>
      )}
    </>
  );
};

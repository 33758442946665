import React from "react";
import styled from "styled-components";
import CustomNavLink from "../components/Lib/CustomNavLink"; // Ensure correct import path

const NavLinksContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const NavLinksWrapper = styled.nav`
  margin-bottom: 20px;
`;

const StyledNavLink = styled(CustomNavLink)`
  padding: 10px 15px;
  text-decoration: none;
  color: #000;
  border-radius: 6px;
  font-size: 1rem;
  display: block;

  &.active {
    background-color: var(--color-container-card-default);
    color: var(--color-button-accent);
    font-weight: var(--font-weight-body-2);
  }

  &:hover {
    background-color: var(--color-container-card-default);
  }
`;

const InnerNavLink = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  position: relative;
  transition: background-color 0.3s, color 0.3s;

  &.active::before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    width: 3px;
    background-color: var(--color-button-accent);
  }
`;

const NavIcon = styled.img`
  width: 24px;
  height: 24px;
  margin-right: 12px;
  transition: filter 0.3s;

  ${StyledNavLink}.active & {
    filter: none;
  }

  & {
    filter: brightness(0) grayscale(0);
  }
`;

const NavDivider = styled.div`
  border-top: 1px solid #dce5e4;
  margin: 10px 0;
`;

const NavLinks = ({ links: { links, active, icons } }) => {
  return (
    <NavLinksContainer>
      <NavLinksWrapper>
        {links.map((link, index) => (
          <React.Fragment key={index}>
            <StyledNavLink
              to={link.href} // Pass the `href` to CustomNavLink
              className={link.name.includes(active) ? "active" : ""}
            >
              <InnerNavLink
                className={link.name.includes(active) ? "active" : ""}
              >
                {icons ? (
                  <link.icon />
                ) : (
                  <NavIcon src={link.icon} alt={`${link.name} icon`} />
                )}
                <span>{link.name}</span>
              </InnerNavLink>
            </StyledNavLink>
            {index === 3 && <NavDivider />}
          </React.Fragment>
        ))}
      </NavLinksWrapper>
    </NavLinksContainer>
  );
};

export default NavLinks;

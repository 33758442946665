import React, { useState, useEffect, useRef, useCallback } from "react";
import debounce from "lodash.debounce";
import styled from "styled-components";
import EditorJS from "@editorjs/editorjs";
import LinkTool from "@editorjs/link";
import CodeTool from "@editorjs/code";
import Header from "@editorjs/header";
import List from "@editorjs/list";
import Paragraph from "@editorjs/paragraph";
import Marker from "@editorjs/marker";
import Table from '@editorjs/table'
import useStartupStore from "../stores/startupStore";
import ChatDocumentUpload from "./ChatDocumentUpload";

import GuideEditor from "./GuideEditor";

const AlignmentTuneTool = require("editorjs-text-alignment-blocktune");

// Styled components for layout
const Container = styled.div`
  display: flex;
  background-color: #222;
  height: 100%;
  position: relative;
  min-width: 100%;
  min-height: 350px;
  overflow: hidden;
`;

const TextAreaContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  font-size: 1.2rem;
  padding: 4px;
  position: relative;
  border-radius: 6px;
  height: 100%;
`;

const EditorContainer = styled.div`
  width: 100%;
  height: 380px;
  background-color: var(--color-background-light);
  padding: 10px;
  padding-left: 24px;
  border-radius: 4px;
  font-size: 12px;
  overflow-y: auto;
`;

const VisibilityOptions = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 15px;
  position: absolute;
  bottom: 10px;
  left: 5%;
  z-index: 100;
`;

const VisibilityLabel = styled.label`
  display: flex;
  align-items: center;
  gap: 5px;
  font-size: var(--font-size-body-1);
  color: var(--color-navigation-icon-dark);
`;

const VisibilityText = styled.p`
  color: var(--color-navigation-icon-dark);
  font-size: var(--font-size-h5);
`;

const FlexColumnContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
`;


const TextCreationArea = () => {
  const editorInstance = useRef(null); // Ref for storing the EditorJS instance
  const editorHolderRef = useRef(null); // Ref for the EditorJS DOM element
  const guideEditorRef = useRef(null);

  const {
    content,
    setContent,
    privacy,
    setPrivacy,
    selectedProgram,
    setEditorState,
  } = useStartupStore((state) => ({
    content: state.content,
    setContent: state.setContent,
    privacy: state.privacy,
    setPrivacy: state.setPrivacy,
    selectedProgram: state.selectedProgram,
    setEditorState: state.setEditorState,
  }));

  const pageId = selectedProgram.cid; // Current pageId
  const index = selectedProgram.sid; // Current tab index

  // Refs to store previous and current pageId and index
  const prevPageId = useRef(pageId);
  const prevIndex = useRef(index);
  const currentPageId = useRef(pageId);
  const currentIndex = useRef(index);

  // Update refs when pageId or index changes
  useEffect(() => {
    currentPageId.current = pageId;
    currentIndex.current = index;
  }, [pageId, index]);

  // Debounced save function
  const saveEditorContent = useCallback(
    debounce(async () => {
      if (editorInstance.current) {
        const savedContent = await editorInstance.current.save();
        setContent(currentPageId.current, currentIndex.current, savedContent);
      }
    }, 1000),
    [setContent]
  );

  console.log(content[pageId]?.[index])

  const getCsrfToken = () => {
    const metaTag = document.querySelector("meta[name='csrf-token']");
    return metaTag ? metaTag.content : "";
  };

  // Initialize the EditorJS instance
  const initializeEditor = () => {
    if (editorInstance.current || !editorHolderRef.current) return;

    const csrfToken = getCsrfToken();

    editorInstance.current = new EditorJS({
      holder: editorHolderRef.current,
      placeholder: "Start typing your content here...",
      data: content[pageId]?.[index]?.response || { blocks: [] },
      tools: {
        header: {
          class: Header,
          tunes: ["anyTuneName"],
        },
        table: {
          class: Table,
          inlineToolbar: true,
          config: {
            rows: 2,
            cols: 3,
            withHeadings: true,
          },
        },
        Marker: {
          class: Marker,
          shortcut: "CMD+SHIFT+M",
        },
        linkTool: {
          class: LinkTool,
          config: {
            endpoint: "/api/fetch_url",
            headers: {
              "X-CSRF-Token": csrfToken,
            },
          },
        },
        list: {
          class: List,
        },
        paragraph: {
          class: Paragraph,
          tunes: ["anyTuneName"],
          config: {
            inlineToolbar: true,
          },
        },
        anyTuneName: {
          class: AlignmentTuneTool,
          config: {
            default: "left",
            blocks: {
              header: "center",
              list: "right",
            },
          },
        },
        code: {
          class: CodeTool,
        },
      },
      readOnly:
        content[pageId]?.[index]?.wait &&
        content[pageId]?.[index]?.diff_proposed_response,
      async onChange() {
        saveEditorContent();
      },
    });
  };

  // Initialize editor on mount
  useEffect(() => {
    // Initialize editor state if not already initialized
    if (!content[pageId]) {
      setEditorState();
    }

    initializeEditor();

    // Cleanup on unmount
    return () => {
      if (editorInstance.current) {
        editorInstance.current.destroy();
        editorInstance.current = null;
      }
    };
  }, []);

  // Update editor when pageId or index changes
  useEffect(() => {
    const handleContentChange = async () => {
      if (!editorInstance.current) return;

      // Save current content using previous pageId and index
      const savedContent = await editorInstance.current.save();
      setContent(prevPageId.current, prevIndex.current, savedContent);

      // Clear and load new content
      await editorInstance.current.clear();
      const newContent = content[pageId]?.[index]?.response || { blocks: [] };
      await editorInstance.current.render(newContent);

      // Update previous pageId and index
      prevPageId.current = pageId;
      prevIndex.current = index;
    };

    if (pageId !== prevPageId.current || index !== prevIndex.current) {
      handleContentChange();
    }
  }, [pageId, index]);

  // Access current privacy setting
  const currentPrivacy = privacy[pageId]?.[index] || "Everyone";

  return (
    <FlexColumnContainer>
    <Container>
      {/* Main Text Creation Area */}
      <TextAreaContainer>
        {/* EditorJS container */}
        <EditorContainer>
          {/* Editor holder */}
          <div ref={editorHolderRef} />
        </EditorContainer>
        {/* Visibility Options */}
        <VisibilityOptions>
          <VisibilityText>Visible to</VisibilityText>
          <VisibilityLabel>
            <input
              type="radio"
              checked={currentPrivacy === "Everyone"}
              onChange={() => setPrivacy(pageId, index, "Everyone")}
              name={`visibility-${pageId}-${index}`}
              style={{ width: "unset" }}
            />
            Everyone
          </VisibilityLabel>
          <VisibilityLabel>
            <input
              type="radio"
              checked={currentPrivacy === "Members"}
              onChange={() => setPrivacy(pageId, index, "Members")}
              name={`visibility-${pageId}-${index}`}
              style={{ width: "unset" }}
            />
            Only members
          </VisibilityLabel>
          <VisibilityLabel>
            <input
              type="radio"
              checked={currentPrivacy === "Only me"}
              onChange={() => setPrivacy(pageId, index, "Only me")}
              name={`visibility-${pageId}-${index}`}
              style={{ width: "unset" }}
            />
            Only me
          </VisibilityLabel>
        </VisibilityOptions>
      </TextAreaContainer>

      {/* Suggestion Sidebar */}
      <GuideEditor
        editorRef={guideEditorRef}
      />
    </Container>
    <ChatDocumentUpload
    name={content[pageId]?.[index]?.name}
    close={() => setEditMode(false)}
  />
</FlexColumnContainer>
  );
};

export default TextCreationArea;

import { useState, useEffect } from "react";

const useGoogleDrive = ({ clientId, apiKey, appId }) => {
  const [googleAccessToken, setGoogleAccessToken] = useState(null);
  const [isGoogleAuthenticated, setIsGoogleAuthenticated] = useState(false);
  const [pickerInited, setPickerInited] = useState(false);
  const [tokenClient, setTokenClient] = useState(null);

  useEffect(() => {
    const loadGoogleAPIs = () => {
      const gapiScript = document.createElement("script");
      gapiScript.src = "https://apis.google.com/js/api.js";
      gapiScript.onload = initPicker;
      document.body.appendChild(gapiScript);

      const gisScript = document.createElement("script");
      gisScript.src = "https://accounts.google.com/gsi/client";
      gisScript.onload = initTokenClient;
      document.body.appendChild(gisScript);
    };

    loadGoogleAPIs();

    const storedToken = localStorage.getItem("accessToken");
    const tokenExpiry = localStorage.getItem("tokenExpiry");
    if (storedToken && new Date().getTime() < tokenExpiry) {
      setGoogleAccessToken(storedToken);
      setIsGoogleAuthenticated(true);
    }

    return () => {
      const googleScript = document.querySelector(
        'script[src="https://apis.google.com/js/api.js"]'
      );
      const gsiScript = document.querySelector(
        'script[src="https://accounts.google.com/gsi/client"]'
      );
      if (googleScript) document.body.removeChild(googleScript);
      if (gsiScript) document.body.removeChild(gsiScript);
    };
  }, []);

  const initPicker = () => {
    window.gapi.load("picker", () => setPickerInited(true));
  };

  const initTokenClient = () => {
    const client = window.google.accounts.oauth2.initTokenClient({
      client_id: clientId,
      scope: "https://www.googleapis.com/auth/drive.file",
      prompt: "consent", // Always prompt the user for consent
      callback: (response) => {
        if (response.error) {
          console.error("Error obtaining OAuth token:", response);
          return;
        }
        const expiresIn = response.expires_in * 1000;
        const expiryTime = new Date().getTime() + expiresIn;

        localStorage.setItem("accessToken", response.access_token);
        localStorage.setItem("tokenExpiry", expiryTime);

        setGoogleAccessToken(response.access_token);
        setIsGoogleAuthenticated(true);
      },
    });
    setTokenClient(client);
  };

  const silentAuthenticate = () => {
    if (!tokenClient) {
      console.error("Token client not initialized");
      return;
    }
    tokenClient.requestAccessToken();
  };

  const createPicker = (pickerCallback) => {
    if (!pickerInited || !googleAccessToken) {
      console.error("Picker API or token not available");
      return;
    }

    const picker = new window.google.picker.PickerBuilder()
      .addView(window.google.picker.ViewId.DOCS)
      .setOAuthToken(googleAccessToken)
      .setDeveloperKey(apiKey)
      .setAppId(appId)
      .setCallback(pickerCallback)
      .build();

    picker.setVisible(true);
  };


  const uploadToGoogleDrive = async (files) => {
    if (!googleAccessToken) {
      alert("Please authenticate with Google Drive");
      return [];
    }
  
    const uploadedFiles = [];
    console.log(files);
  
    for (const file of files) {
      const metadata = {
        name: file.name,
        mimeType: file.mimeType,
      };
  
      const formData = new FormData();
      formData.append(
        "metadata",
        new Blob([JSON.stringify(metadata)], { type: "application/json" })
      );
      formData.append("file", file.file);
  
      try {
        // Upload the file
        const response = await fetch(
          "https://www.googleapis.com/upload/drive/v3/files?uploadType=multipart&fields=id,name,mimeType,size",
          {
            method: "POST",
            headers: new Headers({
              Authorization: `Bearer ${googleAccessToken}`,
            }),
            body: formData,
          }
        );
  
        const data = await response.json();
        console.log("File uploaded:", data);
  
        // Get the file metadata
        const metadataResponse = await fetch(
          `https://www.googleapis.com/drive/v3/files/${data.id}?fields=thumbnailLink,webViewLink,iconLink`,
          {
            method: "GET",
            headers: new Headers({
              Authorization: `Bearer ${googleAccessToken}`,
            }),
          }
        );
  
        const metadataDetails = await metadataResponse.json();
        console.log("File metadata:", metadataDetails);
  
        // Save the uploaded file details, including thumbnail and preview links
        const uploadedFile = {
          id: data.id,
          name: data.name,
          size_bytes: data.size || 0,
          icon: data.iconLink || `https://drive-thirdparty.googleusercontent.com/16/type/${data.mimeType}`, 
          url: `https://drive.google.com/file/d/${data.id}/preview`,  
          thumbnail: metadataDetails.thumbnailLink || `https://drive.google.com/thumbnail?authuser=0&sz=w320&id=${data.id}`,  
          preview: metadataDetails.webViewLink || `https://drive.google.com/file/d/${data.id}/preview`,  
          source: "drive",
        };
  
        uploadedFiles.push(uploadedFile);
      } catch (error) {
        console.error("Error uploading file:", error);
      }
    }
  
    return uploadedFiles;
  };
  

  return {
    isGoogleAuthenticated,
    createPicker,
    silentAuthenticate, 
    uploadToGoogleDrive,
  };
};

export default useGoogleDrive;

import React, { useEffect, useRef, useCallback, useState } from "react";
import EditorJS from "@editorjs/editorjs";
import Header from "@editorjs/header";
import List from "@editorjs/list";
import Paragraph from "@editorjs/paragraph";
import Marker from "@editorjs/marker";
import LinkTool from "@editorjs/link";
import CodeTool from "@editorjs/code";
import AlignmentTuneTool from "editorjs-text-alignment-blocktune";
import debounce from "lodash.debounce";
import styled from "styled-components";
import useStartupStore from "../stores/startupStore";
import { ActionIcon, rem } from "@mantine/core";
import { IconCheck, IconX } from "@tabler/icons-react";
import { IconPencil } from "@tabler/icons-react";
import fetchApi from "../components/Lib/api";

const SuggestionSidebar = styled.div`
  display: flex;
  flex-direction: column;
  padding: 4px;
  transition: width 0.3s ease;
  justify-content: stretch;
  width: ${(props) => (props.collapsed === "true" ? "25px" : "30%")};
  overflow: hidden;
  max-height: 100%;
`;

const SidebarHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 1rem;
  margin-bottom: 8px;
`;

const SidebarText = styled.p`
  font-size: var(--font-size-h5);
  color: var(--color-navigation-icon-dark);
  white-space: nowrap;
  overflow: hidden;
`;

const CollapseButton = styled.button`
  background: none;
  border: none;
  color: white;
  cursor: pointer;
  font-size: var(--font-size-body-5);
  white-space: nowrap;
`;

const Arrow = styled.span`
  margin-left: 5px;
  display: inline;
`;

const Suggestions = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  background-color: var(--color-background-main);
  padding: 12px;
  height: 100%;
  overflow-y: auto;
  max-height: 350px;
  color: white;
`;

const SuggestionItem = styled.div`
  color: white !important;
  font-size: 1rem;

  p {
    color: white !important;
  }
`;

const GuideEditor = ({ editorRef }) => {
  const [isSidebarCollapsed, setIsSidebarCollapsed] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const localEditorInstance = useRef(null);
  const editorHolderRef = useRef(null);

  const { content, setContent, selectedProgram, setProgram, program, isOwner } = useStartupStore(
    (state) => ({
      content: state.content,
      setContent: state.setContent,
      selectedProgram: state.selectedProgram,
      setProgram: state.setProgram,
      program: state.program,
      isOwner: state.isOwner
    })
  );

  const pageId = selectedProgram.cid;
  const index = selectedProgram.sid;

  const currentPageId = useRef(pageId);
  const currentIndex = useRef(index);

  // Update refs when pageId or index changes
  useEffect(() => {
    currentPageId.current = pageId;
    currentIndex.current = index;
  }, [pageId, index]);

  const getCsrfToken = () => {
    const metaTag = document.querySelector("meta[name='csrf-token']");
    return metaTag ? metaTag.content : "";
  };

  // Debounced save function
  const saveGuideContent = useCallback(
    debounce(async () => {
      if (localEditorInstance.current) {
        const savedContent = await localEditorInstance.current.save();
        setContent(
          currentPageId.current,
          currentIndex.current,
          false,
          savedContent
        );
        // setGuide(savedContent)
      }
    }, 1000),
    [setContent]
  );

  // Initialize the EditorJS instance
  const initializeGuideEditor = () => {
    if (localEditorInstance.current || !editorHolderRef.current) return;

    const csrfToken = getCsrfToken();

    localEditorInstance.current = new EditorJS({
      holder: editorHolderRef.current,
      placeholder: "Write your guide content here...",
      data: content[pageId]?.[index]?.guide || { blocks: [] },
      readOnly: !editMode,
      tools: {
        header: {
          class: Header,
          tunes: ["anyTuneName"],
        },
        Marker: {
          class: Marker,
          shortcut: "CMD+SHIFT+M",
        },
        linkTool: {
          class: LinkTool,
          config: {
            endpoint: "/api/fetch_url",
            headers: {
              "X-CSRF-Token": csrfToken,
            },
          },
        },
        list: {
          class: List,
        },
        paragraph: {
          class: Paragraph,
          tunes: ["anyTuneName"],
          config: {
            inlineToolbar: true,
          },
        },
        anyTuneName: {
          class: AlignmentTuneTool,
          config: {
            default: "left",
            blocks: {
              header: "center",
              list: "right",
            },
          },
        },
        code: {
          class: CodeTool,
        },
      },
      async onChange() {
        saveGuideContent();
      },
    });

    // Attach editor instance to ref if passed
    if (editorRef) {
      editorRef.current = localEditorInstance.current;
    }
  };

  // Destroy previous instance before creating a new one
  const destroyPreviousEditor = () => {
    if (localEditorInstance.current) {
      localEditorInstance.current.destroy();
      localEditorInstance.current = null;
    }
  };

  const handleUpdate = async () => {
    try {
      const guideContent = JSON.stringify(content[pageId]?.[index]?.guide);

      const response = await fetchApi(
        `/sub_categories/${content[pageId]?.[index]?.id}/update_guide`,
        "PATCH",
        {
          sub_category: {
            guide: guideContent, // Stringify only for the API call
          },
        }
      );

      if (response.ok) {
        const updatedData = await response.json();
        setEditMode(false);

        setProgram({
          categories: program.categories.map((category, catIndex) =>
            catIndex === pageId
              ? {
                  ...category,
                  sub_categories: category.sub_categories.map(
                    (subCategory, subIndex) =>
                      subIndex === index
                        ? {
                            ...subCategory,
                            guide: guideContent, // Keep as object internally
                          }
                        : subCategory
                  ),
                }
              : category
          ),
        });
      } else {
        const { error } = await response.json();
        console.error(error); // Handle error as needed
      }
    } catch (error) {
      console.error("Error during updating guide:", error);
    }
  };

  // Update editor when pageId or index changes
  useEffect(() => {
    const handleContentChange = async () => {
      destroyPreviousEditor();
      initializeGuideEditor();
    };

    handleContentChange();
  }, [pageId, index, editMode]);

  const toggleSidebar = () => {
    setIsSidebarCollapsed(!isSidebarCollapsed);
  };

  return (
    <SuggestionSidebar collapsed={isSidebarCollapsed + ""}>
      <SidebarHeader>
        {!isSidebarCollapsed && (
          <div className="flex gap-4 items-center">
            {" "}
            <SidebarText>Suggestions to follow</SidebarText>
            {isOwner && <>
              {editMode ? (
                <>
                  <ActionIcon
                    component="button"
                    href={`#`}
                    size={20}
                    variant="default"
                    aria-label="Edit guide"
                    onClick={() => setEditMode(false)}
                  >
                    <IconX
                      style={{ width: rem(18), height: rem(18) }}
                      stroke={2.5}
                    />
                  </ActionIcon>
                  <ActionIcon
                    component="button"
                    href={`#`}
                    size={20}
                    variant="default"
                    aria-label="Edit guide"
                    onClick={handleUpdate}
                  >
                    <IconCheck
                      style={{ width: rem(18), height: rem(18) }}
                      stroke={2.5}
                    />
                  </ActionIcon>
                </>
              ) : (
                <ActionIcon
                  component="button"
                  size={20}
                  variant="default"
                  aria-label="Edit guide"
                  onClick={() => setEditMode(true)}
                >
                  <IconPencil
                    style={{ width: rem(18), height: rem(18) }}
                    stroke={2.5}
                  />
                </ActionIcon>
              )}
            </>}
          </div>
        )}
        <CollapseButton onClick={toggleSidebar}>
          <Arrow>{isSidebarCollapsed ? "◀" : "▶"}</Arrow>
        </CollapseButton>
      </SidebarHeader>
      <Suggestions>
        <div>
          <div ref={editorHolderRef} />
        </div>
      </Suggestions>
    </SuggestionSidebar>
  );
};

export default GuideEditor;

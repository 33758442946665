import React, { useState, useRef, useEffect } from 'react';

const TagsInput = ({
  value = [],
  onChange,
  placeholder = 'Type and press Enter',
  maxTags = Infinity,
  allowDuplicates = false,
  splitChars = [','],
  disabled = false,
  readOnly = false,
  onRemove,
  onClear,
  className = '',
}) => {
  const [inputValue, setInputValue] = useState('');
  const inputRef = useRef(null);

  const addTag = (tag) => {
    const trimmedTag = tag.trim();
    if (trimmedTag.length === 0) return;

    const isDuplicate = value.some(
      (existingTag) => existingTag.toLowerCase() === trimmedTag.toLowerCase()
    );

    if ((!isDuplicate || allowDuplicates) && value.length < maxTags) {
      onChange([...value, trimmedTag]);
      setInputValue('');
    }
  };

  const handleKeyDown = (event) => {
    const trimmedInput = inputValue.trim();

    // Handle tag creation with Enter
    if (event.key === 'Enter' && trimmedInput.length > 0) {
      event.preventDefault();
      addTag(trimmedInput);
      return;
    }

    // Handle split characters
    if (splitChars.includes(event.key) && trimmedInput.length > 0) {
      event.preventDefault();
      addTag(trimmedInput);
      return;
    }

    // Handle backspace to remove last tag
    if (event.key === 'Backspace' && inputValue.length === 0 && value.length > 0) {
      event.preventDefault();
      const newTags = value.slice(0, -1);
      onChange(newTags);
      onRemove?.(value[value.length - 1]);
    }
  };

  const handlePaste = (event) => {
    event.preventDefault();
    const pastedText = event.clipboardData.getData('text/plain');
    
    const newTags = pastedText
      .split(new RegExp(`[${splitChars.join('')}]`))
      .map((tag) => tag.trim())
      .filter((tag) => tag.length > 0)
      .filter((tag) => allowDuplicates || !value.includes(tag));

    const tagsToAdd = newTags.slice(0, maxTags - value.length);
    if (tagsToAdd.length > 0) {
      onChange([...value, ...tagsToAdd]);
      setInputValue('');
    }
  };

  const removeTag = (indexToRemove) => {
    const newTags = value.filter((_, index) => index !== indexToRemove);
    onChange(newTags);
    onRemove?.(value[indexToRemove]);
  };

  const handleClear = () => {
    onChange([]);
    setInputValue('');
    onClear?.();
    inputRef.current?.focus();
  };

  // Handle form submission prevention
  const handleSubmit = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
    }
  };

  return (
    <div className="w-full">
      <div
        className={`flex flex-wrap gap-2 p-2 py-3 border border-gray-400 rounded-md min-h-10 max-h-40 items-start overflow-y-auto ${
          disabled ? 'bg-gray-100' : 'bg-white'
        } ${className}`}
      >
        <div className="flex flex-wrap gap-2 items-center w-full">
          {value.map((tag, index) => (
            <span
              key={`${tag}-${index}`}
              className="inline-flex items-center gap-1 bg-gray-100 px-2 py-1 rounded-md text-[11px] break-all"
            >
              <span className="max-w-xs truncate">{tag}</span>
              {!readOnly && !disabled && (
                <button
                  type="button"
                  onClick={() => removeTag(index)}
                  className="text-gray-500 hover:text-gray-700 focus:outline-none ml-1 inline"
                  onKeyDown={(e) => e.key === 'Enter' && removeTag(index)}
                >
                  ×
                </button>
              )}
            </span>
          ))}
          
          {!readOnly && !disabled && (
            <input
              ref={inputRef}
              type="text"
              value={inputValue}
              onChange={(e) => setInputValue(e.target.value)}
              onKeyDown={handleKeyDown}
              onKeyPress={handleSubmit}
              onPaste={handlePaste}
              placeholder={value.length === 0 ? placeholder : ''}
              className="flex-1 min-w-20 outline-none border-none bg-transparent text-[12px] px-3"
            />
          )}
        </div>
        
        {!readOnly && !disabled && value.length > 0 && (
          <button
            type="button"
            onClick={handleClear}
            className="sticky top-2 right-2 text-gray-500 hover:text-gray-700 focus:outline-none ml-auto text-[10px] "
            title="Clear all tags"
          >
            clear
          </button>
        )}
      </div>
    </div>
  );
};

export default TagsInput;
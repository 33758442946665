import React from "react";
import fetchApi from "../Lib/api";
import FeatureButton from "../../stories/FeatureButton";

export default function ProgramChangesApproval({
  id,
  setSubcategory,
  toggle,
}) {
  const handleApproval = async (approve) => {
    try {
      const response = await fetchApi(
        `/sub_categories/${id}/${approve ? "approve" : "disapprove"}`,
        "PATCH"
      );

      if (response.ok) {
        const responseData = await response.json();
        setSubcategory(responseData.sub_category);
        toggle();
      } else {
        console.error("HTTP error! Failed to submit form");
      }
    } catch (error) {
      console.error("Error during password change:", error);
    }
  };
  return (
    <>
      <FeatureButton
        buttonText="Approve Changes"
        action={() => handleApproval(true)}
      />{" "}
      <FeatureButton
        buttonText="Reject Changes"
        hidePlus={true}
        action={() => handleApproval(false)}
      />
    </>
  );
}

import React from "react";
import { Routes, Route } from "react-router-dom";
import Layout from "../Lib/Layout";
import UserProvider  from "../Lib/UserContext";
import Onboarding from "../Onboarding/Onboarding";
import QueryProvider from "../Lib/QueryClientProvider";

export default function AuthenticationPage() {
  return (
    <QueryProvider>
    <Layout>
      <UserProvider>
        <Routes>
          <Route path="/onboarding" element={<Onboarding />} />
        </Routes>
      </UserProvider>
    </Layout>
    </QueryProvider>
  );
}

// useDropbox.js

import { useEffect, useState } from "react";
import { Dropbox } from "dropbox";

const useDropbox = (dropboxAppKey, dropboxRedirectUri) => {
  const [accessToken, setAccessToken] = useState(() => {
    const storedToken = localStorage.getItem("dropboxAccessToken");
    const tokenExpiry = localStorage.getItem("dropboxTokenExpiry");

    if (storedToken && new Date().getTime() < tokenExpiry) {
      return storedToken;
    } else {
      return null;
    }
  });

  const [dbx, setDbx] = useState(null);

  useEffect(() => {
    // Load Dropbox Chooser script
    const dropboxScript = document.createElement("script");
    dropboxScript.src = "https://www.dropbox.com/static/api/2/dropins.js";
    dropboxScript.id = "dropboxjs";
    dropboxScript.setAttribute("data-app-key", dropboxAppKey);
    document.body.appendChild(dropboxScript);

    return () => {
      document.body.removeChild(dropboxScript);
    };
  }, [dropboxAppKey]);

  useEffect(() => {
    if (accessToken) {
      // Initialize Dropbox client without passing fetch
      const dropbox = new Dropbox({ accessToken });
      setDbx(dropbox);
    }
  }, [accessToken]);

  const authenticateDropbox = () => {
    const authUrl = `https://www.dropbox.com/oauth2/authorize?client_id=${dropboxAppKey}&response_type=token&redirect_uri=${encodeURIComponent(
      dropboxRedirectUri
    )}&token_access_type=online&scope=files.content.read%20files.content.write%20sharing.write`;

    const width = 600;
    const height = 700;
    const left = window.screen.width / 2 - width / 2;
    const top = window.screen.height / 2 - height / 2;

    const authWindow = window.open(
      authUrl,
      "DropboxAuth",
      `width=${width},height=${height},top=${top},left=${left}`
    );

    const interval = setInterval(() => {
      try {
        if (authWindow.closed) {
          clearInterval(interval);
        }
        const hash = authWindow.location.hash;
        if (hash) {
          const params = new URLSearchParams(hash.substr(1));
          const accessToken = params.get("access_token");
          const expiresIn = params.get("expires_in");

          if (accessToken) {
            const expiryTime = new Date().getTime() + expiresIn * 1000;
            localStorage.setItem("dropboxAccessToken", accessToken);
            localStorage.setItem("dropboxTokenExpiry", expiryTime);
            setAccessToken(accessToken);
            authWindow.close();
          }
        }
      } catch (error) {
        // Ignore cross-origin errors
      }
    }, 500);
  };

  const openDropboxChooser = (onSuccess) => {
    const options = {
      success: (files) => {
        onSuccess(files);
      },
      cancel: () => {
        console.log("Dropbox Chooser canceled");
      },
      linkType: "preview",
      multiselect: true,
    };
    window.Dropbox.choose(options);
  };

  const fetchDropboxThumbnail = async (filePath) => {
    if (!dbx) {
      console.error("Dropbox client not initialized or access token not available.");
      return null;
    }

    try {
      const response = await dbx.filesGetThumbnail({
        path: filePath,
        format: "jpeg",
        size: "w64h64",
        mode: "strict",
      });

      const blob = response.result.fileBlob;
      const thumbnailUrl = URL.createObjectURL(blob);
      return thumbnailUrl;
    } catch (error) {
      console.error("Error fetching Dropbox thumbnail:", error.error || error);
      return null;
    }
  };

const uploadToDropbox = async (files) => {
  if (!dbx) {
    console.error("Dropbox client not initialized or access token not available.");
    return [];
  }

  const uploadedFiles = [];

  try {
    for (const file of files) {
      const path = "/uploads/" + file.name;

      // Upload the file
      const uploadResponse = await dbx.filesUpload({
        path: path,
        contents: file.file,
      });

      const fileMetadata = uploadResponse.result;

      console.log("Uploaded File Metadata:", fileMetadata);

      // Create a shared link for the uploaded file
      const sharedLinkResponse = await dbx.sharingCreateSharedLinkWithSettings({
        path: fileMetadata.path_lower,
        settings: { requested_visibility: 'public' },
      });

      const sharedLink = sharedLinkResponse.result.url;

      // Modify the shared link to get a direct download link
      const directLink = sharedLink.replace('&dl=0', '&raw=1');

      // Fetch thumbnail using the file path
      let thumbnailUrl = null;
      try {
        thumbnailUrl = await fetchDropboxThumbnail(fileMetadata.path_lower);
      } catch (error) {
        console.error("Error fetching thumbnail:", error);
        thumbnailUrl = 'https://www.dropbox.com/static/images/icons64/page_white.png';
      }
      const iconUrl = 'https://www.dropbox.com/static/images/icons64/page_white.png';

      const uploadedFile = {
        id: fileMetadata.id,
        name: fileMetadata.name,
        size_bytes: fileMetadata.size,
        source: "dropbox",
        thumbnail: thumbnailUrl,
        icon: iconUrl,
        url: directLink, // Use the direct shareable link
      };

      console.log("Uploaded File with Shared Link:", uploadedFile);

      uploadedFiles.push(uploadedFile);
    }

    return uploadedFiles;
  } catch (error) {
    if (error.status === 401) {
      console.error("Unauthorized: Please re-authenticate with Dropbox.");
      localStorage.removeItem("dropboxAccessToken");
      localStorage.removeItem("dropboxTokenExpiry");
      setAccessToken(null);
      authenticateDropbox();
    } else {
      console.error("Error uploading files to Dropbox:", error.error || error);
    }
    return [];
  }
};


  return {
    authenticateDropbox,
    openDropboxChooser,
    uploadToDropbox,
    isDropboxAuthenticated: !!accessToken,
    fetchDropboxThumbnail,
  };
};

export default useDropbox;

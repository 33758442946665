import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useState } from "react";

import homeIcon from "../../stories/assets/home.svg";
import forumIcon from "../../stories/assets/forum.svg";
import membersIcon from "../../stories/assets/members.svg";
import activityIcon from "../../stories/assets/activity.svg";
import workspaceIcon from "../../stories/assets/workspace.svg";
import profileIcon from "../../stories/assets/profile.svg";

import { upperFirst } from "@mantine/hooks";
import fetchApi from "./api";
import { useTranslation } from "react-i18next";
import Sidebar from "../../stories/Sidebar";
import useUserStore from "../../stores/userStore";

export const handleLogout = async () => {
  try {
    const response = await fetchApi("/users/sign_out", "DELETE", {});

    if (response) {
      // Redirect using JavaScript after successful logout
      window.location.href = "/users/sign_in"; // Redirect to the homepage
    } else {
      console.error("Logout error:", "Failed to log out");
    }
  } catch (error) {
    console.error("Logout error:", error.message);
  }
};

export default function NavbarSimple({ navlinks }) {
  const { pathname } = useLocation();
  const { t } = useTranslation();
  const [active, setActive] = useState(navlinks ? "Profile" : "Home");
  const { user } = useUserStore(
    (state) => state.user
  );

  useEffect(() => {
    const parts = pathname.split("/");
    const lastPart = parts[parts.length - 1];

    setActive(lastPart.length > 3 ? upperFirst(lastPart) : active);
  }, [pathname]);

  return (
    <Sidebar
      startupDetails={{
        startupName: user.full_name,
        subText: user.job_title || "Student",
        startupImage: user?.avatar?.url,
      }}
      navLinks={
        navlinks
          ? { ...navlinks, active: active }
          : {
              links: [
                {
                  name: t("sidebar.home"),
                  icon: homeIcon,
                  href: { hash: "", href: "/dashboard/#/" },
                },
                {
                  name: t("sidebar.forums"),
                  icon: forumIcon,
                  href: { hash: "forums", href: "/dashboard/#/forums" },
                },
                {
                  name: t("sidebar.messages"),
                  icon: forumIcon,
                  href: { hash: "messages", href: "/dashboard/#/messages" },
                },
                {
                  name: t("sidebar.members"),
                  icon: membersIcon,
                  href: { hash: "members", href: "/dashboard/#/members" },
                },
                {
                  name: t("sidebar.activity"),
                  icon: activityIcon,
                  href: { hash: "activity", href: "/dashboard/#/activty" },
                },
                {
                  name: t("sidebar.workspace"),
                  icon: workspaceIcon,
                  href: { hash: "workspace", href: "/dashboard/#/workspace" },
                },
                {
                  name: t("sidebar.profile"),
                  icon: profileIcon,
                  href: { hash: "", href: "/profile/#/" },
                },
              ],
              active: active,
            }
      }
    />
  );
}
import React from "react";
import { Routes, Route } from "react-router-dom";
import Layout from "../Lib/Layout";
import UserProvider from "../Lib/UserContext";
import CreateStartup from "../Startups/CreateStartup";
import StartupShow from "../Startups/StartupShow";
import StartupConfirmation from "../Startups/StartupConfirmation";
import EditStartup from "../Startups/EditStartup";
import MeetingConfirmation from "../Startups/MeetingConfirmation";
import QueryProvider from "../Lib/QueryClientProvider";

export default function AuthenticationPage() {
  return (
    <QueryProvider>
    <Layout>
      <UserProvider>
        <Routes>
          <Route path="/startup_entities/new/:token" element={<CreateStartup />} />
          <Route
            path="/project_entities/new"
            element={<CreateStartup project={true} />}
          />
          <Route
            path="/startup_entities/:slug/edit"
            element={<EditStartup />}
          />
          <Route path="/startup_entities/:slug" element={<StartupShow />} />
          <Route
            path="/project_entities/:slug"
            element={<StartupShow project={true} />}
          />
          <Route
            path="/invitations/confirm_invitation/:token"
            element={<StartupConfirmation />}
          />
          <Route
            path="/meetings/verify/:token"
            element={<MeetingConfirmation />}
          />
        </Routes>
      </UserProvider>
    </Layout>
    </QueryProvider>
  );
}

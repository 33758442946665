import React, { memo, useRef, useState } from "react";
import styled from "styled-components";
import locationIcon from "./assets/location.svg";
import ideaIcon from "./assets/idea.svg";
import domainIcon from "./assets/domain.svg";
import editIcon from "./assets/edit.svg";
import shareIcon from "./assets/share.svg";
import FeatureButton from "./FeatureButton";
import { handleFollowUnfollow } from "../components/Startups/StartupShow";
import ContainerButton from "./ContainerButton";
import useStartupStore from "../stores/startupStore";
import ContactMessage from "../components/Startups/ContactMessage";
import Breadcrumbs from "./Breadcrumbs";
import fetchApi from "../components/Lib/api";

const MemoContactMessage = memo(ContactMessage);

const StartupHeaderContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-gap: 20px;
  align-items: flex-start;
  border-radius: 8px;

  @media (max-width: 1024px) {
    grid-gap: 10px;
  }
`;

const StartupWrapper = styled.div`
  grid-column: 1 / span 7;
  display: flex;
`;

const StartupImage = styled.img`
  width: 110px;
  height: 110px;
  border-radius: 6px;
  object-fit: cover;
  margin-right: 16px;
  cursor: pointer;
  border: 1px solid var(--color-container-card-default);
`;

const StartupContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 10px;
  align-items: flex-start;
`;

const StartupTitle = styled.h2`
  font-size: var(--font-size-h3);
  font-weight: var(--font-weight-body-2);
  color: var(--color-background-card);
  line-height: 26px;
`;

const StartupDescription = styled.p`
  text-align: left;
  color: var(--color-background-main);
`;

const ReadMoreButton = styled.button`
  color: var(--color-button-accent);
  font-weight: var(--font-weight-body-3);
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;
`;

const StartupInfoHead = styled.div`
  grid-column: 8 / span 4;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 12px;
`;

const InfoItem = styled.div`
  display: flex;
  align-items: center;
  font-size: var(--font-size-body-2);
  color: var(--color-background-main);
  gap: 12px;
`;

const InfoItemLeft = styled.div`
  display: flex;
  gap: 8px;
  font-size: var(--font-size-body-4);
  color: var(--color-background-card);
`;

const ActionButtons = styled.div`
  grid-column: 12 / span 1;
  display: flex;
  justify-content: flex-end;
  gap: 10px;
`;

const ActionButton = styled.button`
  background-color: var(--color-container-card-default);
  border: none;
  border-radius: 4px;
  padding: 7px;
  cursor: pointer;

  img {
    width: 15px;
    height: 15px;
  }
`;

const StartupHeader = () => {
  const { startup, slug, isOwner, role, isMember, isFollowing } =
    useStartupStore((state) => ({
      startup: state.startup,
      role: state.role,
      isFollowing: state.isFollowing,
      startupImage: state.startupImage,
      slug: state.slug,
      isOwner: state.isOwner,
      isMember: state.isMember,
    }));

  const [isCollapsed, setIsCollapsed] = useState(true);
  const [follow, setFollow] = useState(isFollowing);
  const fileInputRef = useRef(null);
  const [startupImage, setStartupImage] = useState(null);

  const toggleCollapse = () => {
    setIsCollapsed(!isCollapsed);
  };

  const truncatedDescription = isCollapsed
    ? startup.description.slice(0, 100) + "..."
    : startup.description;

  // Handle image click event
  const handleImageClick = () => {
    if (isMember || isOwner) {
      fileInputRef.current.click();
    }
  };

  const handleFileChange = async (event) => {
    // Handle file changes here
    const selectedFile = event.target.files[0];
    console.log(selectedFile);
    setStartupImage(URL.createObjectURL(selectedFile));

    try {
      const formData = new FormData();
      formData.append(`startup_entity[image]`, selectedFile);
      const response = await fetchApi(
        `/startup_entities/${slug}`,
        "PATCH",
        formData,
        true
      );

      if (response.ok) {
        console.log("Success:", response.user);

        const responseData = await response.json();
        console.log("Success:", responseData);
      } else {
        console.error("HTTP error! Failed to submit form");
      }
    } catch (error) {
      console.error("Error:", error.message);
    }
  };

  return (
    <>
    <input
              type="file"
              ref={fileInputRef}
              style={{ display: "none" }}
              onChange={handleFileChange}
            />
      <Breadcrumbs
        links={[
          { name: "Home", href: "/dashboard/#/", active: false },
          { name: startup.name, active: true },
        ]}
      />
      <StartupHeaderContainer>
        <StartupWrapper>
          <StartupImage
            src={
              startupImage ||
              startup.image?.url ||
              "https://flowbite.com/docs/images/examples/image-3@2x.jpg"
            }
            alt="Startup"
            onClick={handleImageClick}
          />

          <StartupContent>
            <StartupTitle>{startup.name}</StartupTitle>
            {startup.description.length > 100 ? (
              <>
                {" "}
                <StartupDescription>{truncatedDescription}</StartupDescription>
                <ReadMoreButton onClick={toggleCollapse}>
                  {isCollapsed ? "Read more" : "Read less"}
                </ReadMoreButton>
              </>
            ) : (
              <StartupDescription>{startup.description}</StartupDescription>
            )}
          </StartupContent>
        </StartupWrapper>

        <StartupInfoHead>
          <InfoItem>
            <InfoItemLeft>
              <img src={locationIcon} alt="Location" />
              <b>Location</b>
            </InfoItemLeft>
            <span>{`${startup.city} ${startup.country}`}</span>
          </InfoItem>
          <InfoItem>
            <InfoItemLeft>
              <img src={ideaIcon} alt="Idea" />
              <b>Idea</b>
            </InfoItemLeft>
            <span>{startup.stage}</span>
          </InfoItem>
          <InfoItem>
            <InfoItemLeft>
              <img src={domainIcon} alt="Domain" />
              <b>Domain</b>
            </InfoItemLeft>
            <span>{startup.industry}</span>
          </InfoItem>
          <div style={{ display: "flex", gap: "8px" }}>
            <ContainerButton
              buttonText={follow ? "Following" : "Follow"}
              action={async () =>
                setFollow(await handleFollowUnfollow(follow, slug))
              }
            />
            <MemoContactMessage
              slug={slug}
              isMember={isOwner || isMember}
              role={role}
            />
          </div>
        </StartupInfoHead>

        <ActionButtons>
          {(isOwner || role < 1) && (
            <ActionButton>
              <a href={`/startup_entities/${slug}/edit`}>
                <img src={editIcon} alt="Edit" />
              </a>
            </ActionButton>
          )}
          <ActionButton>
            <img src={shareIcon} alt="Share" />
          </ActionButton>
        </ActionButtons>
      </StartupHeaderContainer>
    </>
  );
};

export default StartupHeader;

import React from 'react';
import styled from 'styled-components';

const MainContainerWrapper = styled.div`
  width: 100%;
  max-width: 1200px;
  margin: 0 auto; 
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

const MainContainer = ({ children }) => {
  return (
    <MainContainerWrapper>
      {children} {/* Render all the children passed to MainContainer */}
    </MainContainerWrapper>
  );
};

export default MainContainer;

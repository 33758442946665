import {
  Anchor,
  Button,
  Divider,
  Group,
  Menu,
  Modal,
  Select,
  Stack,
  Text,
  TextInput,
  Title,
} from "@mantine/core";
import { useForm } from "@mantine/form";
import React, { useEffect, useState } from "react";
import fetchApi from "../Lib/api";
import { countriesData, industriesData } from "../Onboarding/data";
import { useParams } from "react-router-dom";
import { IconArrowLeft } from "@tabler/icons-react";
import { StartupPrivacy } from "./StartupShow";
import { useDisclosure } from "@mantine/hooks";

export default function EditStartup() {
  const { slug } = useParams();
  const [owner, setOwner] = useState(false);
  const [startup, setStartup] = useState({});
  const [opened, { close, open }] = useDisclosure(false);
  const form = useForm({
    initialValues: {
      name: "",
      description: "",
      industry: "",
      stage: "",
      country: "",
      city: "",
      website: "",
      linkedin: "",
      social_media: "",
      project_type: "",
    },
    validate: {
      name: (value) => {
        if (!value) {
          return "Name is required";
        }
        return null;
      },
      description: (value) => {
        if (!value) {
          return "Description is required";
        }
        return null;
      },
      industry: (value) => {
        if (!value) {
          return "Industry is required";
        }
        return null;
      },
      stage: (value, values) => {
        if (!value && !values.project_type) {
          return "Stage is required";
        }
        return null;
      },
      country: (value) => {
        if (!value) {
          return "Country is required";
        }
        return null;
      },
      city: (value) => {
        if (!value) {
          return "City is required";
        }
        return null;
      },
    },
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetchApi(
          `/startup_entities/${slug}/edit`,
          "GET"
        );

        if (response.ok) {
          const res = await response.json();
          console.log(res);

          // Assuming res.startup_entity is an object
          Object.keys(form.values).forEach((key) => {
            form.setFieldValue(key, res.startup_entity[key]);
          });
          setOwner(res.is_owner);
          setStartup(res);
          console.log(form.values);
        } else {
          const msg = (await response.json()).error;
          console.error(msg);
        }
      } catch (error) {
        console.error("Error during startup privacy change:", error);
      }
    };

    fetchData(); // Call the async function immediately
  }, [slug]); // Added form as a dependency to the dependency array

  const handleSubmit = async (e) => {
    try {
      const response = await fetchApi(`/startup_entities/${slug}`, "PUT", {
        startup_entity: form.values,
      });

      if (response.ok) {
        const res = (await response.json()).startup_entity;
        // setNotification({ type: "success", content: msg });
        console.log(res);
        window.location.href = `/startup_entities/${res.slug}`;
      } else {
        const msg = (await response.json()).error;
        // setNotification({ type: "failure", content: msg });
        console.error(msg);
      }
    } catch (error) {
      console.error("Error during startup update:", error);
    }
  };

  const handleDelete = async (e) => {
    try {
      const response = await fetchApi(`/startup_entities/${slug}`, "DELETE");

      if (response.ok) {
        const res = (await response.json()).startup_entity;
        console.log(res);
        window.location.href = `/dashboard/#/`;
      } else {
        const msg = (await response.json()).error;
        // setNotification({ type: "failure", content: msg });
        console.error(msg);
      }
    } catch (error) {
      console.error("Error during deleting startup", error);
    }
  };

  return (
    <>
      <main className="flex justify-center ">
        <div className="max-w-[900px] w-full px-6 py-12">
          <Anchor onClick={() => window.history.back()} target="_blank">
            <Group className="mb-2" position="center" spacing={4}>
              <IconArrowLeft size={18} color="#1c7ed6" />
              Go back
            </Group>
          </Anchor>
          <Title order={3} className="text-gray-600">
            Edit {startup?.startup_entity?.entity_type}
          </Title>

          <form onSubmit={form.onSubmit(handleSubmit)} className="mt-4 px-2">
            <Stack spacing={24}>
              <Title order={4}>General Details</Title>
              <Text className="text-base text-gray-600">
                Revamp your startup details to stand out. Keep it fresh and
                engaging.
              </Text>

              <Divider
                my="xs"
                label={`${startup?.startup_entity?.entity_type} information`}
                labelPosition="center"
                className="lg:w-2/3"
              />

              <TextInput
                required
                label={`${startup?.startup_entity?.entity_type} name`}
                value={form.values.name}
                onChange={(event) =>
                  form.setFieldValue("name", event.currentTarget.value)
                }
                error={form.errors.name}
                radius="md"
                size="md"
                className="lg:w-2/3"
              />

              <TextInput
                required
                label="Description"
                value={form.values.description}
                onChange={(event) =>
                  form.setFieldValue("description", event.currentTarget.value)
                }
                error={form.errors.description}
                radius="md"
                size="md"
                className="lg:w-2/3"
              />
              <Select
                label="Industry"
                radius="md"
                size="md"
                name="work_type"
                data={industriesData}
                value={form.values.industry}
                onChange={(event) => form.setFieldValue("industry", event)}
                error={form.errors.industry}
                withAsterisk
                className="lg:w-2/3"
                searchable
              />

              {startup?.startup_entity?.entity_type === "Project" ? (
                <Select
                  label="Project Type"
                  radius="md"
                  size="md"
                  name="project_type"
                  data={["Freelance", "Institution"]}
                  value={form.values.project_type}
                  onChange={(event) =>
                    form.setFieldValue("project_type", event)
                  }
                  error={form.errors.project_type}
                  withAsterisk
                  className="lg:w-2/3"
                />
              ) : (
                <Select
                  label="Development stage"
                  radius="md"
                  size="md"
                  name="work_type"
                  data={[
                    "Idea",
                    "Prototype",
                    "Start-up",
                    "Project",
                    "Growth",
                    "Maturity",
                  ]}
                  value={form.values.stage}
                  onChange={(event) => form.setFieldValue("stage", event)}
                  error={form.errors.stage}
                  withAsterisk
                  className="lg:w-2/3"
                />
              )}

              <Divider
                my="xs"
                label="Location information"
                labelPosition="center"
                className="lg:w-2/3"
              />

              <Group className="lg:w-2/3" grow position="apart">
                <Select
                  required
                  data={countriesData}
                  label="Country"
                  value={form.values.country}
                  onChange={(event) => form.setFieldValue("country", event)}
                  error={form.errors.country}
                  radius="md"
                  size="md"
                  searchable
                  nothingFound="No options"
                />
                <TextInput
                  required
                  label="City"
                  value={form.values.city}
                  onChange={(event) =>
                    form.setFieldValue("city", event.currentTarget.value)
                  }
                  error={form.errors.city}
                  radius="md"
                  size="md"
                />
              </Group>
              <Divider
                my="xs"
                label="Links"
                labelPosition="center"
                className="lg:w-2/3"
              />
              <TextInput
                label="Website"
                value={form.values.website}
                onChange={(event) =>
                  form.setFieldValue("website", event.currentTarget.value)
                }
                error={form.errors.website}
                radius="md"
                size="md"
                className="lg:w-2/3"
              />
              <Group className="lg:w-2/3 lg:mt-2" grow position="apart">
                <TextInput
                  label="LinkedIn Link"
                  description="Don't have one for the company? Add your Own."
                  value={form.values.linkedin}
                  onChange={(event) =>
                    form.setFieldValue("linkedin", event.currentTarget.value)
                  }
                  error={form.errors.linkedin}
                  radius="md"
                  size="md"
                />
                <TextInput
                  label="Social Media Link"
                  description="Don't have one for the company? Add your Own."
                  value={form.values.social_media}
                  onChange={(event) =>
                    form.setFieldValue(
                      "social_media",
                      event.currentTarget.value
                    )
                  }
                  error={form.errors.social_media}
                  radius="md"
                  size="md"
                />
              </Group>
              <Group className="lg:w-2/3 mt-4" position="right">
                <Button type="submit" variant="default" className="w-fit px-12">
                  Save changes
                </Button>
              </Group>
            </Stack>
          </form>
          {owner && (
            <div className="mt-8 px-2">
              <Stack spacing={4}>
                <Title order={4}>Privacy Settings</Title>
                <Text className="text-base text-gray-600">
                  Privacy settings can only be updated by the startup owner.
                </Text>
                <Group position="apart" className="mt-4">
                  <div>
                    <Title order={6}>Change Startup Visibility</Title>
                    <Text>
                      This {startup?.startup_entity?.entity_type} is currently{" "}
                      {startup.startup_entity.public_listed
                        ? "visible"
                        : "hidden"}{" "}
                      to the public
                    </Text>
                  </div>
                  <StartupPrivacy startup={startup} setStartup={setStartup} />
                </Group>
                <Group position="apart" className="mt-4">
                  <div>
                    <Title order={6}>Delete this startup</Title>
                    <Text>
                      Once you delete a {startup?.startup_entity?.entity_type},
                      there is no going back. Please be certain.
                    </Text>
                  </div>
                  <Button
                    variant="default"
                    className="w-fit px-10 text-red-700"
                    onClick={open}
                  >
                    Delete
                  </Button>
                </Group>
              </Stack>
            </div>
          )}
        </div>
      </main>
      <Modal opened={opened} onClose={close} size="lg" withCloseButton={false}>
        <div class="p-4 overflow-y-auto">
          <p class="mt-1 text-gray-8000">
            Deleting the {startup?.startup_entity?.name}{" "}
            {startup?.startup_entity?.entity_type} will permanently erase all
            associated data and progress. Please confirm that you understand the
            irreversible nature of this action before proceeding.
          </p>
        </div>
        <div class="flex justify-end items-center gap-x-2 py-3 px-4">
          <Button onClick={close} variant="default" className=" px-12">
            Cancel
          </Button>
          <Button
            onClick={handleDelete}
            variant="default"
            className="text-red-700 px-12"
          >
            Proceed anyway
          </Button>
        </div>
      </Modal>
    </>
  );
}

import React, { useEffect } from "react";
import { useQuery } from "@tanstack/react-query";
import fetchApi from "./api"; // Adjust based on your structure
import useUserStore from "../../stores/userStore"; // Zustand store
import { Loader } from "@mantine/core";

// Function to fetch user data
const fetchUser = async (setUser) => {
  const response = await fetchApi("/api/users/current");
  if (!response.ok) {
    throw new Error("Failed to fetch user data");
  }
  const res = await response.json();
  setUser(res);
  console.log(res)
  return res;
};

// Function to update the last seen status
const updateLastSeen = async () => {
  try {
    const response = await fetchApi("/api/users/update_last_seen", "POST");
    if (response.ok) {
      console.log("Last seen updated");
    } else {
      console.log("Error updating last seen");
    }
  } catch (error) {
    console.error("Error during updateLastSeen:", error);
  }
};

const UserProvider = ({ children }) => {
  const { setUser, setUpdateInterval, clearUpdateInterval } = useUserStore();

  // Use TanStack Query to fetch the user data
  const { data, error, isLoading, isSuccess, isFetching } = useQuery({
    queryKey: ["user"],
    queryFn: () => fetchUser(setUser),
    staleTime: 1000 * 60 * 60 * 24,
    cacheTime: 1000 * 60 * 60 * 24,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    refetchOnReconnect: false,
    retryOnMount: false,
  });

  // Set up the interval to update "last seen"
  useEffect(() => {
    if (!isFetching && isSuccess && data) {
      // Check for successful data fetch
      setUser(data); // Store the fetched user in Zustand
      setUpdateInterval(updateLastSeen);
    }
  }, [isFetching, isSuccess, data, setUser, setUpdateInterval]);

  // Clean up interval on unmount
  useEffect(() => {
    return () => clearUpdateInterval();
  }, [clearUpdateInterval]);

  if (isLoading || isFetching)
    return (
      <Loader
        className="mx-auto mt-10"
        color="indigo"
        size="lg"
        variant="dots"
      />
    );
  if (error) return <p>Error fetching user: {error.message}</p>;

  return <>{children}</>;
};

export default UserProvider;





// # Staging environment (only for staging.nowcollab.com)
// server {
//     listen 443 ssl http2;
//     listen [::]:443 ssl http2;
//     server_name staging.nowcollab.com;

//     ssl_certificate /etc/letsencrypt/live/nowcollab.com/fullchain.pem;
//     ssl_certificate_key /etc/letsencrypt/live/nowcollab.com/privkey.pem;

//     # SSL configuration
//     ssl_protocols TLSv1.2 TLSv1.3;
//     ssl_ciphers ECDHE-ECDSA-AES128-GCM-SHA256:ECDHE-RSA-AES128-GCM-SHA256:ECDHE-ECDSA-AES256-GCM-SHA384:ECDHE-RSA-AES256-GCM-SHA384:ECDHE-ECDSA-CHACHA20-POLY1305:ECDHE-RSA-CHACHA20-POLY1305:DHE-RSA-AES128-GCM-SHA256:DHE-RSA-AES256-GCM-SHA384;

//     # Regular HTTP traffic
//     location / {
//         proxy_pass http://rails_staging;
//         proxy_set_header Host $host;
//         proxy_set_header X-Real-IP $remote_addr;
//         proxy_set_header X-Forwarded-For $proxy_add_x_forwarded_for;
//         proxy_set_header X-Forwarded-Proto $scheme;
//     }

//     # WebSocket support
//     location /cable {
//         proxy_pass http://rails_staging/cable;
//         proxy_http_version 1.1;
//         proxy_set_header Upgrade $http_upgrade;
//         proxy_set_header Connection "Upgrade";
//         proxy_set_header Host $host;
//         proxy_set_header X-Real-IP $remote_addr;
//         proxy_set_header X-Forwarded-For $proxy_add_x_forwarded_for;
//         proxy_set_header X-Forwarded-Proto $scheme;
//     }
// }
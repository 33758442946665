import React, { useContext, useEffect, useState } from "react";

import ReactJoyride from "react-joyride";
import HeaderAndSidebar from "../Lib/HeaderAndSidebar";
import useUserStore from '../../stores/userStore';

export default function Dashboard({ children }) {
  return <HeaderAndSidebar sidebar={<Tour />}>{children}</HeaderAndSidebar>;
}

const Tour = () => {
  const [showTour, setShowTour] = useState(false);
  const {
    user: { user },
  } = useUserStore((state) => state.user);

  useEffect(() => {
    // Check if the tour has been shown before
    const tourShown = localStorage.getItem("tourShown");

    if (!tourShown) {
      // Tour hasn't been shown before
      setShowTour(true);
      // Mark the tour as shown
      localStorage.setItem("tourShown", "true");
    }
  }, []);

  return (
    <>
      {!user.profile_complete && showTour && (
        <ReactJoyride
          steps={[
            {
              content: "Let's give you a brief tour of the app 🍫",
              target: "body",
              placement: "center",
            },
            {
              target: ".complete_profile",
              content:
                "Complete your profile details. You won't be able to create startups until you do so.",
              disableBeacon: false,
              placement: "right",
            },
            {
              target: ".home_startups",
              content:
                "Here you'll find all the startups created by others. You can interact and engage with any startup.",
              placement: "right",
            },
            {
              target: ".create-startup",
              content:
                "Interested in creating your own startup? Here's the button for that.",
            },
            {
              target: ".expert",
              content: "Find the right members with expertise in their skills.",
            },
            {
              target: ".all_startups",
              content:
                "A collection list of startups created by others. All public startups can be found here.",
            },
            {
              target: ".all_members",
              content:
                "Find, search, and interact with other members on the platform.",
              placement: "right",
            },
          ]}
          continuous={true}
          run={true}
          showSkipButton={true}
          // callback={handleTourCallback}
          styles={{
            overlay: {
              maxHeight: "100% !important",
              height: "100%",
            },
            buttonNext: {
              backgroundColor: "#0d47a1",
              fontSize: "0.9rem",
            },
            buttonBack: {
              backgroundColor: "transparent",
              color: "#6196f3",
            },
            beaconInner: {
              animation: "joyride-beacon-inner 1.2s infinite ease-in-out",
              backgroundColor: "#1565c0",
              borderRadius: "50%",
              display: "block",
              height: "50%",
              left: "50%",
              opacity: 0.7,
              position: "absolute",
              top: "50%",
              transform: "translate(-50%, -50%)",
              width: "50%",
            },
            beaconOuter: {
              animation: "joyride-beacon-outer 1.2s infinite ease-in-out",
              backgroundColor: "#6196f3",
              border: `2px solid blue`,
              borderRadius: "50%",
              boxSizing: "border-box",
              display: "block",
              height: "100%",
              left: 0,
              opacity: 0.9,
              position: "absolute",
              top: 0,
              transformOrigin: "center",
              width: "100%",
            },
          }}
          showProgress={true}
          disableScrolling={true}
        />
      )}
    </>
  );
};

import { Button, Menu, Select, Stack, UnstyledButton } from "@mantine/core";
import React, { useState } from "react";
import FeatureButton from "../../stories/FeatureButton";

export default function RoadmapPDFGenerator({ slug }) {
  const [opened, setOpened] = useState(false);
  const [format, setFormat] = useState("pdf");
  const [loading, setLoading] = useState(false);
  const fetchPDF = async () => {
    setLoading(true);
    try {
      const response = await fetch(`/startup_entities/${slug}/roadmap_pdf`, {
        method: "GET",
        headers: {
          "Content-Type": "application/pdf",
        },
      });

      if (response.ok) {
        // Get the file name from Content-Disposition header
        const disposition = response.headers.get("Content-Disposition");
        let filename = "roadmap.pdf"; // Default filename

        if (disposition && disposition.indexOf("filename") !== -1) {
          const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
          const matches = filenameRegex.exec(disposition);
          if (matches != null && matches[1]) {
            filename = matches[1].replace(/['"]/g, "");
          }
        }

        const blob = await response.blob();
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = filename; // Use the extracted filename here
        document.body.appendChild(a);
        a.click();
        a.remove();
        window.URL.revokeObjectURL(url);
      } else {
        const msg = (await response.json()).error;
        console.error(msg);
      }
    } catch (error) {
      console.error("Error during PDF fetch:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Menu shadow="md" opened={opened} onChange={setOpened} position="top" closeOnClickOutside>
      <Menu.Target>
        <UnstyledButton component="div" style={{width: "100%"}}>
          <FeatureButton action={() => setOpened(!opened)} buttonText="Export" fullwidth={true} />
        </UnstyledButton>
      </Menu.Target>
      <Menu.Dropdown p={20} className="max-w-80">
        <Stack>
          <Select
            label="Download Format"
            onChange={setFormat}
            value={format}
            data={[{ value: "pdf", label: "PDF Format" }]}
          />
          <Button
            disabled={loading}
            variant="default"
            fullWidth
            onClick={() => {
              fetchPDF();
              setOpened(false);
            }}
          >
            Download
          </Button>
        </Stack>
      </Menu.Dropdown>
    </Menu>
  );
}

import React from 'react';
import styled from 'styled-components';

const StyledButton = styled.button`
  background-color: var(--color-button-accent);
  color: var(--color-container-card-default);
  padding: 10px 50px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  border: 1px solid var(--color-button-accent);

  &:hover {
    background-color: var(--color-container-card-default);
    color: var(--color-button-accent);
  }
`;

const ContainerButton = ({ buttonText, action, disabled }) => {
  return <StyledButton disabled={disabled} onClick={action}>{buttonText}</StyledButton>;
};

export default ContainerButton;

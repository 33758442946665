import React, { useState } from "react";
import {
  IconSettings,
  IconId,
  IconSocial,
  IconStack3,
  IconHome,
} from "@tabler/icons-react";
import { HashRouter as Router, Routes, Route } from "react-router-dom";
import NavbarSimple from "./ProfileNavbar";
import { UserInfoIcons } from "./UserInfo";
import { useForm } from "@mantine/form";
import Profile from "./Pages/Profile";
import Settings from "./Pages/Settings";
import Workspace from "../Dashbaord/DashboardPages/Workspace";
import Activity from "../Dashbaord/DashboardPages/activities";
import HeaderAndSidebar from "../Lib/HeaderAndSidebar";
import useUserStore from "../../stores/userStore";

export default function ProfilePage() {
  const [isEditMode, setIsEditMode] = useState(false);
  const { user } = useUserStore((state) => state.user);

  const form = useForm({
    initialValues: {
      avatar: null,
      first_name: user.first_name || "",
      last_name: user.last_name || "",
      user_type: user.user_type || 0,
      company: user.company || "",
      job_title: user.job_title || "",
      year_of_experience: user.year_of_experience || 0,
      work_type: user.work_type || "",
      university: user.university || "",
      degree: user.degree || "",
      country: user.address?.country || "",
      city: user.address?.city || "",
      about_me: user.about_me || "",
      expert_in: user.expert_in || "",
      skills: user.skills || "",
      industries: user.industries || "",
      github: user.github || "",
      twitter: user.twitter || "",
      linkedin: user.linkedin || "",
      public_email: user.public_email || "",
      public_phone: user.public_phone || "",
      display_email: user.display_email,
      display_phone: user.display_phone,
    },

    validate: {},
  });

  return (
    <HeaderAndSidebar
      navlinks={{
        links: [
          {
            name: "Home",
            icon: IconHome,
            href: { hash: "", href: "/dashboard/#/" },
          },
          {
            name: "Profile",
            icon: IconId,
            href: { hash: "#", href: "/profile/#/" },
          },
          {
            name: "Workspace",
            icon: IconStack3,
            href: { hash: "workspace", href: "/profile/#/workspace" },
          },
          {
            name: "Activity",
            icon: IconSocial,
            href: { hash: "activity", href: "/profile/#/activity" },
          },
          {
            name: "Settings",
            icon: IconSettings,
            href: { hash: "settings", href: "/profile/#/settings" },
          },
        ],
        icons: true,
      }}

    >
      <Routes>
        <Route
          path="/"
          element={
            <Profile
              isEditMode={isEditMode}
              setIsEditMode={setIsEditMode}
              form={form}
            />
          }
        />
        <Route path="/workspace" element={<Workspace />} />
        <Route path="/activity" element={<Activity />} />
        <Route path="/settings" element={<Settings />} />
      </Routes>
    </HeaderAndSidebar>
  );
}

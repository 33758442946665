import React, { memo, useEffect, useRef, useState } from "react";
import { Alert, Button, Loader, Menu } from "@mantine/core";
import { IconAlertCircle, IconEye, IconEyeOff } from "@tabler/icons-react";
import fetchApi from "../Lib/api";
import { useParams } from "react-router-dom";
import HeaderAndSidebar from "../Lib/HeaderAndSidebar";
import MainContainer from "../../stories/MainContainer";
import StartupHeader from "../../stories/StartupHeader";
import StartupBody from "../../stories/StartupBody";
import useStartupStore from "../../stores/startupStore";
import { useQuery } from "@tanstack/react-query";
import styled from "styled-components";

const RoadmapHeader = styled.div`
  align-self: flex-start;
`;

const HeaderTitle = styled.h3`
  font-weight: var(--font-weight-h4);
  color: var(--color-background-card);
`;

const HeaderText = styled.p`
  color: #68706b;
  margin-top: 8px;
`;

const NotFoundContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 400px;
  text-align: center;
  padding: 2rem;
  background-color: #f8f9fa;
`;

const NotFoundTitle = styled.h1`
  font-size: 3rem;
  font-weight: bold;
  color: #d9534f;
  margin-bottom: 1rem;
`;

const NotFoundDescription = styled.p`
  font-size: 1.25rem;
  color: #6c757d;
  margin-bottom: 1.5rem;
`;

const MemoStartupShow = memo(StartupShow);

// Custom hook for fetching startup data
const useStartup = (slug, setStartup) => {
  return useQuery({
    queryKey: ["startup", slug], // Query key for caching
    queryFn: async () => {
      const response = await fetchApi(`/startup_entities/${slug}`, "GET");

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || "Failed to fetch startup data");
      }

      let data = await response.json();
      setStartup(data);
      return data;
    },
    retry: 1, // Retry once on failure
    staleTime: 5 * 60 * 1000, // Consider data fresh for 5 minutes
    cacheTime: 30 * 60 * 1000, // Keep inactive data in cache for 30 minutes
  });
};

export default function StartupContainer({ project }) {
  return (
    <HeaderAndSidebar>
      <MemoStartupShow project={project} />
    </HeaderAndSidebar>
  );
}

export const handleFollowUnfollow = async (
  isFollowing,
  slug,
  setIsFollowing
) => {
  try {
    const endpoint = !isFollowing
      ? `/startup_entities/${slug}/followings`
      : `/startup_entities/${slug}/followings/${slug}`;
    const method = !isFollowing ? "POST" : "DELETE";
    const response = await fetchApi(endpoint, method);

    if (response.ok) {
      const res = await response.json();
      if (setIsFollowing) setIsFollowing(!isFollowing);
    } else {
      const { error } = await response.json();
      console.error(error);
    }
  } catch (error) {
    console.error("Error during follow/unfollow:", error);
  }

  if (!setIsFollowing) return !isFollowing;
};

function StartupShow({ project }) {
  const { slug } = useParams();
  

  const {
    setStartup,
    logTime,
    isOwner,
    isMember,
  } = useStartupStore((state) => ({
    setStartup: state.setStartup,
    logTime: state.logTime,
    isOwner: state.isOwner,
    isMember: state.isMember,
  }));

  const {isLoading, isError, error } = useStartup(slug, setStartup);


  // Log time spent on the page when the component unmounts or page unloads
  useEffect(() => {
    window.addEventListener("beforeunload", () => logTime(slug));

    return () => {
      window.removeEventListener("beforeunload", () => logTime(slug));
      logTime(slug); // Call logTime manually on unmount
    };
  }, [logTime, slug]);

  // Show loading indicator while the data is being fetched
  if (isLoading) {
    return (
      <Loader
        className="mx-auto mt-10"
        color="indigo"
        size="lg"
        variant="dots"
      />
    );
  }

  // Show error message if there's an error fetching the data
    if (isError) {
      console.log(error)
      return (
        <NotFoundContainer>
          <NotFoundTitle>404 - Not Found</NotFoundTitle>
          <NotFoundDescription>
            The startup you're looking for couldn't be found. It might have been removed or you might not have access to it.
          </NotFoundDescription>
          <Button
            variant="outline"
            color="red"
            onClick={() => (window.location.href = "/dashboard/#/")}
          >
            Back to Startups
          </Button>
        </NotFoundContainer>
      );
    }

  return (
    <MainContainer>
      <StartupHeader />
      <RoadmapHeader>
        <HeaderTitle>Roadmaps</HeaderTitle>
        <HeaderText>
          A short and crisp context about what is roadmap and how to complete.{" "}
          <a href="#" style={{ display: "inline" }}>
            <b>What is it ? Know more about it</b>
          </a>
        </HeaderText>
      </RoadmapHeader>
      <StartupBody />
    </MainContainer>
  );
}

export const StartupPrivacy = ({ startup, setStartup }) => {
  const handleSubmit = async (val) => {
    if (startup.startup_entity.public_listed === val) return;

    try {
      const response = await fetchApi(
        `/startup_entities/${startup.startup_entity.slug}`,
        "PATCH",
        {
          startup_entity: { public_listed: val },
        }
      );

      if (response.ok) {
        const res = (await response.json()).startup_entity;
        console.log(res)

        setStartup((p) => ({
          ...p,
          startup_entity: {
            ...p.startup_entity,
            public_listed: res.public_listed,
          },
        }));
      } else {
        const msg = (await response.json()).error;
        console.error(msg);
      }
    } catch (error) {
      console.error("Error during password change:", error);
    }
  };

  return (
    <Menu shadow="md">
      <Menu.Target>
        <Button
          variant="default"
          className="w-fit"
          size="md"
          leftIcon={
            startup.startup_entity.public_listed ? (
              <IconEye size="1.3rem" />
            ) : (
              <IconEyeOff size="1.3rem" />
            )
          }
        >
          Privacy
        </Button>
      </Menu.Target>
      <Menu.Dropdown>
        <div className="relative flex items-start py-2 px-3 rounded-lg hover:bg-gray-100 ">
          <div className="flex items-center h-5 mt-1">
            <input
              id="public"
              type="radio"
              className="shrink-0 border-gray-200 rounded-full text-blue-600 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none "
              onChange={() => handleSubmit(true)}
              checked={startup.startup_entity.public_listed === true}
            />
          </div>
          <label htmlFor="public" className="ms-3.5">
            <span className="block text-sm font-semibold text-gray-800 dark:text-gray-300">
              Public
            </span>
            <span className="block text-sm text-gray-600 dark:text-gray-500">
              Make it available to everyone.
            </span>
          </label>
        </div>
        <div className="relative flex items-start py-2 px-3 rounded-lg hover:bg-gray-100 ">
          <div className="flex items-center h-5 mt-1">
            <input
              id="private"
              type="radio"
              onChange={() => handleSubmit(false)}
              checked={startup.startup_entity.public_listed === false}
              className="shrink-0 border-gray-200 rounded-full text-blue-600 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none "
            />
          </div>
          <label htmlFor="private" className="ms-3.5">
            <span className="block text-sm font-semibold text-gray-800 ">
              Unlisted
            </span>
            <span className="block text-sm text-gray-600 ">
              Make it private from public view.
            </span>
          </label>
        </div>
      </Menu.Dropdown>
    </Menu>
  );
};

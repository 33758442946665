import Filter from "bad-words";

// List of additional bad words to be filtered
const additionalBadWords = [
  "asshole",
  "bastard",
  "bitch",
  "crap",
  "cunt",
  "damn",
  "dick",
  "douchebag",
  "fuck",
  "fucker",
  "fucking",
  "motherfucker",
  "nigger",
  "shit",
  "shitty",
  "slut",
  "whore",
  "wanker",
  "cock",
  "pussy",
  "nigga",
  "twat",
  "dickhead",
  "arsehole",
  "bollocks",
  "bugger",
  "faggot",
  "prick",
  "shithead",
  "tit",
  "tosser",
  "wank",
  "wanky",
  "cum",
  "dildo",
  "dyke",
  "fag",
  "fagg0t",
  "fag1t",
  "faggit",
  "fagz",
  "faig",
  "faigs",
  "farts",
  "goddamn",
  "hell",
  "homo",
  "jackoff",
  "jerk",
  "jizz",
  "knob",
  "lesbo",
  "masturbate",
  "nazi",
  "nutsack",
  "penis",
  "phuck",
  "queer",
  "retard",
  "schlong",
  "screw",
  "semen",
  "sh1t",
  "sh1tter",
  "shiz",
  "skank",
  "smegma",
  "spic",
  "spunk",
  "tits",
  "titties",
  "tranny",
  "vagina",
  "wop",
  "wuss",
  "anus",
  "assface",
  "asswipe",
  "azzhole",
  "biatch",
  "blowjob",
  "boffing",
  "butthole",
  "clit",
  "clitoris",
  "cockhead",
  "cocksucker",
  "coochie",
  "coon",
  "cornhole",
  "crotch",
  "cuck",
  "cumshot",
  "cunillingus",
  "cunnilingus",
  "dickhead",
  "dicksucker",
  "dike",
  "dipshit",
  "douche",
  "dumbass",
  "dumbfuck",
  "dumbshit",
  "dyk",
  "fagbag",
  "fagtard",
  "felching",
  "felcher",
  "fister",
  "flamer",
  "foreskin",
  "fudgepacker",
  "gangbang",
  "gook",
  "guido",
  "handjob",
  "hardcoresex",
  "headfuck",
  "humping",
  "jap",
  "juggalo",
  "junglebunny",
  "kike",
  "kunt",
  "lesbian",
  "licker",
  "minge",
  "muffdiver",
  "munging",
  "numbnuts",
  "orgy",
  "piss",
  "pissflaps",
  "polesmoker",
  "poon",
  "poonani",
  "punani",
  "punta",
  "rape",
  "rapist",
  "rimjob",
  "scat",
  "shitface",
  "shitfaced",
  "shitfuck",
  "shithead",
  "shithole",
  "shithouse",
  "shitspitter",
  "shitstain",
  "slapper",
  "slutbag",
  "smeghead",
  "spic",
  "spick",
  "spoog",
  "spooge",
  "spunkbubble",
  "taint",
  "titty",
  "tosspot",
  "twatface",
  "twats",
  "twunt",
  "vaj",
  "vulva",
  "wang",
  "wanker",
  "wanky",
  "wazoo",
  "wetback",
  "whorebag",
  "whoreface",
  "wog",
  "wop",
  "wussbag",
  "wussy",
  "wuzzie",
  // Add more words if needed
];

const configureFilter = () => {
  const filter = new Filter();
  filter.addWords(...additionalBadWords);
  return filter;
};

const cleanText = (text) => {
  if (!text) return text;
  const filter = configureFilter();
  return filter.clean(text);
};

export { cleanText };

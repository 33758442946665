// Import necessary modules and hooks
import React from "react";
import { diff_match_patch } from "diff-match-patch";
import ContainerButton from "../../stories/ContainerButton";
import fetchApi from "../Lib/api";
import useStartupStore from "../../stores/startupStore";
import useUserStore from "../../stores/userStore";

// Safely parse JSON data
function parseJSON(data) {
  try {
    return typeof data === "string" ? JSON.parse(data) : data || { blocks: [] };
  } catch (error) {
    console.error("Error parsing JSON:", error);
    return { blocks: [] };
  }
}

// Compute LCS of two sequences
function computeLCS(oldBlocks, newBlocks) {
  const dp = Array(oldBlocks.length + 1)
    .fill(null)
    .map(() => Array(newBlocks.length + 1).fill(0));
  for (let i = oldBlocks.length - 1; i >= 0; i--) {
    for (let j = newBlocks.length - 1; j >= 0; j--) {
      dp[i][j] = blocksEqual(oldBlocks[i], newBlocks[j])
        ? dp[i + 1][j + 1] + 1
        : Math.max(dp[i + 1][j], dp[i][j + 1]);
    }
  }
  return dp;
}

// Check if two blocks are equal (consider initials)
function blocksEqual(blockA, blockB) {
  if (
    blockHasInitials(blockA) &&
    blockHasInitials(blockB) &&
    blockA.type === blockB.type
  ) {
    return true;
  }
  if (!blockHasInitials(blockA) && !blockHasInitials(blockB)) {
    return (
      blockA.type === blockB.type &&
      deepEqual(stripAllInitials(blockA.data), stripAllInitials(blockB.data))
    );
  }
  return false;
}

// Deep comparison
function deepEqual(obj1, obj2) {
  return JSON.stringify(obj1) === JSON.stringify(obj2);
}

// Check if a block already has initials
function blockHasInitials(block) {
  // Check if the block data contains any initials in the format <sup><a>...</a></sup>
  const initialsRegex = /<sup><a[^>]*>.*<\/a><\/sup>/;

  // Recursively check for initials in the block data
  function containsInitials(data) {
    if (typeof data === "string") {
      return initialsRegex.test(data); // Check if initials exist in the string
    }
    if (Array.isArray(data)) {
      return data.some(containsInitials); // Check arrays recursively
    }
    if (typeof data === "object" && data !== null) {
      return Object.values(data).some(containsInitials); // Check objects recursively
    }
    return false;
  }

  return containsInitials(block.data);
}

// Strip initials for comparison (do not modify actual content)
function stripAllInitials(content) {
  if (typeof content === "string") {
    return content.replace(/<sup><a[^>]*>.*<\/a><\/sup>/g, "");
  }
  if (Array.isArray(content)) {
    return content.map(stripAllInitials);
  }
  if (typeof content === "object" && content !== null) {
    return Object.entries(content).reduce((acc, [key, value]) => {
      acc[key] = stripAllInitials(value);
      return acc;
    }, {});
  }
  return content;
}

// Annotate editor data by comparing old and new data
function annotateEditorJsData(oldData, newData, userInitials, userUrl) {
  const annotatedBlocks = [];
  const dp = computeLCS(oldData.blocks || [], newData.blocks || []);

  let i = 0,
    j = 0;
  while (i < oldData.blocks.length || j < newData.blocks.length) {
    if (
      i < oldData.blocks.length &&
      j < newData.blocks.length &&
      blocksEqual(oldData.blocks[i], newData.blocks[j])
    ) {
      // Unchanged block, add as is
      annotatedBlocks.push(newData.blocks[j]);
      i++;
      j++;
    } else if (
      j < newData.blocks.length &&
      (i === oldData.blocks.length || dp[i][j + 1] >= dp[i + 1][j])
    ) {
      // Inserted block, add initials if they aren't present
      const insertedBlock = JSON.parse(JSON.stringify(newData.blocks[j]));
      if (!blockHasInitials(insertedBlock)) {
        insertedBlock.data = addUserInitialsToBlockData(
          insertedBlock.data,
          userInitials,
          userUrl
        );
      }
      annotatedBlocks.push(insertedBlock);
      j++;
    } else if (
      i < oldData.blocks.length &&
      (j === newData.blocks.length || dp[i][j + 1] < dp[i + 1][j])
    ) {
      // Deleted block
      const deletedBlock = JSON.parse(JSON.stringify(oldData.blocks[i]));
      deletedBlock.data = wrapBlockDataInTag(deletedBlock.data, "del");
      annotatedBlocks.push(deletedBlock);
      i++;
    }
  }

  return { ...newData, blocks: annotatedBlocks };
}

// Add initials to new blocks in newData (only for new blocks)
function addUserInitialsToNewData(oldData, newData, userInitials, userUrl) {
  const dp = computeLCS(oldData.blocks || [], newData.blocks || []);
  const newBlocksWithInitials = [];

  let i = 0,
    j = 0;
  while (i < oldData.blocks.length || j < newData.blocks.length) {
    if (
      i < oldData.blocks.length &&
      j < newData.blocks.length &&
      blocksEqual(oldData.blocks[i], newData.blocks[j])
    ) {
      // Unchanged block, add as is
      newBlocksWithInitials.push(newData.blocks[j]);
      i++;
      j++;
    } else if (
      j < newData.blocks.length &&
      (i === oldData.blocks.length || dp[i][j + 1] >= dp[i + 1][j])
    ) {
      // Inserted block, add initials
      const insertedBlock = JSON.parse(JSON.stringify(newData.blocks[j]));
      if (!blockHasInitials(insertedBlock)) {
        insertedBlock.data = addUserInitialsToBlockData(
          insertedBlock.data,
          userInitials,
          userUrl
        );
      }
      newBlocksWithInitials.push(insertedBlock);
      j++;
    } else {
      // Skip deleted or changed blocks
      i++;
    }
  }

  return { ...newData, blocks: newBlocksWithInitials };
}

// Add initials to block data (only if the block doesn't already have initials)
function addUserInitialsToBlockData(data, userInitials, userUrl) {
  // Regex to detect if any initials are already present within <sup><a></a></sup>
  const initialsRegex = /<sup><a[^>]*>.*<\/a><\/sup>/;

  // If it's a string, check for the initials pattern
  if (typeof data === "string") {
    if (!initialsRegex.test(data)) {
      // If initials aren't found, append them
      return `${data}<sup><a href="${userUrl}" contenteditable="false">(${userInitials})</a></sup>`;
    }
    // If initials are found, return the original data (no duplication)
    return data;
  }

  // If it's an array (for lists or complex structures), apply the logic recursively
  if (Array.isArray(data)) {
    return data.map((item) =>
      addUserInitialsToBlockData(item, userInitials, userUrl)
    );
  }

  // If it's an object, recursively apply the logic to each field
  if (typeof data === "object" && data !== null) {
    const newData = {};
    for (const key in data) {
      if (data.hasOwnProperty(key)) {
        newData[key] = addUserInitialsToBlockData(
          data[key],
          userInitials,
          userUrl
        );
      }
    }
    return newData;
  }

  // If none of the above, return the data as is
  return data;
}

// Function to wrap block data in tags (like del/ins)
function wrapBlockDataInTag(data, tag) {
  if (typeof data === "string") {
    return `<${tag}>${data}</${tag}>`;
  }
  if (Array.isArray(data)) {
    return data.map((item) => wrapBlockDataInTag(item, tag));
  }
  if (typeof data === "object" && data !== null) {
    const newData = {};
    for (const key in data) {
      if (data.hasOwnProperty(key)) {
        newData[key] = wrapBlockDataInTag(data[key], tag);
      }
    }
    return newData;
  }
  return data;
}

// Main SaveComponent for saving the annotated data
const SaveComponent = ({ setLoading, close }) => {
  const {
    content,
    privacy,
    files,
    isOwner,
    selectedProgram,
    setProgram,
    program,
  } = useStartupStore((state) => ({
    content: state.content,
    privacy: state.privacy,
    files: state.files,
    isOwner: state.isOwner,
    selectedProgram: state.selectedProgram,
    setProgram: state.setProgram,
    program: state.program,
  }));

  const {
    user: { id, first_name, last_name },
  } = useUserStore((state) => state.user);
  const userInitials = `${first_name[0]}${last_name[0]}`;
  const userUrl = `/profile/user/${id}`;

  const handleSave = async () => {
    const contentTouched =
      content[selectedProgram.cid]?.[selectedProgram.sid]?.touched;
    const hasFiles =
      files[selectedProgram.cid]?.[selectedProgram.sid]?.length > 0;
    const privacyChanged =
      program?.categories[selectedProgram.cid]?.sub_categories[
        selectedProgram.sid
      ].privacy !== privacy[selectedProgram.cid]?.[selectedProgram.sid];

    if (!contentTouched && !hasFiles && !privacyChanged) return;

    const id =
      program?.categories[selectedProgram.cid]?.sub_categories[
        selectedProgram.sid
      ].id;
    const oldDataRaw =
      program?.categories[selectedProgram.cid]?.sub_categories[
        selectedProgram.sid
      ].response || '{ "blocks": [] }';
    const newDataRaw = content[selectedProgram.cid]?.[selectedProgram.sid]
      .response || { blocks: [] };

    const oldData = parseJSON(oldDataRaw);
    const newData =
      typeof newDataRaw === "string" ? parseJSON(newDataRaw) : newDataRaw;

    // Process newData to include user initials in new blocks only
    const newDataWithInitials = addUserInitialsToNewData(
      oldData,
      newData,
      userInitials,
      userUrl
    );

    // Annotate the new data with the diff results
    const annotatedData = annotateEditorJsData(
      oldData,
      newDataWithInitials,
      userInitials,
      userUrl
    );

    try {
      const formData = new FormData();

      files[selectedProgram.cid]?.[selectedProgram.sid]?.forEach((file) => {
        if (!file.file) {
          formData.append(
            "sub_category[file_records_attributes][]",
            JSON.stringify({
              name: file.name,
              url: file.url,
              file_id: file.id,
              size_bytes: file.size_bytes,
              icon: file.icon,
              source: file.source,
            })
          );
        } else {
          formData.append("sub_category[files][]", file.file);
        }
      });
      formData.append(
        "sub_category[privacy]",
        privacy[selectedProgram.cid]?.[selectedProgram.sid]
      );

      if (isOwner) {
        formData.append(
          "sub_category[response]",
          JSON.stringify(newDataWithInitials)
        );
      } else {
        formData.append(
          "sub_category[proposed_response]",
          JSON.stringify(newDataWithInitials)
        );
        formData.append(
          "sub_category[diff_proposed_response]",
          JSON.stringify(annotatedData)
        );
      }

      const response = await fetchApi(
        `/sub_categories/${id}`,
        "PATCH",
        formData,
        true
      );
      if (response.ok) {
        const responseData = await response.json();
        setProgram(responseData.program);
        close();
      } else {
        console.error("HTTP error! Failed to submit form");
      }
    } catch (error) {
      console.error("Error:", error.message);
    }
  };

  return <ContainerButton action={handleSave} buttonText="Save & Complete" />;
};

export default SaveComponent;

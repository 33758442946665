import React from 'react';
import styled from 'styled-components';

const BreadcrumbsList = styled.ol`
  display: flex;
  align-items: center;
  list-style: none;
  padding: 0;
  margin: 0;
`;

const BreadcrumbItem = styled.li`
  display: inline-flex;
  align-items: center;
  font-size: var(--font-size-body-1);

  &.active {
    font-weight: var(--font-weight-h4);
    color: var(--color-background-main);
    white-space: nowrap;
  }

  &.active a {
    color: var(--color-button-accent);
  }
`;

const BreadcrumbLink = styled.a`
  color: #6c6c6c;
  text-decoration: none;
  transition: color 0.2s ease-in;

  &:hover,
  &:focus {
    color: #6c45e7;
    outline: none;
  }
`;

const BreadcrumbSeparator = styled.svg`
  margin: 0 8px;
  color: #ccc;
  width: 16px;
  height: 16px;
`;

const Breadcrumbs = ({ links }) => {
  return (
    <BreadcrumbsList>
      {links.map((link, index) => (
        <BreadcrumbItem key={index} className={link.active ? 'active' : ''}>
          {link.active ? (
            <span>{link.name}</span>
          ) : (
            <>
              <BreadcrumbLink href={link.href}>
                {link.name}
              </BreadcrumbLink>
              {index < links.length - 1 && (
                <BreadcrumbSeparator
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  aria-hidden="true"
                >
                  <path
                    d="M6 13L10 3"
                    stroke="currentColor"
                    strokeLinecap="round"
                  />
                </BreadcrumbSeparator>
              )}
            </>
          )}
        </BreadcrumbItem>
      ))}
    </BreadcrumbsList>
  );
};

export default Breadcrumbs;

import React from "react";
import { useForm } from "@mantine/form";
import {
  PasswordInput,
  Button,
  Group,
  Stack,
  Paper,
  Title,
  Divider,
  Container,
  Text,
  Anchor,
  Center,
  Box,
  rem,
} from "@mantine/core";
import { IconArrowLeft } from "@tabler/icons-react";
import fetchApi from "../Lib/api";

const ChangePasswordForm = () => {
  const reset_password_token = new URLSearchParams(window.location.search).get(
    "reset_password_token"
  );

  const is_new_password = new URLSearchParams(window.location.search).get(
    "new_password"
  ) === "true";

  const form = useForm({
    initialValues: {
      password: "",
      password_confirmation: "",
    },
    validate: {
      password: (val) =>
        val.length >= 6
          ? null
          : "Password should include at least 6 characters",
      password_confirmation: (val) =>
        val === form.values.password ? null : "Passwords do not match",
    },
  });

  const handleSubmit = async (e) => {
    try {
      const response = await fetchApi("/users/password", "PUT", {
        user: {
          reset_password_token: reset_password_token,
          password: form.values.password,
          password_confirmation: form.values.password_confirmation,
        },
      });

      if (response.ok) {
        // Handle password change success
        console.log("Password change successful!");
        window.location.href = is_new_password ? "/onboarding" : "/dashboard/#/";
      } else {
        // Handle password change failure
        console.error("Password change failed.");
      }
    } catch (error) {
      console.error("Error during password change:", error);
    }
  };

  return (
    <Container size={660} my={30}>
      <Paper
        radius="md"
        p="lg"
        shadow="sm"
        withBorder
        //   {...props}
        className="py-20 m-auto max-w-lg xl:max-w-xl md:px-16"
      >
        <Title ta="center" order={1}>{is_new_password ? "Set" : "Chnage"} your password</Title>

        <Divider mb="xl" mt="xs" variant="dotted" />
        <form onSubmit={form.onSubmit(handleSubmit)}>
          <Stack>
            <PasswordInput
              required
              label="New Password"
              placeholder="Your new password"
              value={form.values.password}
              onChange={(event) =>
                form.setFieldValue("password", event.currentTarget.value)
              }
              error={form.errors.password}
              radius="md"
            />

            <PasswordInput
              required
              label="Confirm New Password"
              placeholder="Confirm your new password"
              value={form.values.password_confirmation}
              onChange={(event) =>
                form.setFieldValue(
                  "password_confirmation",
                  event.currentTarget.value
                )
              }
              error={form.errors.password_confirmation}
              radius="md"
            />

            <Group position="center">
              <Button
                type="submit"
                radius="xl"
                style={{ backgroundColor: "rgb(55, 96, 146)" }}
              >
                {is_new_password ? "Set" : "Change"} Password
              </Button>
            </Group>
          </Stack>

          <Group position="center" mt="xl">
            <Anchor type="button" c="dimmed" href="/">
              <Center inline>
                <IconArrowLeft
                  style={{ width: rem(12), height: rem(12) }}
                  stroke={1.5}
                />
                <Box ml={5}>Back to the login page</Box>
              </Center>
            </Anchor>
          </Group>
        </form>
      </Paper>
    </Container>
  );
};

export default ChangePasswordForm;

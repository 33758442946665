import React, { memo, useContext, useEffect, useState } from "react";

import fetchApi from "../../Lib/api";
import { useParams } from "react-router-dom";
import {
  Alert,
  Avatar,
  Button,
  Collapse,
  Grid,
  Group,
  Skeleton,
} from "@mantine/core";
import { IconAlertCircle, IconMessage } from "@tabler/icons-react";
import { TopicListItem } from "./TopicsList";
import HeaderAndSidebar from "../../Lib/HeaderAndSidebar";
import Markdown from "../../Lib/Markdown";
import moment from "moment";
import { useDisclosure } from "@mantine/hooks";
import { CreateTopic } from "../../Dashbaord/DashboardPages/Forums/Topics";
import useUserStore from "../../../stores/userStore";

const MemoTopics = memo(Posts);

export default function postsContainer({ project }) {
  return (
    <HeaderAndSidebar sidebar={<></>}>
      <MemoTopics />
    </HeaderAndSidebar>
  );
}

function Posts() {
  const { channel_type, post } = useParams();
  const [topic, setTopic] = useState(null);
  const [posts, setPosts] = useState([]);
  const [replyContent, setReplyContent] = useState("");
  const [channelName, setChannelName] = useState("");

  const { user } = useUserStore((state) => state.user);

  useEffect(() => {
    // Parse query parameters from the location object
    const searchParams = new URLSearchParams(location.search);
    const param1 = searchParams.get("channel");
    setChannelName(param1);

    // Use param1 and param2 as needed...
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetchApi(`/hub/${channel_type}/${post}`, "GET");

        if (response.ok) {
          const res = await response.json();
          setTopic(res.topic);
          setPosts(res.posts);
          console.log(res.posts);
        } else {
          const msg = (await response.json()).error;
          console.error(msg);
        }
      } catch (error) {
        console.error("Error during password change:", error);
      }
    };

    fetchData(); // Call the async function immediately
  }, []);

  const handleReply = async (postId) => {
    try {
      const response = await fetchApi(`/hub/${channel_type}/${post}`, "POST", {
        post: { content: replyContent },
      });

      if (response.ok) {
        const res = await response.json();
        setPosts((p) => [
          ...p,
          { ...res, first_unread_in_page: false, first_in_page: false },
        ]);
        console.log(res);

        setReplyContent("");
      } else {
        const msg = (await response.json()).error;
        // setNotification({ type: "failure", content: msg });
        console.error(msg);
      }
    } catch (error) {
      console.error("Error during password change:", error);
    }
  };

  const handleDeletePost = async (id) => {
    try {
      const response = await fetchApi(
        `/hub/${channel_type}/${topic.slug}/${id}`,
        "DELETE"
      );

      if (response.status === 204) {
        setPosts((prev) => prev.filter((p) => p.post.id !== id));
      } else {
        console.error((await response.json()).error);
      }
    } catch (error) {
      console.error("Error during data fetching:", error);
    }
  };

  const handleEditPost = async (id, content, toggle) => {
    try {
      const response = await fetchApi(
        `/hub/${channel_type}/${topic.slug}/${id}`,
        "PATCH",
        { post: { content } }
      );

      if (response.ok) {
        // window.location.href = "/dashboard/#/forums";
        setPosts((prev) =>
          prev.map((p) =>
            p.post.id == id
              ? { ...p, post: { ...p.post, content: content } }
              : p
          )
        );
        toggle();
      } else {
        console.error((await response.json()).error);
      }
    } catch (error) {
      console.error("Error during data fetching:", error);
    }
  };
  if (!topic) return <></>;

  return (
    <section className="flex justify-center">
      <div className="max-w-[85rem] w-full p-6">
        <ol
          className="flex items-center whitespace-nowrap mb-6"
          aria-label="Breadcrumb"
        >
          <li className="inline-flex items-center">
            <a
              className="flex items-center text-sm text-gray-500 hover:text-blue-600 focus:outline-none focus:text-blue-600 dark:focus:text-blue-500"
              href="/dashboard/#/forums"
            >
              Channels
            </a>
            <svg
              className="flex-shrink-0 mx-2 overflow-visible size-4 text-gray-400 dark:text-neutral-600"
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            >
              <path d="m9 18 6-6-6-6" />
            </svg>
          </li>
          <li className="inline-flex items-center">
            <a
              className="flex items-center text-sm text-gray-500 hover:text-blue-600 focus:outline-none focus:text-blue-600 dark:focus:text-blue-500"
              href="/dashboard/#/forums"
            >
              {channelName}
              <svg
                className="flex-shrink-0 mx-2 overflow-visible size-4 text-gray-400 dark:text-neutral-600"
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <path d="m9 18 6-6-6-6" />
              </svg>
            </a>
          </li>
          <li className="inline-flex items-center">
            {channel_type.replaceAll("-", " ")}
            <svg
              className="flex-shrink-0 mx-2 overflow-visible size-4 text-gray-400 dark:text-neutral-600"
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            >
              <path d="m9 18 6-6-6-6" />
            </svg>
          </li>
          <li
            className="inline-flex items-center text-sm font-semibold text-gray-800 truncate dark:text-gray-200"
            aria-current="page"
          >
            {post.replaceAll("-", " ")}
          </li>
        </ol>

        <Grid className="mx-auto">
          <Grid.Col span={8}>
            <Topic
              topic={topic.topic}
              follow={topic.follow}
              channel_type={channel_type}
            />
            <div className="max-w-4xl mx-auto mt-4">
              {posts.map((post) => (
                <Post
                  user_id={user.id}
                  is_admin={user.admin}
                  key={post.post.id}
                  post={post}
                  handleDeletePost={handleDeletePost}
                  handleEditPost={handleEditPost}
                />
              ))}
            </div>
            {user.thredded_user_detail.moderation_state === "approved" ||
            user.admin ? (
              <div>
                <div className=" mb-5 rounded-lg rounded-t-lg border-2 border-gray-200 dark:bg-gray-800 dark:border-gray-700">
                  <Markdown
                    value={replyContent}
                    visibleDragbar={true}
                    preview={"edit"}
                    hideToolbar={false}
                    height={200}
                    setValue={(e) => {
                      setReplyContent(e);
                    }}
                  />
                </div>
                <Button
                  onClick={() => handleReply(null)}
                  variant="default"
                  fullWidth
                  className="w-fit"
                  leftIcon={<IconMessage size="1rem" />}
                >
                  Post reply
                </Button>
              </div>
            ) : (
              <Alert icon={<IconAlertCircle size="1rem" />} color="indigo">
                You are not allowed to create a new post. Please contact the
                administrator.
              </Alert>
            )}
          </Grid.Col>
          <Grid.Col span={4}>
            <Skeleton
              visible={true}
              height={600}
              width="80%"
              radius="xl"
              animate={false}
            >
              Lorem ipsum dolor sit amet...
            </Skeleton>
          </Grid.Col>
        </Grid>
      </div>
    </section>
  );
}

const Post = ({
  user_id,
  is_admin,
  post,
  handleDeletePost,
  handleEditPost,
}) => {
  const [opened, { toggle, open }] = useDisclosure(false);
  const [content, setContent] = useState(post.post.content);
  return (
    <div className="bg-white p-4 rounded-lg shadow mb-4">
      <div className="flex items-center justify-between mb-4">
        <div className="flex items-center space-x-3">
          <img
            src={post.post.user.avatar_url}
            alt="Avatar"
            className="w-10 h-10 rounded-full"
          />
          <div>
            <h4 className="font-semibold text-gray-900">
              {post.post.user.first_name} {post.post.user.last_name}
            </h4>
            <p className="text-sm text-gray-500">
              {moment(post.post.created_at).fromNow()}
            </p>
          </div>
        </div>

        <div className="flex items-center space-x-2">
          <button className="text-blue-500 hover:text-blue-700 text-sm">
            💬 Quote
          </button>

          {(user_id == post.post.user_id || is_admin) && (
            <>
              <button
                onClick={toggle}
                className="text-blue-500 hover:text-blue-700 text-sm"
              >
                ✏️ Edit
              </button>
              <button
                onClick={() => handleDeletePost(post.post.id)}
                className="text-blue-500 hover:text-blue-700 text-sm"
              >
                🗑 Delete
              </button>
            </>
          )}
        </div>
      </div>
      {opened ? (
        <>
          <Markdown
            value={content}
            preview="edit"
            height={"100%"}
            setValue={(e) => {
              setContent(e);
            }}
          />
          <Button
            onClick={() => handleEditPost(post.post.id, content, toggle)}
            variant="default"
            fullWidth
            className="w-fit"
            disabled={!content}
            leftIcon={<IconMessage size="1rem" />}
          >
            Edit Content
          </Button>
        </>
      ) : (
        <Markdown
          value={post.post.content}
          hideToolbar={true}
          preview="preview"
          // height={"unset"}
        />
      )}
    </div>
  );
};

const Topic = ({ topic, follow, channel_type }) => {
  const [opened, { toggle, open }] = useDisclosure(false);
  const [following, setFollowing] = useState(!!follow);
  const [title, setTitle] = useState(topic.title);
  const { user } = useUserStore((state) => state.user);

  const handleFollow = async () => {
    try {
      const response = await fetchApi(
        `/hub/${channel_type}/${topic.slug}/${follow ? "unfollow" : "follow"}`,
        "POST"
      );

      if (response.ok) {
        const res = await response.json();
        setFollowing(!following);
        console.log(res);
      } else {
        console.error((await response.json()).error);
      }
    } catch (error) {
      console.error("Error during data fetching:", error);
    }
  };

  const handleDelete = async () => {
    try {
      const response = await fetchApi(
        `/hub/${channel_type}/${topic.slug}`,
        "DELETE"
      );

      if (response.status === 204) {
        window.location.href = "/dashboard/#/forums";
      } else {
        console.error((await response.json()).error);
      }
    } catch (error) {
      console.error("Error during data fetching:", error);
    }
  };

  const handleEdit = (title) => {
    setTitle(title);
    close();
  };

  return (
    <>
      <div className="bg-white p-4 border-b border-gray-300">
        <h1 className="text-2xl font-semibold text-gray-900 mb-2">{title}</h1>
        <div className="flex justify-between items-center">
          <p className="text-gray-600 text-sm">
            Started by:{" "}
            <span className="font-medium text-gray-800">
              {topic.user.first_name} {topic.user.last_name}
            </span>{" "}
            on <span>{moment(topic.created_at).fromNow()}</span>
          </p>
          <div>
            {(user.admin ||
              (topic.user_id === user.id &&
                user.thredded_user_detail.moderation_state === "approved")) && (
              <>
                <button
                  onClick={toggle}
                  className="text-gray-500 hover:text-gray-700 mr-4 text-sm"
                >
                  ✏️ Edit Topic
                </button>
                <button
                  onClick={handleDelete}
                  className="text-gray-500 hover:text-gray-700 text-sm mr-4"
                >
                  🗑 Delete Topic
                </button>
              </>
            )}
            <button
              onClick={handleFollow}
              className="text-gray-500 hover:text-gray-700 mr-4 text-sm"
            >
              {following ? "🔕 Stop following" : "🔔 Follow this topic"}
            </button>
          </div>
        </div>

        {opened && (
          <CreateTopic
            topic={topic}
            channel_type={channel_type}
            close={handleEdit}
          />
        )}
      </div>
    </>
  );
};

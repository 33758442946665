import {
  Button,
  Checkbox,
  Group,
  Select,
  Stack,
  TextInput,
  Title,
  Collapse,
  Breadcrumbs,
  Alert,
} from "@mantine/core";
import React, { useContext, useEffect, useState } from "react";

import moment from "moment";

import { useDisclosure } from "@mantine/hooks";
import fetchApi from "../../../Lib/api";
import Markdown from "../../../Lib/Markdown";
import { IconAlertCircle } from "@tabler/icons-react";
import useUserStore from "../../../../stores/userStore";

export default function RenderTopics({ context, userIsBlocked }) {
  const [topics, setTopics] = useState([]);

  useEffect(() => {
    let url;
    if (context.slug) url = `/hub/${context.slug}`;
    else if (context.id) url = `/hub/topics/all/${context.id}`;
    else url = "/hub/topics/all";
    async function fetchData() {
      try {
        const response = await fetchApi(url, "GET");
        if (response.ok) {
          const res = await response.json();
          console.log(res);
          setTopics(res.topics);
        } else {
          console.error((await response.json()).error);
        }
      } catch (error) {
        console.error("Error during data fetching:", error);
      }
    }
    fetchData();
  }, [context]);

  return (
    <Stack>
      {context.channel && (
        <>
          <Breadcrumbs mt="xs">
            <Title order={4} c="dimmed">
              {context.channel}
            </Title>
            <Title order={4} c="dimmed">
              {context.name}
            </Title>
          </Breadcrumbs>
        </>
      )}

      {userIsBlocked === "approved" ? (
        <CreateTopic channel_type={context.slug} name={context.name} />
      ) : (
        <Alert icon={<IconAlertCircle size="1rem" />} color="indigo">
          You are not allowed to create a new topic. Please contact the
          administrator.
        </Alert>
      )}

      {topics.map((t) => (
        <PostCard
          key={t.topic.id}
          topic={t.topic}
          following={t.follow}
          url_path={t.url_path}
        />
      ))}
    </Stack>
  );
}

const PostCard = ({ topic, following, url_path }) => {
  const statusStyles = {
    pending: {
      bgColor:
        "bg-yellow-100 border-yellow-200 dark:bg-yellow-900 dark:border-yellow-700",
      headerColor: "bg-yellow-200 dark:bg-yellow-800",
      textColor: "text-yellow-900 dark:text-yellow-100",
      subTextColor: "text-yellow-800 dark:text-yellow-200",
      detailColor: "text-yellow-700 dark:text-yellow-300",
      statusText: "⏳ Pending Review",
    },
    blocked: {
      bgColor: "bg-red-100 border-red-200 dark:bg-red-900 dark:border-red-700",
      headerColor: "bg-red-200 dark:bg-red-800",
      textColor: "text-red-900 dark:text-red-100",
      subTextColor: "text-red-800 dark:text-red-200",
      detailColor: "text-red-700 dark:text-red-300",
      statusText: "🚫 Blocked Content",
    },
    approved: {
      bgColor: "bg-white border-gray-200 dark:bg-gray-800 dark:border-gray-700",
      headerColor: "bg-gray-200 dark:bg-gray-800",
      textColor: "text-gray-900 dark:text-white",
      subTextColor: "text-gray-500 dark:text-gray-400",
      detailColor: "text-gray-500 dark:text-gray-400",
      statusText: "Post Content",
    },
  };

  const currentStyle =
    statusStyles[topic.first_post?.moderation_state] || statusStyles.approved;

  return (
    <div
      className={`mx-auto w-full rounded-lg border shadow p-5 ${currentStyle.bgColor}`}
    >
      {topic.first_post?.moderation_state !== "approved" && (
        <div className={`px-3 py-1 rounded-t-lg ${currentStyle.headerColor}`}>
          <span className={`${currentStyle.textColor} font-semibold`}>
            {currentStyle.statusText}
          </span>
        </div>
      )}

      <div className="flex items-center space-x-4 mb-4 pt-3">
        <img
          className="w-12 h-12 rounded-full"
          src={
            topic.first_post.user.avatar_url || "https://via.placeholder.com/48"
          }
          alt="User Avatar"
        />
        <div>
          <h3 className={`text-lg font-semibold ${currentStyle.textColor}`}>
            {topic.first_post.user.first_name} {topic.first_post.user.last_name}
          </h3>
          <p className={`text-sm ${currentStyle.subTextColor}`}>
            {topic.first_post.user.job_title}
          </p>
        </div>
      </div>
      <a href={url_path}>
        <h2 className={`text-xl font-bold mb-3 ${currentStyle.textColor}`}>
          {topic.title}
        </h2>
      </a>

      <Markdown
        value={topic.first_post.content}
        hideToolbar={true}
        preview="preview"
        height={50}
      />

      <div className={`text-sm my-3 ${currentStyle.detailColor}`}>
        🗓️ Posted {moment(topic.created_at).fromNow()} | 💬{" "}
        {topic.posts_count - 1} Replies
      </div>
      <div className="flex items-center gap-5 text-gray-500 dark:text-gray-300">
        <a
          href={url_path}
          className="px-4 py-2 rounded bg-blue-500 text-white hover:bg-blue-600 transition-colors"
        >
          💬 Replies
        </a>
      </div>
    </div>
  );
};

export function CreateTopic({ channel_type, name, topic, close }) {
  const [opened, { toggle, open }] = useDisclosure(!!topic);
  const [messageboards, setMessageBoards] = useState([]);
  const [messageboard, setMessageBoard] = useState(channel_type);
  const [title, setTitle] = useState("");
  const [content, setContent] = useState("");
  const [locked, setLocked] = useState(false);
  const [sticky, setSticky] = useState(false);
  const { user } = useUserStore((state) => state.user);

  useEffect(() => {
    if (topic) {
      setTitle(topic.title);
      setLocked(topic.locked);
      setSticky(topic.sticky);
      setMessageBoard(channel_type);
    }
    async function fetchData() {
      try {
        const response = await fetchApi("/hub?mg=true", "GET");
        if (response.ok) {
          const res = await response.json();
          setMessageBoards(
            res.map((m) => ({ label: m.name, value: m.slug, id: m.id }))
          );
        } else {
          console.error((await response.json()).error);
        }
      } catch (error) {
        console.error("Error during data fetching:", error);
      }
    }
    if (opened && !name) fetchData();
  }, [opened, name]);

  const handleTopicCreate = async () => {
    const m_id = messageboards.find((m) => m.value === messageboard).id;
    try {
      const response = await fetchApi(
        topic ? `/hub/${channel_type}/${topic.slug}` : `/hub/${messageboard}`,
        topic ? "PATCH" : "POST",
        {
          // messageboard_id: channel_type,
          topic: topic
            ? { title, messageboard_id: m_id, locked, sticky }
            : { title, content, locked, sticky },
        }
      );

      if (response.ok) {
        const res = await response.json();
        if (close) close(title);
        toggle();
        setTitle("");
        setContent("");
      } else {
        const msg = (await response.json()).error;
        // setNotification({ type: "failure", content: msg });
        console.error(msg);
      }
    } catch (error) {
      console.error("Error during password change:", error);
    }
  };

  return (
    <Stack className="max-w-3xl mb-4">
      <TextInput
        onClick={open}
        onChange={(event) => setTitle(event.currentTarget.value)}
        size="md"
        withAsterisk
        value={title}
        label={opened ? "Title" : ""}
        placeholder={`Start a new topic ${name ? "in " + name : ""}`}
      />
      <Collapse in={opened}>
        <Stack>
          {!name && (
            <Select
              label={"Select sub channel for the topic"}
              data={messageboards}
              value={messageboard || messageboards[0]?.value}
              onChange={setMessageBoard}
            />
          )}
          {!topic && (
            <Markdown
              value={content}
              visibleDragbar={true}
              preview={"edit"}
              hideToolbar={false}
              height={200}
              setValue={(e) => {
                setContent(e);
              }}
            />
          )}

          {user.admin && (
            <Group>
              <Checkbox
                checked={locked}
                onChange={(event) => setLocked(event.currentTarget.checked)}
                label="Locked"
              />
              <Checkbox
                checked={sticky}
                onChange={(event) => setSticky(event.currentTarget.checked)}
                label="Sticky"
              />
            </Group>
          )}

          <Group>
            <Button
              onClick={handleTopicCreate}
              variant="default"
              fullWidth
              className="w-fit"
              disabled={!title || !(topic || content)}
              // leftIcon={<IconMessage size="1rem" />}
            >
              {topic ? "Update" : "Create new"} topic
            </Button>{" "}
            <Button
              onClick={toggle}
              variant="default"
              fullWidth
              className="w-fit"
              // leftIcon={<IconMessage size="1rem" />}
            >
              Cancel
            </Button>
          </Group>
        </Stack>
      </Collapse>
    </Stack>
  );
}

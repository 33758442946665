import { Loader, MantineProvider, Tabs } from "@mantine/core";
import React, { useState } from "react";
import TagsInput from "./TagsInput";
import fetchApi from "./api";

export default function EmailForwarding() {
  const [selectedEmails, setSelectedEmails] = useState([]);
  const [selectedInstitutions, setSelectedInstitutions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [activeTab, setActiveTab] = useState("institution-invite");
  const [formData, setFormData] = useState({
    subject: "",
    salutation: "",
  });

  // Handle input changes
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  // Handle multi-select for emails
  const handleMultiSelectEmails = (selected) => {
    setSelectedEmails(selected);
    // Reset institutions if emails change
    setSelectedInstitutions(new Array(selected.length).fill(""));
  };

  // Handle multi-select for institutions
  const handleMultiSelectInstitutions = (index, value) => {
    const updatedInstitutions = [...selectedInstitutions];
    updatedInstitutions[index] = value;
    setSelectedInstitutions(updatedInstitutions);
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();

    // Validate all required fields
    if (
      !formData.subject ||
      !formData.salutation ||
      selectedEmails.length === 0 ||
      selectedInstitutions.length !== selectedEmails.length ||
      selectedInstitutions.some((inst) => !inst.trim())
    ) {
      alert(
        "Please ensure all emails have a corresponding institution name. All fields are required."
      );
      return;
    }

    setLoading(true);

    try {
      const response = await fetchApi("/emails_forwarding", "POST", {
        email_type: activeTab,
        recipient_email: selectedEmails,
        institutions: selectedInstitutions,
        subject: formData.subject,
        salutation: formData.salutation,
      });

      if (response.ok) {
        setLoading(false);
        alert("Email sent successfully!");
        // Reset form
        setFormData({
          subject: "",
          salutation: "",
        });
        setSelectedEmails([]);
        setSelectedInstitutions([]);
      } else {
        const errorData = await response.json();
        alert(`Error sending email: ${errorData.error}`);
      }
    } catch (error) {
      console.error("Error sending email:", error);
      alert("An error occurred while sending the email.");
    }
  };

  return (
    <MantineProvider
      theme={{
        fontSizes: {
          xs: "12px",
          sm: "14px",
          md: "16px",
          lg: "18px",
          xl: "20px",
        },
        components: {
          TextInput: {
            defaultProps: { size: "md" },
            styles: () => ({
              input: {
                height: "30px",
                fontSize: "12px",
              },
            }),
          },
          PasswordInput: {
            defaultProps: { size: "md" },
            styles: () => ({
              input: {
                height: "30px",
                fontSize: "12px",
              },
            }),
          },
        },
      }}
    >
      <Tabs
        defaultValue="institution-invite"
        orientation="vertical"
        onTabChange={(value) => setActiveTab(value)}
      >
        <Tabs.List>
          <Tabs.Tab value="institution-invite">Institution Invite</Tabs.Tab>
          <Tabs.Tab value="messages">Email 2</Tabs.Tab>
        </Tabs.List>

        <Tabs.Panel value="institution-invite">
          <form onSubmit={handleSubmit} className="space-y-6 p-4 lg:p-12">
            <div className="text-center mb-8">
              <h1 className="block text-2xl font-bold text-gray-800 dark:text-white">
                Institution Invitation Form
              </h1>
            </div>

            {/* Email Recipients */}
            <div>
              <label className="inline text-md mb-1 leading-[1.4]">
                Email Recipients <span className="text-red-500">*</span>
              </label>
              <TagsInput
                data={[]}
                value={selectedEmails}
                onChange={handleMultiSelectEmails}
              />
              <p className="mt-1 text-[11px] text-gray-500">
                Type, press Enter to save and to add more emails.
              </p>
            </div>

            <div className="grid grid-cols-1 lg:grid-cols-4 lg:gap-3">
              {/* Institution Names Corresponding to Emails */}
              {selectedEmails.map((email, index) => (
                <div key={email} className="mb-4">
                  <label className="inline font-light text-md mb-1 leading-[1.4]">
                    Institution for {email}{" "}
                    <span className="text-red-500">*</span>
                  </label>
                  <input
                    type="text"
                    value={selectedInstitutions[index] || ""}
                    onChange={(e) =>
                      handleMultiSelectInstitutions(index, e.target.value)
                    }
                    required
                    className="bg-white border border-gray-400 rounded-lg text-gray-900 font-normal h-16 leading-[1.5] px-3 py-2 focus:outline-none w-full"
                    placeholder="Enter institution name"
                  />
                </div>
              ))}
            </div>
            {/* Email Subject */}
            <div>
              <label className="inline font-light text-md mb-1 leading-[1.4]">
                Email Subject <span className="text-red-500">*</span>
              </label>
              <input
                type="text"
                name="subject"
                value={formData.subject}
                onChange={handleChange}
                required
                className="bg-white border border-gray-400 rounded-lg text-gray-900 font-normal h-16 leading-[1.5] px-3 py-2 focus:outline-none w-full"
              />
            </div>

            {/* Salutation */}
            <div>
              <label className="inline font-light text-md mb-1 leading-[1.4]">
                Salutation <span className="text-red-500">*</span>
              </label>
              <input
                type="text"
                name="salutation"
                value={formData.salutation}
                onChange={handleChange}
                required
                className="bg-white border border-gray-400 rounded-lg text-gray-900 font-normal h-16 leading-[1.5] px-3 py-2 focus:outline-none w-full"
              />
              <p className="mt-1 text-[11px] text-gray-500">
                E.g., Dear Bob - Remember to use a generic salutation for
                multiple users. Avoid commas.
              </p>
            </div>

            {/* Submit Button */}
            <div>
              {loading ? (
                <Loader style={{ marginInline: "auto" }} />
              ) : (
                <button
                  type="submit"
                  className="w-full py-3 px-4 inline-flex justify-center items-center gap-x-2 text-sm font-medium rounded-lg border border-transparent bg-blue-600 text-white hover:bg-blue-700 focus:outline-none focus:bg-blue-700 disabled:opacity-50"
                >
                  Send Email
                </button>
              )}
            </div>
          </form>
        </Tabs.Panel>

        <Tabs.Panel value="messages">
          <div>Not yet added</div>
        </Tabs.Panel>
      </Tabs>
    </MantineProvider>
  );
}

import React, { useContext } from "react";
import { useState } from "react";
import {
  IconUserCheck,
  IconShieldCheck,
  IconCircleCheck,
  IconListDetails,
} from "@tabler/icons-react";
import { Button, Center, Group, Paper, Stepper, rem } from "@mantine/core";
import Category from "./Category";
import UserDetails from "./Details";
import AboutMe from "./About";
import { validations } from "./validations";
import { useForm } from "@mantine/form";
import fetchApi from "../Lib/api";
import { useMediaQuery } from "@mantine/hooks";
import useUserStore from "../../stores/userStore";

const onSubmit = async (data, setFunction, user_id) => {
  try {
    const formData = new FormData();
    console.log(data);

    if (user_id) formData.append(`user[id]`, user_id);

    Object.entries(data).forEach(([key, value]) => {
      formData.append(`user[${key}]`, value);
    });

    const response = await fetchApi(
      "/api/users/onboard",
      "POST",
      formData,
      true
    );

    if (response.ok) {
      console.log("Success:", response.user);

      const responseData = await response.json();
      console.log("Success:", responseData.user);
      if (setFunction) {
        setFunction((p) => ({ ...p, user: responseData.user }));
        return true;
      } else if (!user_id) window.location.href = "/profile";
    } else {
      console.error("HTTP error! Failed to submit form");
    }
  } catch (error) {
    console.error("Error:", error.message);
  }
};

export default function Onboarding() {
  const isMobile = useMediaQuery("(max-width: 50rem)");
  const { user } = useUserStore((state) => state.user);
  const [active, setActive] = useState(0);
  const [showError, setShowError] = useState(false);

  const form = useForm({
    initialValues: {
      avatar: null,
      first_name: user.first_name || "",
      last_name: user.last_name || "",
      user_type: user.user_type || 0,
      company: user.company || "",
      job_title: user.job_title || "",
      year_of_experience: user.year_of_experience || 0,
      work_type: user.work_type || "",
      university: user.university || "",
      degree: user.degree || "",
      country: user.address?.country || "",
      city: user.address?.city || "",
      about_me: user.about_me || "",
      expert_in: user.expert_in || "",
      skills: user.skills || "",
      industries: user.industries || "",
    },

    validate: validations(active),
  });

  return (
    <main className="max-w-[900px] lg:pt-16 p-4 pt-6 mx-2  sm:mx-auto  space-x-2 text-sm font-mediums sm:space-x-4">
      <Stepper
        allowNextStepsSelect={false}
        active={active}
        onStepClick={setActive}
        radius="md"
        completedIcon={
          <IconCircleCheck style={{ width: rem(18), height: rem(18) }} />
        }
      >
        <Stepper.Step
          icon={<IconUserCheck style={{ width: rem(18), height: rem(18) }} />}
          description={isMobile ? "" : "Account type"}
        >
          <Category form={form} />
        </Stepper.Step>
        <Stepper.Step
          icon={<IconListDetails style={{ width: rem(18), height: rem(18) }} />}
          description={isMobile ? "" : "Personal Information"}
        >
          <UserDetails form={form} />
        </Stepper.Step>
        <Stepper.Step
          icon={<IconShieldCheck style={{ width: rem(18), height: rem(18) }} />}
          description={isMobile ? "" : "Skills and Expertise"}
        >
          <AboutMe form={form} showError={showError} />
        </Stepper.Step>
      </Stepper>

      <Group position="apart" className="my-12" align="end">
        {active > 0 ? (
          <Button
            loading={false}
            onClick={() => {
              if (active > 0) setActive((current) => current - 1);
            }}
            variant="default"
          >
            Go back
          </Button>
        ) : (
          <div></div>
        )}
        <Button
          loading={false}
          onClick={() => {
            console.log(form.validate());
            if (!form.validate().hasErrors)
              if (active < 2) setActive((current) => current + 1);
              else onSubmit(form.values);
            else setShowError(true);
          }}
          variant="default"
        >
          Save and continue
        </Button>
      </Group>
    </main>
  );
}

export { onSubmit };

import React from "react";
import styled from "styled-components";
import FeatureButton from "./FeatureButton";

const FeatureCardContainer = styled.div`
  display: flex;
  justify-content: space-between;
  background-color: var(--color-container-card-default);
  padding: 20px;
  border: 1px solid #dee8e7;
  border-radius: 10px;
  text-align: left;
  width: 100%;
`;

const FeatureCardContent = styled.div`
  display: flex;
  gap: 16px;

  @media (max-width: 1280px) {
    flex-direction: column;
    align-items: center;
  }

  @media (max-width: 1024px) {
    flex-direction: row;
  }
`;

const FeatureImage = styled.img`
  width: 80px;
  height: 80px;
  object-fit: contain;
`;

const FeatureDetails = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const FeatureTitle = styled.h3`
  color: var(--color-background-card);
  font-weight: var(--font-weight-h4);
  line-height: 18px;
`;

const FeatureDescription = styled.p`
  font-size: var(--font-size-body-1);
  color: var(--color-background-main);
`;

const FeatureCard = ({ title, description, imageUrl, buttonText, action }) => {
  return (
    <FeatureCardContainer>
      <FeatureCardContent>
        <FeatureImage src={imageUrl} alt="Feature Illustration" />
        <FeatureDetails>
          <FeatureTitle>{title}</FeatureTitle>
          <FeatureDescription>{description}</FeatureDescription>
         {action &&  <FeatureButton buttonText={buttonText} action={action} />}
        </FeatureDetails>
      </FeatureCardContent>
    </FeatureCardContainer>
  );
};

export default FeatureCard;

import React, { useState } from "react";
import styled from "styled-components";
import sendIcon from "./assets/send-arrow.svg";
import FeatureButton from "./FeatureButton"; // Assuming this is the correct path for FeatureButton
import { Button, Loader } from "@mantine/core";
import { IconBulb } from "@tabler/icons-react";
import SaveComponent from "../components/Startups/SaveComponent";
import GoogleDrivePickerAndUploader from "../components/Lib/FilesPickerAndUploader";
import useStartupStore from "../stores/startupStore";
import useUserStore from "../stores/userStore";
import MarkdownIt from 'markdown-it';

// Styled components
const ChatDocumentUploadContainer = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 10px;
  background-color: #fff;
`;

const AttachedDocuments = styled.div`
  width: 40%;
  height: 100%;
`;

const SectionTitle = styled.p`
  margin-bottom: 8px;
  font-weight: var(--font-weight-h3);
  font-size: 1rem;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  gap: 10px;
  margin-top: 24px;
`;

const ChatArea = styled.div`
  width: 60%;
`;

const ChatBox = styled.div`
  height: 250px;
  border: 1px solid var(--color-navigation-icon-dark);
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 5px;
`;

const ChatMessages = styled.div`
  padding: 5px;
  overflow-y: auto;
  flex-grow: 1;
`;

const ChatMessage = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 5px;
  flex-direction: ${(props) =>
    props.issent === "true" ? "row-reverse" : "row"};
`;

const ChatBubble = styled.div`
  background-color: ${(props) =>
    props.issent === "true" ? "#f1f1f1" : "var(--color-button-accent)"};
  padding: 8px;
  border-radius: 12px;
  max-width: 70%;
  font-size: 0.85rem;
  border: ${(props) => (props.issent === "true" ? "1px solid #ddd" : "none")};
  overflow-wrap: break-word;

  /* General text styling */
  p {
    margin: 0.5em 0;
    color: ${(props) => (props.issent === "true" ? "#333" : "white")};
  }

  /* Blockquote styling (used for AI highlights or quotes) */
  blockquote {
    margin: 0.5em 0;
    padding: 0.5em 1em;
    background-color: ${(props) =>
      props.issent === "true" ? "#f9f9f9" : "rgba(255, 255, 255, 0.1)"};
    border-left: 4px solid
      ${(props) => (props.issent === "true" ? "#ccc" : "#6ba4ff")};
    color: ${(props) => (props.issent === "true" ? "#555" : "white")};
    font-style: italic;
  }

  /* Code block styling */
  pre {
    background: ${(props) =>
      props.issent === "true" ? "#f5f5f5" : "rgba(0, 0, 0, 0.8)"};
    color: ${(props) => (props.issent === "true" ? "#444" : "#e5e5e5")};
    padding: 10px;
    border-radius: 5px;
    font-size: 0.8rem;
    overflow-x: auto;
    font-family: 'Courier New', Courier, monospace;
  }

  /* Inline code styling */
  code {
    background: ${(props) =>
      props.issent === "true" ? "#f5f5f5" : "rgba(0, 0, 0, 0.8)"};
    padding: 2px 4px;
    border-radius: 3px;
    font-family: 'Courier New', Courier, monospace;
    color: ${(props) => (props.issent === "true" ? "#444" : "#e5e5e5")};
  }

  /* List styling */
  ul,
  ol {
    margin: 0.5em 0;
    padding-left: 20px;
    color: ${(props) => (props.issent === "true" ? "#333" : "white")};
  }

  li {
    margin-bottom: 5px;
    line-height: 1.5;
  }

  /* Table styling */
  table {
    width: 100%;
    border-collapse: collapse;
    margin: 10px 0;
    font-size: 0.9rem;
    color: ${(props) => (props.issent === "true" ? "#333" : "white")};
  }

  th,
  td {
    padding: 8px 12px;
    border: 1px solid
      ${(props) => (props.issent === "true" ? "#ddd" : "rgba(255, 255, 255, 0.3)")};
    text-align: left;
  }

  th {
    background-color: ${(props) =>
      props.issent === "true" ? "#f1f1f1" : "rgba(255, 255, 255, 0.1)"};
    font-weight: bold;
  }

  /* Link styling */
  a {
    color: ${(props) => (props.issent === "true" ? "#007bff" : "#80d4ff")};
    text-decoration: underline;
    cursor: pointer;

    &:hover {
      color: ${(props) => (props.issent === "true" ? "#0056b3" : "#66c3ff")};
    }
  }

  /* Image styling */
  img {
    max-width: 100%;
    border-radius: 5px;
    margin: 10px 0;
    display: block;
  }

  /* Horizontal rule styling */
  hr {
    border: 0;
    border-top: 1px solid ${(props) => (props.issent === "true" ? "#ddd" : "#555")};
    margin: 1em 0;
  }
`;



const ChatText = styled.p`
  color: #333;
  margin: 0; /* Remove any margin */
`;

const ChatInputArea = styled.div`
  display: flex;
  align-items: center;
  padding: 5px;
  border-top: 1px solid #ddd;
`;

const ChatInput = styled.input`
  flex-grow: 1;
  padding: 8px;
  border: 1px solid var(--color-button-accent);
  border-radius: 8px;
  margin-right: 5px;
  font-size: 0.85rem;
  outline: none;
`;

const SendButton = styled.button`
  background-color: var(--color-button-accent);
  border: none;
  cursor: pointer;
  padding: 8px;
  border-radius: 50%;
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const SendIcon = styled.img`
  width: 16px;
  &:hover {
    filter: brightness(0.8);
  }
`;

// Component
const ChatDocumentUpload = ({ close}) => {
  const [chats, setChats] = useState([]);
  const [userMessage, setUserMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const mdParser = new MarkdownIt();

  const {
    user
  } = useUserStore((state) => state.user);

  const { content, selectedProgram } = useStartupStore((state) => ({
    content: state.content,
    selectedProgram: state.selectedProgram,
  }));

  const pageId = selectedProgram.cid;
  const index = selectedProgram.sid;


  // Function to generate AI response based on user input using the real API
  const fetchAIResponse = async (userId, projectId, userMessage) => {
    try {
      const response = await fetch("https://34.47.239.170:8000/api/v1/chat/", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          user_id: userId,
          project_id: projectId,
          message: userMessage,
          current_stage: content[pageId]?.[index]?.name,
          stream: false,
          temperature: 0.7,
        }),
      });

      if (!response.ok) {
        throw new Error(`API error: ${response.statusText}`);
      }

      const data = await response.json();
      return data.response; // Assuming the API response contains the AI message in `message`
    } catch (error) {
      console.error("Failed to fetch AI response", error);
      return "Sorry, something went wrong. Please try again.";
    }
  };

  // Updated handleSendMessage function
  const handleSendMessage = async () => {
    if (!userMessage.trim()) return; // Don't send empty messages

    const userChat = { message: userMessage, issent: true };
    setChats([...chats, userChat]); // Add user message to the chat

    setUserMessage("");
    setLoading(true);

    // Fetch AI response from the real API
    const aiResponse = await fetchAIResponse(
      `user_${user.id}`,
      "project_456",
      userChat.message
    );

    const aiChat = { message: aiResponse, issent: false };

    // Simulate some delay (optional)

    setChats((prevChats) => [...prevChats, aiChat]); // Add AI response to the chat
    setLoading(false);
  };

  // Handle Enter key press
  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      handleSendMessage();
    }
  };

  return (
    <ChatDocumentUploadContainer>
      {/* Attached Documents */}
      <AttachedDocuments>
        <SectionTitle>Attached Documents</SectionTitle>
        <GoogleDrivePickerAndUploader />

        {/* Buttons Below Attached Documents */}
        <Button
          variant="default"
          fullWidth
          className="flex-grow-0 mt-4 font-light rounded-lg border border-transparent bg-green-800 text-white hover:bg-green-600 disabled:opacity-50 disabled:pointer-events-none expert"
          leftIcon={<IconBulb size="1.5rem" />}
        >
          Validate your response
        </Button>
        <ButtonContainer>
          <FeatureButton action={close} buttonText="Discard" />
          <SaveComponent close={close} />
        </ButtonContainer>
      </AttachedDocuments>

      {/* Chat Area */}
      <ChatArea>
        <SectionTitle>Smart AI Chat</SectionTitle>
        <ChatBox>
        <ChatMessages>
    {chats.map((chat, index) => (
      <ChatMessage key={index} issent={chat.issent + ""}>
        <ChatBubble issent={chat.issent + ""}>
          {/* Use dangerouslySetInnerHTML to render the markdown */}
          <div
            dangerouslySetInnerHTML={{ __html: mdParser.render(chat.message) }}
          />
        </ChatBubble>
      </ChatMessage>
    ))}
  </ChatMessages>

          {/* Chat Input with Send Icon */}
          <ChatInputArea>
            <ChatInput
              type="text"
              name="chat_area"
              placeholder="Type a message"
              value={userMessage}
              onChange={(e) => setUserMessage(e.target.value)}
              onKeyPress={handleKeyPress}
            />
            {loading ? (
              <Loader />
            ) : (
              <SendButton onClick={handleSendMessage}>
                <SendIcon src={sendIcon} alt="Send" />
              </SendButton>
            )}
          </ChatInputArea>
        </ChatBox>
      </ChatArea>
    </ChatDocumentUploadContainer>
  );
};

export default ChatDocumentUpload;

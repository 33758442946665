import React, { useState } from "react";
import {
  Menu,
  Button,
  Anchor,
  Checkbox,
  Group,
  PasswordInput,
  TextInput,
  MantineProvider,
  Divider,
} from "@mantine/core";
import { useForm } from "@mantine/form";
import fetchApi from "../Lib/api";
import Notification from "../Lib/Notification";
import { GoogleButton } from "./GoogleButton";
import { LinkedInButton } from "./LinkedInButton";
import UserButton from "../../stories/UserButton";

function getSubdomain() {
    const hostname = window.location.hostname;
    const parts = hostname.split(".");
    // Adjust for localhost or development environments
    if (parts.length > 2) {
      return parts[0]; // Subdomain is the first part of the hostname
    }
    return null; // No subdomain
  }

export default function SignInModal({ isSignedIn, currentUser }) {
  const [resetMode, setResetMode] = useState(false);
  const [notification, setNotification] = useState(null);
  const [loading, setLoading] = useState(false);

  console.log({ isSignedIn, currentUser });

  // Form validation rules
  const form = useForm({
    initialValues: {
      email: "",
      password: "",
    },
    validate: {
      email: (val) => (/^\S+@\S+$/.test(val) ? null : "Invalid email"),
      password: (val) =>
        resetMode
          ? null
          : val.length >= 6
          ? null
          : "Password should include at least 6 characters",
    },
  });

  const handleSocialLogin = async (provider) => {
    setNotification(null);
    setLoading(true);

    try {
      // Redirect to backend social login endpoint
      const response = await fetchApi(`/users/auth/${provider}`, "GET");

      if (response.ok) {
        const data = await response.json();

        // Redirect to authorization URL or handle successful login
        if (data.redirect_url) {
          window.location.href = data.redirect_url;
        } else {
          setNotification({
            type: "success",
            content: `${provider} login initiated`,
          });
        }
      } else {
        const errorData = await response.json();
        setNotification({
          type: "failure",
          content: errorData.error || `${provider} login failed`,
        });
      }
    } catch (error) {
      console.error(`${provider} Login Error:`, error);
      setNotification({
        type: "failure",
        content: `Network error with ${provider} login`,
      });
    } finally {
      setLoading(false);
    }
  };

  const handleSubmit = async (values) => {
    setNotification(null);
    setLoading(true);

    try {
      let endpoint, payload;
      const subdomain = getSubdomain();

      if (resetMode) {
        // Password Reset
        endpoint = "/users/password";
        payload = { email: values.email };
      } else {
        // Login
        endpoint = "/users/sign_in";
        payload = {
          email: values.email,
          password: values.password,
          subdomain,
        };
      }

      const response = await fetchApi(endpoint, "POST", { user: payload });

      if (response.ok) {
        const data = await response.json();

        // Success handling
        if (resetMode) {
          setNotification({
            type: "success",
            content: "Password reset link sent to your email",
          });
          // Optional: Switch back to login mode after successful reset request
          setResetMode(false);
        } else {
          setNotification({
            type: "success",
            content: "Login successful",
          });
          // Redirect or perform login action
          window.location.href = data.redirect_to || "/dashboard/#/";
        }
      } else {
        // Error handling
        const errorData = await response.json();
        setNotification({
          type: "failure",
          content: errorData.error || "Authentication failed",
        });
      }
    } catch (error) {
      console.error("Authentication Error:", error);
      setNotification({
        type: "failure",
        content: "Network error. Please try again.",
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <MantineProvider
      theme={{
        fontSizes: {
          xs: "12px",
          sm: "14px",
          md: "16px",
          lg: "18px",
          xl: "20px",
        },
        components: {
          TextInput: {
            defaultProps: { size: "md" },
            styles: () => ({
              input: {
                height: "30px",
                fontSize: "12px",
              },
            }),
          },
          PasswordInput: {
            defaultProps: { size: "md" },
            styles: () => ({
              input: {
                height: "30px",
                fontSize: "12px",
              },
            }),
          },
        },
      }}
    >
      {isSignedIn ? (
        <UserButton
        userName={currentUser?.full_name}
        userEmail={currentUser?.email}
        userImage={""}
        dashboard={true}
      />
      ) : (
        <Menu shadow="md" position="bottom" transitionProps={{ transition: 'scale', duration: 200 }}>
          <Menu.Target>
            <button className="btn btn-primary">Sign-in</button>
          </Menu.Target>

          <Menu.Dropdown       
            shadow="md"
            p={30}
            mt={10}
            radius="md"
          >
            {notification && <Notification {...notification} />}
            <form onSubmit={form.onSubmit(handleSubmit)}  style={{ width: "260px" }}>
              <TextInput
                label="Email"
                required
                {...form.getInputProps("email")}
              />

              {!resetMode && (
                <PasswordInput
                  label="Password"
                  required
                  mt="md"
                  {...form.getInputProps("password")}
                />
              )}

              <Group justify="space-between" mt="lg">
                {!resetMode && <Checkbox label="Remember me" size="xs" />}
                <Anchor
                  component="button"
                  size="xs"
                  type="button"
                  onClick={() => {
                    setResetMode(!resetMode);
                    form.reset();
                  }}
                >
                  {resetMode ? "Back to Login" : "Forgot password?"}
                </Anchor>
              </Group>

              <Button fullWidth mt="xl" type="submit" loading={loading}>
                {resetMode ? "Reset Password" : "Sign In"}
              </Button>

              {!resetMode && (
                <>
                  <Divider
                    label="Or continue with"
                    labelPosition="center"
                    my="lg"
                    variant="dotted"
                  />

                  <Group grow mb="md" mt="md">
                    <GoogleButton
                      radius="xl"
                      onClick={() => handleSocialLogin("google")}
                    >
                      Google
                    </GoogleButton>
                    <LinkedInButton
                      radius="xl"
                      onClick={() => handleSocialLogin("linkedin")}
                    >
                      LinkedIn
                    </LinkedInButton>
                  </Group>
                </>
              )}
            </form>
          </Menu.Dropdown>
        </Menu>
      )}
    </MantineProvider>
  );
}

// app/javascript/stores/userStore.js
import { create } from 'zustand';

const useUserStore = create((set, get) => ({
  user: null,
  selectedUser: null,
  intervalIdRef: null,

  // Function to update specific fields in the user object
  setUser: (updatedFields) => {
    const currentUser = get().user;
    set({
      user: { ...currentUser, ...updatedFields },
    });
  },

  setSelectedUser: (selectedUser) => set({ selectedUser }),

  // Set up the interval for updating the "last seen" time
  setUpdateInterval: (updateLastSeen) => {
    const { intervalIdRef } = get();

    // Ensure there's no duplicate interval
    if (intervalIdRef) return;

    const intervalId = setInterval(() => {
      updateLastSeen();
    }, 10 * 60 * 1000); // 10 minutes

    set({ intervalIdRef: intervalId });
  },

  // Clear the interval when no longer needed
  clearUpdateInterval: () => {
    const { intervalIdRef } = get();
    if (intervalIdRef) {
      clearInterval(intervalIdRef);
      set({ intervalIdRef: null });
    }
  },
}));

export default useUserStore;

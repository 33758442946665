import {
  Anchor,
  Avatar,
  Group,
  Loader,
  SimpleGrid,
  Stack,
  ThemeIcon,
  Title,
} from "@mantine/core";

import React, { useEffect, useState } from "react";
import parse from "html-react-parser";
import fetchApi from "../../Lib/api";
import { IconArrowLeft, IconSocial } from "@tabler/icons-react";
import { Users } from "./Members";
import StartupCard from "./Card";
import useUserStore from "../../../stores/userStore";
import { useQuery } from "@tanstack/react-query";

const useActivities = () => {
  return useQuery({
    queryKey: ['activities'], // Unique query key for caching
    queryFn: async () => {
      const response = await fetchApi('/activities/index', 'GET');

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error || 'Failed to fetch activities');
      }

      return await response.json(); // Return the parsed JSON data
    },
    retry: 1, // Retry once on failure
    staleTime: 5 * 60 * 1000, // Data is fresh for 5 minutes
    cacheTime: 30 * 60 * 1000, // Cache inactive data for 30 minutes
  });
};


export default function Activity() {
  const [stats, setStats] = useState({});
  const [activity, setActivity] = useState([]);
  const [view, setView] = useState("activity");

  const setUser = useUserStore((state) => state.setUser);

  const { data, isLoading, isError, error } = useActivities();

  useEffect(() => {
    if (data) {
      setStats(data.counts || []);
      setActivity(data.activities || []);
      setUser((prevUser) => ({
        ...prevUser,
        recent_activities_count: 0, // Reset the count
      }));
    }
  }, [data, setStats, setActivity, setUser]);

  if (isLoading) {
    return (
      <Loader
        className="mx-auto mt-10"
        color="indigo"
        size="lg"
        variant="dots"
      />
    );
  }

  if (isError) {
    return <div>Error: {error.message}</div>;
  }

  return (
    <section className="flex justify-center ">
      <div className="max-w-7xl w-full p-3 lg:p-6">
        <Stack spacing={20}>
          <Group>
            <ThemeIcon
              variant="gradient"
              size="lg"
              aria-label="Gradient action icon"
              gradient={{ from: "blue", to: "rgba(8, 0, 18, 1)", deg: 90 }}
            >
              <IconSocial size={20} />
            </ThemeIcon>
            <Title order={3} className="text-gray-600">
              Activity
            </Title>
          </Group>
          <div>
            <Group className="flex-col lg:flex-row flex-nowrap">
              <div
                onClick={() => setView("followers")}
                className="bg-white hover:bg-gray-100 overflow-hidden shadow sm:rounded-lg w-full cursor-pointer"
              >
                <div className="px-4 py-5 sm:p-6">
                  <dl>
                    <dt className="text-sm leading-5 font-medium text-gray-500 truncate">
                      No of Followers
                    </dt>
                    <div className="flex justify-between items-center">
                      <dd className="mt-1 text-3xl leading-9 font-semibold text-indigo-600">
                        {stats.followers_count || 0}
                      </dd>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth="1.5"
                        stroke="currentColor"
                        className="w-6 h-6"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M13.5 6H5.25A2.25 2.25 0 0 0 3 8.25v10.5A2.25 2.25 0 0 0 5.25 21h10.5A2.25 2.25 0 0 0 18 18.75V10.5m-10.5 6L21 3m0 0h-5.25M21 3v5.25"
                        />
                      </svg>
                    </div>
                  </dl>
                </div>
              </div>
              <div
                onClick={() => setView("followings")}
                className="bg-white hover:bg-gray-100 overflow-hidden shadow sm:rounded-lg w-full cursor-pointer"
              >
                <div className="px-4 py-5 sm:p-6">
                  <dl>
                    <dt className="text-sm leading-5 font-medium text-gray-500 truncate">
                      Following
                    </dt>

                    <div className="flex justify-between items-center">
                      <dd className="mt-1 text-3xl leading-9 font-semibold text-indigo-600">
                        {stats.followings_count || 0}
                      </dd>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth="1.5"
                        stroke="currentColor"
                        className="w-6 h-6"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M13.5 6H5.25A2.25 2.25 0 0 0 3 8.25v10.5A2.25 2.25 0 0 0 5.25 21h10.5A2.25 2.25 0 0 0 18 18.75V10.5m-10.5 6L21 3m0 0h-5.25M21 3v5.25"
                        />
                      </svg>
                    </div>
                  </dl>
                </div>
              </div>
              <div
                onClick={() => setView("startups")}
                className="bg-white hover:bg-gray-100 overflow-hidden shadow sm:rounded-lg w-full cursor-pointer"
              >
                <div className="px-4 py-5 sm:p-6">
                  <dl>
                    <dt className="text-sm leading-5 font-medium text-gray-500 truncate">
                      Startups Following
                    </dt>

                    <div className="flex justify-between items-center">
                      <dd className="mt-1 text-3xl leading-9 font-semibold text-indigo-600">
                        {stats.followed_startups_count || 0}
                      </dd>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth="1.5"
                        stroke="currentColor"
                        className="w-6 h-6"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M13.5 6H5.25A2.25 2.25 0 0 0 3 8.25v10.5A2.25 2.25 0 0 0 5.25 21h10.5A2.25 2.25 0 0 0 18 18.75V10.5m-10.5 6L21 3m0 0h-5.25M21 3v5.25"
                        />
                      </svg>
                    </div>
                  </dl>
                </div>
              </div>
            </Group>
          </div>

          {view !== "activity" && (
            <Anchor onClick={() => setView("activity")}>
              <Group className="mb-2" position="center" spacing={4}>
                <IconArrowLeft size={18} color="#1c7ed6" />
                Go back to activities
              </Group>
            </Anchor>
          )}

          {view === "activity" && (
            <>
              {activity.map((ac) => (
                <div
                  key={ac.date}
                  className="p-5 mb-4 border shadow border-gray-100 rounded-lg bg-white"
                >
                  <time className="text-lg font-semibold text-gray-900 ">
                    {ac.date}
                  </time>
                  <ol className="mt-3 divide-y divider-gray-200">
                    {ac.activities.map(
                      ({
                        id,
                        actor,
                        message,
                        description,
                        sub_message,
                        view,
                        url,
                      }) => (
                        <li className="list-none" key={id}>
                          <a
                            href={url || "#"}
                            className="items-center block p-3 sm:flex hover:bg-gray-100 "
                          >
                            <Avatar
                              className="w-12 h-12 mb-3 me-3 rounded-full sm:mb-0"
                              src={
                                actor.avatar_url ||
                                "https://placehold.co/600x400?text=No Image"
                              }
                              alt={actor.name}
                            />
                            <div className="text-gray-600 ">
                              <div className="text-base font-normal">
                                {parse(description || message || "")}
                              </div>
                              {sub_message && (
                                <div className="text-sm font-normal">
                                  {sub_message}
                                </div>
                              )}
                              {view === "Private" ? (
                                <span className="inline-flex items-center text-xs font-normal text-gray-700 ">
                                  <svg
                                    className="w-2.5 h-2.5 me-1"
                                    aria-hidden="true"
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="currentColor"
                                    viewBox="0 0 20 20"
                                  >
                                    <path d="m2 13.587 3.055-3.055A4.913 4.913 0 0 1 5 10a5.006 5.006 0 0 1 5-5c.178.008.356.026.532.054l1.744-1.744A8.973 8.973 0 0 0 10 3C4.612 3 0 8.336 0 10a6.49 6.49 0 0 0 2 3.587Z" />
                                    <path d="m12.7 8.714 6.007-6.007a1 1 0 1 0-1.414-1.414L11.286 7.3a2.98 2.98 0 0 0-.588-.21l-.035-.01a2.981 2.981 0 0 0-3.584 3.583c0 .012.008.022.01.033.05.204.12.401.211.59l-6.007 6.007a1 1 0 1 0 1.414 1.414L8.714 12.7c.189.091.386.162.59.211.011 0 .021.007.033.01a2.981 2.981 0 0 0 3.584-3.584c0-.012-.008-.023-.011-.035a3.05 3.05 0 0 0-.21-.588Z" />
                                    <path d="M17.821 6.593 14.964 9.45a4.952 4.952 0 0 1-5.514 5.514L7.665 16.75c.767.165 1.55.25 2.335.251 6.453 0 10-5.258 10-7 0-1.166-1.637-2.874-2.179-3.407Z" />
                                  </svg>
                                  Private
                                </span>
                              ) : (
                                <span className="inline-flex items-center text-xs font-normal text-gray-500 dark:text-gray-400">
                                  <svg
                                    className="w-2.5 h-2.5 me-1"
                                    aria-hidden="true"
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="currentColor"
                                    viewBox="0 0 20 20"
                                  >
                                    <path d="M10 .5a9.5 9.5 0 1 0 0 19 9.5 9.5 0 0 0 0-19ZM8.374 17.4a7.6 7.6 0 0 1-5.9-7.4c0-.83.137-1.655.406-2.441l.239.019a3.887 3.887 0 0 1 2.082 2.5 4.1 4.1 0 0 0 2.441 2.8c1.148.522 1.389 2.007.732 4.522Zm3.6-8.829a.997.997 0 0 0-.027-.225 5.456 5.456 0 0 0-2.811-3.662c-.832-.527-1.347-.854-1.486-1.89a7.584 7.584 0 0 1 8.364 2.47c-1.387.208-2.14 2.237-2.14 3.307a1.187 1.187 0 0 1-1.9 0Zm1.626 8.053-.671-2.013a1.9 1.9 0 0 1 1.771-1.757l2.032.619a7.553 7.553 0 0 1-3.132 3.151Z" />
                                  </svg>
                                  Public
                                </span>
                              )}
                            </div>
                          </a>
                        </li>
                      )
                    )}
                  </ol>
                </div>
              ))}
            </>
          )}

          {view === "followers" && <Followers />}

          {view === "followings" && <Followings />}

          {view === "startups" && <Startups />}
        </Stack>
      </div>
    </section>
  );
}

const Followers = ({}) => {
  const [users, setUsers] = useState([]);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetchApi(`/api/users/followers`, "GET");

        if (response.ok) {
          const res = await response.json();
          console.log(res);
          setUsers(res);
        } else {
          const msg = (await response.json()).error;
          console.error(msg);
        }
      } catch (error) {
        console.error("Error during fetching activities:", error);
      }
    };

    fetchData();
  }, []);
  return (
    <Stack spacing={12}>
      <Title order={4} className="text-gray-600">
        People You are Following
      </Title>
      <Users users={users} setUsers={setUsers} />
    </Stack>
  );
};

const Followings = ({}) => {
  const [users, setUsers] = useState([]);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetchApi(`/api/users/followings`, "GET");

        if (response.ok) {
          const res = await response.json();
          console.log(res);
          setUsers(res);
        } else {
          const msg = (await response.json()).error;
          console.error(msg);
        }
      } catch (error) {
        console.error("Error during fetching activities:", error);
      }
    };

    fetchData();
  }, []);
  return (
    <Stack spacing={12}>
      <Title order={4} className="text-gray-600">
        People Following You
      </Title>
      <Users users={users} setUsers={setUsers} />
    </Stack>
  );
};

const Startups = ({}) => {
  const [startups, setStartups] = useState([]);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetchApi(`/api/users/followed_startups`, "GET");

        if (response.ok) {
          const res = await response.json();
          console.log(res);
          setStartups(res);
        } else {
          const msg = (await response.json()).error;
          console.error(msg);
        }
      } catch (error) {
        console.error("Error during fetching activities:", error);
      }
    };

    fetchData();
  }, []);
  return (
    <Stack spacing={12}>
      <Title order={4} className="text-slate-900">
        Startups You Are Following
      </Title>
      <SimpleGrid
        spacing="lg"
        verticalSpacing="lg"
        breakpoints={[
          { minWidth: "0rem", cols: 1 },
          { minWidth: "50rem", cols: 2 },
          { minWidth: "90rem", cols: 3 },
        ]}
      >
        {startups.map(({ startup_entity, openings_length, members }) => (
          <StartupCard
            key={startup_entity.id}
            startup={startup_entity}
            isFollowing={null}
            roles={openings_length}
            members={members}
          />
        ))}
      </SimpleGrid>
    </Stack>
  );
};
